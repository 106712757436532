/* tslint:disable */
/* eslint-disable */
/**
 * Valmet.Iop.App.Api 1.0-config
 * Valmet.Iop.App.Api
 *
 * The version of the OpenAPI document: 1.0-config
 * Contact: n.n@zure.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {Configuration} from './configuration';
import globalAxios, {AxiosPromise, AxiosInstance} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AllowedChildCompanyTypeOutputDTO
 */
export interface AllowedChildCompanyTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  parentTypeId: string;
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  parentTypeDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  childTypeId: string;
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  childTypeDisplayName: string;
}
/**
 *
 * @export
 * @interface ApiTagDataSetOutputDTO
 */
export interface ApiTagDataSetOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ApiTagDataSetOutputDTO
   */
  apiTagDataSetId: string;
  /**
   *
   * @type {string}
   * @memberof ApiTagDataSetOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {ApiTagDataSource}
   * @memberof ApiTagDataSetOutputDTO
   */
  apiDataSource: ApiTagDataSource;
  /**
   *
   * @type {Array<ApiTagOutputDTO>}
   * @memberof ApiTagDataSetOutputDTO
   */
  apiTags: Array<ApiTagOutputDTO>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ApiTagDataSource {
  Labkotec = 'Labkotec',
  Netbiter = 'Netbiter',
  Tolcon = 'Tolcon',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ApiTagLinkedEntityType {
  Site = 'Site',
  Asset = 'Asset',
}

/**
 *
 * @export
 * @interface ApiTagOutputDTO
 */
export interface ApiTagOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ApiTagOutputDTO
   */
  tagId: string;
  /**
   *
   * @type {string}
   * @memberof ApiTagOutputDTO
   */
  displayName: string;
  /**
   * Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagDataSource specific tag indentifiers.              Key is value from Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagIdentifierKeys.
   * @type {{ [key: string]: string; }}
   * @memberof ApiTagOutputDTO
   */
  apiIdentifiers: {[key: string]: string};
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof ApiTagOutputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiTagOutputDTO
   */
  siteId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiTagOutputDTO
   */
  assetId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiTagOutputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {ApiTagTagUsage}
   * @memberof ApiTagOutputDTO
   */
  tagUsage?: ApiTagTagUsage;
  /**
   *
   * @type {boolean}
   * @memberof ApiTagOutputDTO
   */
  isDisabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApiTagOutputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof ApiTagOutputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof ApiTagOutputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof ApiTagOutputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof ApiTagOutputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof ApiTagOutputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ApiTagTagUsage {
  None = 'None',
  Pressure = 'Pressure',
  Temperature = 'Temperature',
  TankLevelMass = 'TankLevelMass',
}

/**
 *
 * @export
 * @interface AssetOutputDTO
 */
export interface AssetOutputDTO {
  /**
   *
   * @type {string}
   * @memberof AssetOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssetOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof AssetOutputDTO
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetOutputDTO
   */
  providerCompanyId: string;
  /**
   *
   * @type {string}
   * @memberof AssetOutputDTO
   */
  ownerCompanyId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AssetOutputDTO
   */
  allowAllProducts: boolean;
  /**
   *
   * @type {Array<AssetProductOutputDTO>}
   * @memberof AssetOutputDTO
   */
  products: Array<AssetProductOutputDTO>;
  /**
   *
   * @type {boolean}
   * @memberof AssetOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
}
/**
 *
 * @export
 * @interface AssetProductOutputDTO
 */
export interface AssetProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof AssetProductOutputDTO
   */
  productId: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetProductOutputDTO
   */
  isAllowed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetProductOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AssetSortColumnName {
  Type = 'Type',
  DisplayName = 'DisplayName',
  OwnerCompanyDisplayName = 'OwnerCompanyDisplayName',
}

/**
 *
 * @export
 * @interface CompanyOutputDTO
 */
export interface CompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof CompanyOutputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof CompanyOutputDTO
   */
  organizationId: string;
  /**
   *
   * @type {string}
   * @memberof CompanyOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyOutputDTO
   */
  vat: string;
  /**
   *
   * @type {Array<CompanyTypeOutputDTO>}
   * @memberof CompanyOutputDTO
   */
  types: Array<CompanyTypeOutputDTO>;
}
/**
 *
 * @export
 * @interface CompanyParameterOutputDTO
 */
export interface CompanyParameterOutputDTO {
  /**
   *
   * @type {string}
   * @memberof CompanyParameterOutputDTO
   */
  parameterId: string;
  /**
   * Serialized value for the parameter.  Content depends on data type of parameter.
   * @type {string}
   * @memberof CompanyParameterOutputDTO
   */
  parameterValue: string;
}
/**
 *
 * @export
 * @interface CompanyStructureDTO
 */
export interface CompanyStructureDTO {
  /**
   *
   * @type {Array<StructureItemDTO>}
   * @memberof CompanyStructureDTO
   */
  sites?: Array<StructureItemDTO> | null;
  /**
   *
   * @type {Array<StructureItemDTO>}
   * @memberof CompanyStructureDTO
   */
  products?: Array<StructureItemDTO> | null;
  /**
   *
   * @type {Array<StructureItemDTO>}
   * @memberof CompanyStructureDTO
   */
  scopes?: Array<StructureItemDTO> | null;
}
/**
 *
 * @export
 * @interface CompanyTypeOutputDTO
 */
export interface CompanyTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof CompanyTypeOutputDTO
   */
  companyTypeId: string;
  /**
   *
   * @type {string}
   * @memberof CompanyTypeOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof CompanyTypeOutputDTO
   */
  hasCompanyHierarchy: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CompanyTypeOutputDTO
   */
  allowedChildTypeIds: Array<string>;
}
/**
 *
 * @export
 * @interface CreateApiTagDataSetInputDTO
 */
export interface CreateApiTagDataSetInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateApiTagDataSetInputDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiTagDataSetInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {ApiTagDataSource}
   * @memberof CreateApiTagDataSetInputDTO
   */
  apiDataSource?: ApiTagDataSource;
  /**
   * Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagDataSource specific API settings.              Key is value from Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiSettingKeys.
   * @type {{ [key: string]: string; }}
   * @memberof CreateApiTagDataSetInputDTO
   */
  apiSettings?: {[key: string]: string} | null;
  /**
   *
   * @type {Array<CreateApiTagInputDTO>}
   * @memberof CreateApiTagDataSetInputDTO
   */
  apiTags?: Array<CreateApiTagInputDTO> | null;
}
/**
 *
 * @export
 * @interface CreateApiTagInputDTO
 */
export interface CreateApiTagInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateApiTagInputDTO
   */
  displayName?: string | null;
  /**
   * Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagDataSource specific tag indentifiers.              Key is value from Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagIdentifierKeys.
   * @type {{ [key: string]: string; }}
   * @memberof CreateApiTagInputDTO
   */
  apiIdentifiers?: {[key: string]: string} | null;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof CreateApiTagInputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {ApiTagLinkedEntityType}
   * @memberof CreateApiTagInputDTO
   */
  linkedEntityType?: ApiTagLinkedEntityType;
  /**
   * Id for the entity defined in Valmet.Iop.App.Api.V1Config.ApiTagDataSets.CreateApiTagDataSet.CreateApiTagInputDTO.LinkedEntityType
   * @type {string}
   * @memberof CreateApiTagInputDTO
   */
  linkedEntityId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApiTagInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {ApiTagTagUsage}
   * @memberof CreateApiTagInputDTO
   */
  tagUsage?: ApiTagTagUsage;
  /**
   *
   * @type {boolean}
   * @memberof CreateApiTagInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CreateApiTagInputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateApiTagInputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateApiTagInputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateApiTagInputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateApiTagInputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateApiTagInputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @interface CreateAssetInputDTO
 */
export interface CreateAssetInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateAssetInputDTO
   */
  providerCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateAssetInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssetInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CreateAssetInputDTO
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateAssetInputDTO
   */
  ownerCompanyId?: string | null;
  /**
   * If true, any product from the provider can be selected with this asset.  If false, only the products linked through  Valmet.Iop.App.Api.V1Config.Assets.CreateAsset.CreateAssetInputDTO.Products can be selected.
   * @type {boolean}
   * @memberof CreateAssetInputDTO
   */
  allowAllProducts?: boolean | null;
  /**
   *
   * @type {Array<CreateAssetProductInputDTO>}
   * @memberof CreateAssetInputDTO
   */
  products?: Array<CreateAssetProductInputDTO> | null;
}
/**
 *
 * @export
 * @interface CreateAssetProductInputDTO
 */
export interface CreateAssetProductInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateAssetProductInputDTO
   */
  productId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssetProductInputDTO
   */
  isAllowed?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateDeliveryDestinationInputDTO
 */
export interface CreateDeliveryDestinationInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateDeliveryDestinationInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateDeliveryDestinationInputDTO
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateDeliveryDestinationInputDTO
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateDeliveryDestinationInputDTO
   */
  customerCompanyId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateDeliveryDestinationInputDTO
   */
  isDisabled?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateDnaInfoServerInputDTO
 */
export interface CreateDnaInfoServerInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateDnaInfoServerInputDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateDnaInfoServerInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {Array<CreateDnaInfoTagInputDTO>}
   * @memberof CreateDnaInfoServerInputDTO
   */
  dnaInfoTags?: Array<CreateDnaInfoTagInputDTO> | null;
}
/**
 *
 * @export
 * @interface CreateDnaInfoTagInputDTO
 */
export interface CreateDnaInfoTagInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateDnaInfoTagInputDTO
   */
  dnaInfoTagName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateDnaInfoTagInputDTO
   */
  displayName?: string | null;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof CreateDnaInfoTagInputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {DnaInfoTagLinkedEntityType}
   * @memberof CreateDnaInfoTagInputDTO
   */
  linkedEntityType?: DnaInfoTagLinkedEntityType;
  /**
   * Id for the entity defined in Valmet.Iop.App.Api.V1Config.DnaInfoServers.CreateDnaInfoServer.CreateDnaInfoTagInputDTO.LinkedEntityType
   * @type {string}
   * @memberof CreateDnaInfoTagInputDTO
   */
  linkedEntityId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateDnaInfoTagInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {DnaInfoTagUsage}
   * @memberof CreateDnaInfoTagInputDTO
   */
  tagUsage?: DnaInfoTagUsage;
  /**
   *
   * @type {boolean}
   * @memberof CreateDnaInfoTagInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CreateDnaInfoTagInputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateDnaInfoTagInputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateDnaInfoTagInputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateDnaInfoTagInputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateDnaInfoTagInputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateDnaInfoTagInputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @interface CreateEdgeGatewayInputDTO
 */
export interface CreateEdgeGatewayInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayInputDTO
   */
  iotHubDeviceId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayInputDTO
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayInputDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {EdgeGatewayLinkedEntityType}
   * @memberof CreateEdgeGatewayInputDTO
   */
  linkedEntityType?: EdgeGatewayLinkedEntityType;
  /**
   * Id for the entity defined in Valmet.Iop.App.Api.V1Config.EdgeGateways.CreateEdgeGateway.CreateEdgeGatewayInputDTO.LinkedEntityType
   * @type {string}
   * @memberof CreateEdgeGatewayInputDTO
   */
  linkedEntityId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateEdgeGatewayInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {Array<CreateEdgeGatewayOpcUaServerInputDTO>}
   * @memberof CreateEdgeGatewayInputDTO
   */
  opcUaServers?: Array<CreateEdgeGatewayOpcUaServerInputDTO> | null;
}
/**
 *
 * @export
 * @interface CreateEdgeGatewayOpcUaServerInputDTO
 */
export interface CreateEdgeGatewayOpcUaServerInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  displayName?: string | null;
  /**
   * The URL through which to reach the server
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  endpointUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  publishingIntervalMilliseconds?: number | null;
  /**
   *
   * @type {OpcUaServerAuthenticationMode}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  authenticationMode?: OpcUaServerAuthenticationMode;
  /**
   * Required if AuthenticationMode is  UsernamePassword
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  username?: string | null;
  /**
   * Required if AuthenticationMode is  UsernamePassword
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  password?: string | null;
  /**
   * Set to true to use secure OPC UA mode  to communicate with OPC UA server.
   * @type {boolean}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  useSecurity?: boolean | null;
  /**
   *
   * @type {Array<CreateEdgeGatewayOpcUaTagInputDTO>}
   * @memberof CreateEdgeGatewayOpcUaServerInputDTO
   */
  tags?: Array<CreateEdgeGatewayOpcUaTagInputDTO> | null;
}
/**
 *
 * @export
 * @interface CreateEdgeGatewayOpcUaTagInputDTO
 */
export interface CreateEdgeGatewayOpcUaTagInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  displayName?: string | null;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  measurementUnitId?: string | null;
  /**
   * Can only be defined if EGW is linked to  a site.
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {EdgeGatewayTagUsage}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  tagUsage?: EdgeGatewayTagUsage;
  /**
   *
   * @type {string}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  nodeId?: string | null;
  /**
   * By default the server publishing interval  is used as the sampling interval.  If this is specified, then  this is used instead.
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  samplingIntervalMilliseconds?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateEdgeGatewayOpcUaTagInputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @interface CreateProductCustomerInputDTO
 */
export interface CreateProductCustomerInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateProductCustomerInputDTO
   */
  customerId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateProductCustomerInputDTO
   */
  isAllowed?: boolean | null;
  /**
   * If true, the product being updated  will be set as a default for this customer  in the context of the product\'s  provider.  An error will be returned if the customer  already has another default product.
   * @type {boolean}
   * @memberof CreateProductCustomerInputDTO
   */
  isDefaultProduct?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateProductEquipmentInputDTO
 */
export interface CreateProductEquipmentInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateProductEquipmentInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateProductEquipmentInputDTO
   */
  canProvide?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateProductEquipmentInputDTO
   */
  canReceive?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateProductInputDTO
 */
export interface CreateProductInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateProductInputDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProductInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateProductInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CreateProductInputDTO
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProductInputDTO
   */
  baseProductId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateProductInputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateProductInputDTO
   */
  density?: number | null;
  /**
   *
   * @type {Array<CreateProductEquipmentInputDTO>}
   * @memberof CreateProductInputDTO
   */
  equipments?: Array<CreateProductEquipmentInputDTO> | null;
  /**
   * If true, the Valmet.Iop.App.Api.V1Config.Products.CreateProduct.CreateProductCustomerInputDTO.IsAllowed  field in Valmet.Iop.App.Api.V1Config.Products.CreateProduct.CreateProductInputDTO.Customers will have no effect,  as they will all be allowed.
   * @type {boolean}
   * @memberof CreateProductInputDTO
   */
  allowWithAllCustomers?: boolean | null;
  /**
   *
   * @type {Array<CreateProductCustomerInputDTO>}
   * @memberof CreateProductInputDTO
   */
  customers?: Array<CreateProductCustomerInputDTO> | null;
  /**
   * If true, the Valmet.Iop.App.Api.V1Config.Products.CreateProduct.CreateProductLogisticsOperatorInputDTO.IsAllowed  field in Valmet.Iop.App.Api.V1Config.Products.CreateProduct.CreateProductInputDTO.LogisticsOperators will have no effect,  as they will all be allowed.
   * @type {boolean}
   * @memberof CreateProductInputDTO
   */
  allowWithAllLogisticsOperators?: boolean | null;
  /**
   *
   * @type {Array<CreateProductLogisticsOperatorInputDTO>}
   * @memberof CreateProductInputDTO
   */
  logisticsOperators?: Array<CreateProductLogisticsOperatorInputDTO> | null;
}
/**
 *
 * @export
 * @interface CreateProductLogisticsOperatorInputDTO
 */
export interface CreateProductLogisticsOperatorInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateProductLogisticsOperatorInputDTO
   */
  logisticsOperatorId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateProductLogisticsOperatorInputDTO
   */
  isAllowed?: boolean | null;
  /**
   * If true, the product being updated  will be set as a default for this logistics operator  in the context of the product\'s  provider.  An error will be returned if the logistics operator  already has another default product.
   * @type {boolean}
   * @memberof CreateProductLogisticsOperatorInputDTO
   */
  isDefaultProduct?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateScopeCommandDTO
 */
export interface CreateScopeCommandDTO {
  /**
   *
   * @type {string}
   * @memberof CreateScopeCommandDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateScopeCommandDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateScopeCommandDTO
   */
  description?: string | null;
  /**
   *
   * @type {CreateScopeSiteSelectionType}
   * @memberof CreateScopeCommandDTO
   */
  siteSelectionType?: CreateScopeSiteSelectionType;
  /**
   *
   * @type {Array<CreateScopeSiteType>}
   * @memberof CreateScopeCommandDTO
   */
  selectedSiteTypes?: Array<CreateScopeSiteType> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateScopeCommandDTO
   */
  selectedSites?: Array<string> | null;
}
/**
 *
 * @export
 * @interface CreateScopeOutputDTO
 */
export interface CreateScopeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateScopeOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CreateScopeOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof CreateScopeOutputDTO
   */
  description?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CreateScopeSiteSelectionType {
  None = 'None',
  AllSites = 'AllSites',
  SelectedTypes = 'SelectedTypes',
  SelectedSites = 'SelectedSites',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum CreateScopeSiteType {
  DistributionTerminal = 'DistributionTerminal',
  LoadingStation = 'LoadingStation',
  FuelingStation = 'FuelingStation',
  CustomerTerminal = 'CustomerTerminal',
  Depot = 'Depot',
}

/**
 *
 * @export
 * @interface CreateSiteChildSiteInputDTO
 */
export interface CreateSiteChildSiteInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateSiteChildSiteInputDTO
   */
  siteId?: string | null;
  /**
   * True will add this site as a child.
   * @type {boolean}
   * @memberof CreateSiteChildSiteInputDTO
   */
  isChild?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateSiteEquipmentInputDTO
 */
export interface CreateSiteEquipmentInputDTO {
  /**
   * If not null, must contain the id  of the equipment being edited.  If null, equipment is assumed to be new.
   * @type {string}
   * @memberof CreateSiteEquipmentInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateSiteEquipmentInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteEquipmentInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {EquipmentType}
   * @memberof CreateSiteEquipmentInputDTO
   */
  type?: EquipmentType;
  /**
   *
   * @type {Array<CreateSiteEquipmentProductInputDTO>}
   * @memberof CreateSiteEquipmentInputDTO
   */
  products?: Array<CreateSiteEquipmentProductInputDTO> | null;
  /**
   * List of parameters to set on the equipment.
   * @type {Array<CreateSiteEquipmentParameterInputDTO>}
   * @memberof CreateSiteEquipmentInputDTO
   */
  parameters?: Array<CreateSiteEquipmentParameterInputDTO> | null;
  /**
   * If set, a user making a loading reservation in delivery scheduling  on this equipment will get this long of a reservation by default  once they set the starting time. Overrides value set on site level.
   * @type {number}
   * @memberof CreateSiteEquipmentInputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user making an unloading reservation in delivery scheduling  on this equipment will get this long of a reservation by default  once they set the starting time. Overrides value set on site level.
   * @type {number}
   * @memberof CreateSiteEquipmentInputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof CreateSiteEquipmentInputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof CreateSiteEquipmentInputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
  /**
   *
   * @type {Array<CreateSiteEquipmentReservationRestrictionDTO>}
   * @memberof CreateSiteEquipmentInputDTO
   */
  reservationRestrictions?: Array<CreateSiteEquipmentReservationRestrictionDTO> | null;
}
/**
 *
 * @export
 * @interface CreateSiteEquipmentParameterInputDTO
 */
export interface CreateSiteEquipmentParameterInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateSiteEquipmentParameterInputDTO
   */
  parameterId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteEquipmentParameterInputDTO
   */
  parameterValue?: string | null;
}
/**
 *
 * @export
 * @interface CreateSiteEquipmentProductInputDTO
 */
export interface CreateSiteEquipmentProductInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateSiteEquipmentProductInputDTO
   */
  productId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateSiteEquipmentProductInputDTO
   */
  canProvide?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateSiteEquipmentProductInputDTO
   */
  canReceive?: boolean | null;
}
/**
 *
 * @export
 * @interface CreateSiteEquipmentReservationRestrictionDTO
 */
export interface CreateSiteEquipmentReservationRestrictionDTO {
  /**
   *
   * @type {DayOfWeek}
   * @memberof CreateSiteEquipmentReservationRestrictionDTO
   */
  startDayOfWeek?: DayOfWeek;
  /**
   * The time when this restriction starts.  Expressed in local time of the related site.  Format: HH:mm
   * @type {string}
   * @memberof CreateSiteEquipmentReservationRestrictionDTO
   */
  startTime?: string | null;
  /**
   *
   * @type {DayOfWeek}
   * @memberof CreateSiteEquipmentReservationRestrictionDTO
   */
  endDayOfWeek?: DayOfWeek;
  /**
   * The time when this restriction ends.  Expressed in local time of the related site.  Format: HH:mm
   * @type {string}
   * @memberof CreateSiteEquipmentReservationRestrictionDTO
   */
  endTime?: string | null;
}
/**
 *
 * @export
 * @interface CreateSiteInputDTO
 */
export interface CreateSiteInputDTO {
  /**
   * The company this site belongs to.
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  companyId?: string | null;
  /**
   * Site name
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateSiteInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {SiteType}
   * @memberof CreateSiteInputDTO
   */
  type?: SiteType;
  /**
   *
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  stateOrRegion?: string | null;
  /**
   * Country alpha-2 code
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  country?: string | null;
  /**
   * Windows time zone id for the site, e.g.  \"FLE Standard Time\".  Used to localize times in reservation  change emails currently.
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  timeZoneId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  contactPhoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteInputDTO
   */
  code?: string | null;
  /**
   * List of scopes that define in which of them  this site is included.
   * @type {Array<CreateSiteScopeInputDTO>}
   * @memberof CreateSiteInputDTO
   */
  scopes?: Array<CreateSiteScopeInputDTO> | null;
  /**
   * List of equipments contained in this site.
   * @type {Array<CreateSiteEquipmentInputDTO>}
   * @memberof CreateSiteInputDTO
   */
  equipments?: Array<CreateSiteEquipmentInputDTO> | null;
  /**
   * If set, a user making a loading reservation in delivery scheduling  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof CreateSiteInputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user making an unloading reservation in delivery scheduling  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof CreateSiteInputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof CreateSiteInputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof CreateSiteInputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If `true`, logistics operators  can see each others codes in the reservation  calendar for this site.
   * @type {boolean}
   * @memberof CreateSiteInputDTO
   */
  logisticsOperatorCodesVisibleForReservations?: boolean | null;
  /**
   * List of parameters to set on the site.
   * @type {Array<CreateSiteParameterInputDTO>}
   * @memberof CreateSiteInputDTO
   */
  parameters?: Array<CreateSiteParameterInputDTO> | null;
  /**
   * List of child sites and whether to assign them as  children of this site.
   * @type {Array<CreateSiteChildSiteInputDTO>}
   * @memberof CreateSiteInputDTO
   */
  childSites?: Array<CreateSiteChildSiteInputDTO> | null;
  /**
   *
   * @type {CreateSiteLocationInputDTO}
   * @memberof CreateSiteInputDTO
   */
  location?: CreateSiteLocationInputDTO;
}
/**
 *
 * @export
 * @interface CreateSiteLocationInputDTO
 */
export interface CreateSiteLocationInputDTO {
  /**
   *
   * @type {number}
   * @memberof CreateSiteLocationInputDTO
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateSiteLocationInputDTO
   */
  longitude?: number | null;
}
/**
 *
 * @export
 * @interface CreateSiteParameterInputDTO
 */
export interface CreateSiteParameterInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateSiteParameterInputDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteParameterInputDTO
   */
  parameterId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSiteParameterInputDTO
   */
  parameterValue?: string | null;
}
/**
 *
 * @export
 * @interface CreateSiteScopeInputDTO
 */
export interface CreateSiteScopeInputDTO {
  /**
   *
   * @type {string}
   * @memberof CreateSiteScopeInputDTO
   */
  scopeId?: string | null;
  /**
   * True will add this site to this scope.
   * @type {boolean}
   * @memberof CreateSiteScopeInputDTO
   */
  isIncluded?: boolean | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

/**
 *
 * @export
 * @interface DeliveryDestinationOutputDTO
 */
export interface DeliveryDestinationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof DeliveryDestinationOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDestinationOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDestinationOutputDTO
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDestinationOutputDTO
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDestinationOutputDTO
   */
  customerCompanyId: string;
  /**
   *
   * @type {boolean}
   * @memberof DeliveryDestinationOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeliveryDestinationOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DeliveryDestinationSortColumnName {
  DisplayName = 'DisplayName',
  CustomerCompanyDisplayName = 'CustomerCompanyDisplayName',
  Code = 'Code',
  Address = 'Address',
}

/**
 *
 * @export
 * @interface DnaInfoServerOutputDTO
 */
export interface DnaInfoServerOutputDTO {
  /**
   *
   * @type {string}
   * @memberof DnaInfoServerOutputDTO
   */
  dnaInfoServerId: string;
  /**
   *
   * @type {string}
   * @memberof DnaInfoServerOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {Array<DnaInfoTagOutputDTO>}
   * @memberof DnaInfoServerOutputDTO
   */
  dnaInfoTags: Array<DnaInfoTagOutputDTO>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DnaInfoServerSortColumnName {
  DisplayName = 'DisplayName',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum DnaInfoTagLinkedEntityType {
  Site = 'Site',
  Asset = 'Asset',
}

/**
 *
 * @export
 * @interface DnaInfoTagOutputDTO
 */
export interface DnaInfoTagOutputDTO {
  /**
   *
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  tagId: string;
  /**
   *
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  dnaInfoTagName: string;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  siteId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  assetId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DnaInfoTagOutputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {DnaInfoTagUsage}
   * @memberof DnaInfoTagOutputDTO
   */
  tagUsage?: DnaInfoTagUsage;
  /**
   *
   * @type {boolean}
   * @memberof DnaInfoTagOutputDTO
   */
  isDisabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof DnaInfoTagOutputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof DnaInfoTagOutputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof DnaInfoTagOutputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof DnaInfoTagOutputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof DnaInfoTagOutputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof DnaInfoTagOutputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DnaInfoTagUsage {
  None = 'None',
  Pressure = 'Pressure',
  Temperature = 'Temperature',
  TankLevelMass = 'TankLevelMass',
}

/**
 *
 * @export
 * @interface EdgeAgentGetLogsInputDTO
 */
export interface EdgeAgentGetLogsInputDTO {
  /**
   *
   * @type {string}
   * @memberof EdgeAgentGetLogsInputDTO
   */
  schemaVersion?: string | null;
  /**
   *
   * @type {Array<Item>}
   * @memberof EdgeAgentGetLogsInputDTO
   */
  items?: Array<Item> | null;
  /**
   *
   * @type {string}
   * @memberof EdgeAgentGetLogsInputDTO
   */
  encoding?: string | null;
  /**
   *
   * @type {string}
   * @memberof EdgeAgentGetLogsInputDTO
   */
  contentType?: string | null;
}
/**
 *
 * @export
 * @interface EdgeAgentGetLogsOutputStringDTO
 */
export interface EdgeAgentGetLogsOutputStringDTO {
  /**
   *
   * @type {string}
   * @memberof EdgeAgentGetLogsOutputStringDTO
   */
  id?: string | null;
  /**
   *
   * @type {number}
   * @memberof EdgeAgentGetLogsOutputStringDTO
   */
  payloadBytes?: number | null;
  /**
   *
   * @type {string}
   * @memberof EdgeAgentGetLogsOutputStringDTO
   */
  payload?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EdgeGatewayLinkedEntityType {
  Site = 'Site',
  Asset = 'Asset',
}

/**
 *
 * @export
 * @interface EdgeGatewayOutputDto
 */
export interface EdgeGatewayOutputDto {
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  edgeGatewayId: string;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  iotHubDeviceId: string;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  siteId?: string | null;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  siteDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  assetId?: string | null;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  assetDisplayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EdgeGatewayOutputDto
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof EdgeGatewayOutputDto
   */
  lastConnected?: string | null;
  /**
   *
   * @type {Array<OpcUaServerOutputDTO>}
   * @memberof EdgeGatewayOutputDto
   */
  opcUaServers: Array<OpcUaServerOutputDTO>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EdgeGatewaySortColumnName {
  DisplayName = 'DisplayName',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EdgeGatewayTagUsage {
  None = 'None',
  Pressure = 'Pressure',
  Temperature = 'Temperature',
  TankLevelMass = 'TankLevelMass',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EquipmentType {
  Bay = 'Bay',
  Tank = 'Tank',
  Lot = 'Lot',
}

/**
 *
 * @export
 * @interface Filter
 */
export interface Filter {
  /**
   *
   * @type {number}
   * @memberof Filter
   */
  tail?: number;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  since?: string | null;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  until?: string | null;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  loglevel?: string | null;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  regex?: string | null;
}
/**
 *
 * @export
 * @interface GetAllApiTagDataSetsOutputDTO
 */
export interface GetAllApiTagDataSetsOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAllApiTagDataSetsOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GetAllApiTagDataSetsOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {ApiTagDataSource}
   * @memberof GetAllApiTagDataSetsOutputDTO
   */
  apiDataSource: ApiTagDataSource;
  /**
   *
   * @type {boolean}
   * @memberof GetAllApiTagDataSetsOutputDTO
   */
  hasApiTags: boolean;
}
/**
 *
 * @export
 * @interface GetAllProductsProductOutputDTO
 */
export interface GetAllProductsProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllProductsProductOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof GetAllProductsProductOutputDTO
   */
  density?: number | null;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  baseProductId: string;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  baseProductDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  baseProductMeasurementUnitId: string;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  baseProductMeasurementUnitAbbreviation: string;
  /**
   *
   * @type {string}
   * @memberof GetAllProductsProductOutputDTO
   */
  baseProductCode: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllProductsProductOutputDTO
   */
  allowWithAllCustomers: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAllProductsProductOutputDTO
   */
  allowWithAllLogisticsOperators: boolean;
}
/**
 *
 * @export
 * @interface GetAllScopesOutputDTO
 */
export interface GetAllScopesOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAllScopesOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GetAllScopesOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof GetAllScopesOutputDTO
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface GetAllSitesSiteOutputDTO
 */
export interface GetAllSitesSiteOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAllSitesSiteOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSitesSiteOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {SiteType}
   * @memberof GetAllSitesSiteOutputDTO
   */
  type?: SiteType;
  /**
   *
   * @type {string}
   * @memberof GetAllSitesSiteOutputDTO
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSitesSiteOutputDTO
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSitesSiteOutputDTO
   */
  country: string;
  /**
   * Always false for now
   * @type {boolean}
   * @memberof GetAllSitesSiteOutputDTO
   */
  isDisabled: boolean;
}
/**
 *
 * @export
 * @interface GetCompanyByIdChildCompanyOutputDTO
 */
export interface GetCompanyByIdChildCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  parentCompanyTypeId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  parentCompanyTypeDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyTypeId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyTypeDisplayName: string;
}
/**
 *
 * @export
 * @interface GetCompanyByIdOutputDTO
 */
export interface GetCompanyByIdOutputDTO {
  /**
   * Company Guid
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  companyId?: string;
  /**
   * Organization Guid
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  organizationId?: string;
  /**
   * Company name
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  displayName: string;
  /**
   * VAT
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  vat: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  iotHubHostName?: string | null;
  /**
   *
   * @type {RequiredAzureResources}
   * @memberof GetCompanyByIdOutputDTO
   */
  requiredAzureResources: RequiredAzureResources;
  /**
   *
   * @type {Array<GetCompanyByIdTypeOutputDTO>}
   * @memberof GetCompanyByIdOutputDTO
   */
  assignedTypes: Array<GetCompanyByIdTypeOutputDTO>;
  /**
   *
   * @type {Array<GetCompanyByIdChildCompanyOutputDTO>}
   * @memberof GetCompanyByIdOutputDTO
   */
  childCompanies: Array<GetCompanyByIdChildCompanyOutputDTO>;
  /**
   *
   * @type {Array<CompanyParameterOutputDTO>}
   * @memberof GetCompanyByIdOutputDTO
   */
  parameters: Array<CompanyParameterOutputDTO>;
}
/**
 *
 * @export
 * @interface GetCompanyByIdTypeOutputDTO
 */
export interface GetCompanyByIdTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdTypeOutputDTO
   */
  typeId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdTypeOutputDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyByIdTypeOutputDTO
   */
  hasCompanyHierarchy: boolean;
}
/**
 *
 * @export
 * @interface GetCompanyTypesTypeOutputDTO
 */
export interface GetCompanyTypesTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  companyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  hasCompanyHierarchy?: boolean;
}
/**
 *
 * @export
 * @interface GetScopeByIdOutputDTO
 */
export interface GetScopeByIdOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetScopeByIdOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GetScopeByIdOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof GetScopeByIdOutputDTO
   */
  description?: string | null;
  /**
   *
   * @type {GetScopeByIdSiteSelectionType}
   * @memberof GetScopeByIdOutputDTO
   */
  siteSelectionType: GetScopeByIdSiteSelectionType;
  /**
   *
   * @type {Array<string>}
   * @memberof GetScopeByIdOutputDTO
   */
  sites: Array<string>;
  /**
   *
   * @type {Array<GetScopeByIdSiteSiteType>}
   * @memberof GetScopeByIdOutputDTO
   */
  siteTypes: Array<GetScopeByIdSiteSiteType>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum GetScopeByIdSiteSelectionType {
  None = 'None',
  AllSites = 'AllSites',
  SelectedTypes = 'SelectedTypes',
  SelectedSites = 'SelectedSites',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum GetScopeByIdSiteSiteType {
  DistributionTerminal = 'DistributionTerminal',
  LoadingStation = 'LoadingStation',
  FuelingStation = 'FuelingStation',
  CustomerTerminal = 'CustomerTerminal',
  Depot = 'Depot',
}

/**
 *
 * @export
 * @interface ImportTagsOutputDTO
 */
export interface ImportTagsOutputDTO {
  /**
   *
   * @type {number}
   * @memberof ImportTagsOutputDTO
   */
  modifiedServers?: number;
  /**
   *
   * @type {number}
   * @memberof ImportTagsOutputDTO
   */
  modifiedTags?: number;
  /**
   *
   * @type {number}
   * @memberof ImportTagsOutputDTO
   */
  addedServers?: number;
  /**
   *
   * @type {number}
   * @memberof ImportTagsOutputDTO
   */
  addedTags?: number;
  /**
   *
   * @type {number}
   * @memberof ImportTagsOutputDTO
   */
  removedServers?: number;
  /**
   *
   * @type {number}
   * @memberof ImportTagsOutputDTO
   */
  removedTags?: number;
}
/**
 *
 * @export
 * @interface Item
 */
export interface Item {
  /**
   *
   * @type {string}
   * @memberof Item
   */
  id?: string | null;
  /**
   *
   * @type {Filter}
   * @memberof Item
   */
  filter?: Filter;
}
/**
 *
 * @export
 * @interface LookupAssetOutputDTO
 */
export interface LookupAssetOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupAssetOutputDTO
   */
  assetId: string;
  /**
   *
   * @type {string}
   * @memberof LookupAssetOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupAssetOutputDTO
   */
  type: string;
}
/**
 *
 * @export
 * @interface LookupBaseProductOutputDTO
 */
export interface LookupBaseProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupBaseProductOutputDTO
   */
  baseProductId: string;
  /**
   *
   * @type {string}
   * @memberof LookupBaseProductOutputDTO
   */
  baseProductDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupBaseProductOutputDTO
   */
  baseProductCode: string;
  /**
   *
   * @type {string}
   * @memberof LookupBaseProductOutputDTO
   */
  baseProductMeasurementUnitAbbreviation: string;
}
/**
 *
 * @export
 * @interface LookupChildSiteOutputDTO
 */
export interface LookupChildSiteOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupChildSiteOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof LookupChildSiteOutputDTO
   */
  siteDisplayName: string;
}
/**
 *
 * @export
 * @interface LookupCustomerOutputDTO
 */
export interface LookupCustomerOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupCustomerOutputDTO
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof LookupCustomerOutputDTO
   */
  customerDisplayName: string;
}
/**
 *
 * @export
 * @interface LookupEquipmentOutputDTO
 */
export interface LookupEquipmentOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupEquipmentOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof LookupEquipmentOutputDTO
   */
  siteDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupEquipmentOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof LookupEquipmentOutputDTO
   */
  equipmentDisplayName: string;
}
/**
 *
 * @export
 * @interface LookupLogisticsOperatorOutputDTO
 */
export interface LookupLogisticsOperatorOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupLogisticsOperatorOutputDTO
   */
  logisticsOperatorId: string;
  /**
   *
   * @type {string}
   * @memberof LookupLogisticsOperatorOutputDTO
   */
  logisticsOperatorDisplayName: string;
}
/**
 *
 * @export
 * @interface LookupMeasurementUnitOutputDTO
 */
export interface LookupMeasurementUnitOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupMeasurementUnitOutputDTO
   */
  measurementUnitId: string;
  /**
   *
   * @type {string}
   * @memberof LookupMeasurementUnitOutputDTO
   */
  measurementUnitAbbreviation: string;
  /**
   * If true, this unit can be used in e.g. forecasting  calculations as it supports conversions to other units.
   * @type {boolean}
   * @memberof LookupMeasurementUnitOutputDTO
   */
  isMeasurementUnitStronglyDefined: boolean;
}
/**
 *
 * @export
 * @interface LookupOwnerCompanyOutputDTO
 */
export interface LookupOwnerCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupOwnerCompanyOutputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof LookupOwnerCompanyOutputDTO
   */
  companyDisplayName: string;
}
/**
 *
 * @export
 * @interface LookupOwnerCompanyProductOutputDTO
 */
export interface LookupOwnerCompanyProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupOwnerCompanyProductOutputDTO
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof LookupOwnerCompanyProductOutputDTO
   */
  productDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupOwnerCompanyProductOutputDTO
   */
  productCode: string;
}
/**
 *
 * @export
 * @interface LookupParameterOutputDTO
 */
export interface LookupParameterOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupParameterOutputDTO
   */
  parameterId: string;
  /**
   *
   * @type {string}
   * @memberof LookupParameterOutputDTO
   */
  parameterDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupParameterOutputDTO
   */
  parameterCode: string;
  /**
   *
   * @type {string}
   * @memberof LookupParameterOutputDTO
   */
  parameterDefaultValue: string;
  /**
   *
   * @type {string}
   * @memberof LookupParameterOutputDTO
   */
  parameterGroupDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupParameterOutputDTO
   */
  parameterGroupCode: string;
  /**
   *
   * @type {ParameterEntity}
   * @memberof LookupParameterOutputDTO
   */
  parameterEntity: ParameterEntity;
  /**
   *
   * @type {ParameterDataType}
   * @memberof LookupParameterOutputDTO
   */
  parameterDataType: ParameterDataType;
  /**
   *
   * @type {Array<string>}
   * @memberof LookupParameterOutputDTO
   */
  parameterAllowedValues: Array<string>;
}
/**
 *
 * @export
 * @interface LookupProductCompaniesWithIncompleteReservationsOutputDTO
 */
export interface LookupProductCompaniesWithIncompleteReservationsOutputDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof LookupProductCompaniesWithIncompleteReservationsOutputDTO
   */
  customerIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof LookupProductCompaniesWithIncompleteReservationsOutputDTO
   */
  logisticsOperatorIds: Array<string>;
}
/**
 *
 * @export
 * @interface LookupProductOutputDTO
 */
export interface LookupProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupProductOutputDTO
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof LookupProductOutputDTO
   */
  productDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupProductOutputDTO
   */
  productCode: string;
}
/**
 *
 * @export
 * @interface LookupProductsAllowedForLogisticsOperatorOutputDTO
 */
export interface LookupProductsAllowedForLogisticsOperatorOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupProductsAllowedForLogisticsOperatorOutputDTO
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof LookupProductsAllowedForLogisticsOperatorOutputDTO
   */
  productDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupProductsAllowedForLogisticsOperatorOutputDTO
   */
  productCode: string;
}
/**
 *
 * @export
 * @interface LookupProvidersOutputDTO
 */
export interface LookupProvidersOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupProvidersOutputDTO
   */
  providerId: string;
  /**
   *
   * @type {string}
   * @memberof LookupProvidersOutputDTO
   */
  providerDisplayName: string;
}
/**
 *
 * @export
 * @interface LookupScopeOutputDTO
 */
export interface LookupScopeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupScopeOutputDTO
   */
  scopeId: string;
  /**
   *
   * @type {string}
   * @memberof LookupScopeOutputDTO
   */
  scopeDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof LookupScopeOutputDTO
   */
  scopeDescription: string;
}
/**
 *
 * @export
 * @interface LookupSiteTypesOutputDTO
 */
export interface LookupSiteTypesOutputDTO {
  /**
   *
   * @type {LookupSiteTypesSiteType}
   * @memberof LookupSiteTypesOutputDTO
   */
  siteType: LookupSiteTypesSiteType;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum LookupSiteTypesSiteType {
  DistributionTerminal = 'DistributionTerminal',
  LoadingStation = 'LoadingStation',
  FuelingStation = 'FuelingStation',
  CustomerTerminal = 'CustomerTerminal',
  Depot = 'Depot',
}

/**
 *
 * @export
 * @interface LookupSitesOutputDTO
 */
export interface LookupSitesOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LookupSitesOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LookupSitesOutputDTO
   */
  displayName: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum OpcUaServerAuthenticationMode {
  Anonymous = 'Anonymous',
  UsernamePassword = 'UsernamePassword',
}

/**
 *
 * @export
 * @interface OpcUaServerOutputDTO
 */
export interface OpcUaServerOutputDTO {
  /**
   *
   * @type {string}
   * @memberof OpcUaServerOutputDTO
   */
  serverId: string;
  /**
   *
   * @type {string}
   * @memberof OpcUaServerOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof OpcUaServerOutputDTO
   */
  endpointUrl: string;
  /**
   *
   * @type {number}
   * @memberof OpcUaServerOutputDTO
   */
  publishingInterval: number;
  /**
   *
   * @type {OpcUaServerAuthenticationMode}
   * @memberof OpcUaServerOutputDTO
   */
  authenticationMode: OpcUaServerAuthenticationMode;
  /**
   *
   * @type {string}
   * @memberof OpcUaServerOutputDTO
   */
  username?: string | null;
  /**
   *
   * @type {Array<OpcUaTagOutputDTO>}
   * @memberof OpcUaServerOutputDTO
   */
  tags: Array<OpcUaTagOutputDTO>;
  /**
   *
   * @type {boolean}
   * @memberof OpcUaServerOutputDTO
   */
  useSecurity: boolean;
}
/**
 *
 * @export
 * @interface OpcUaTagOutputDTO
 */
export interface OpcUaTagOutputDTO {
  /**
   *
   * @type {string}
   * @memberof OpcUaTagOutputDTO
   */
  tagId: string;
  /**
   * The id used in time-series data  storage, this is what the  edge gateway should use.
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  dataCollectionTagId: number;
  /**
   *
   * @type {string}
   * @memberof OpcUaTagOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof OpcUaTagOutputDTO
   */
  measurementUnitId: string;
  /**
   *
   * @type {string}
   * @memberof OpcUaTagOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {EdgeGatewayTagUsage}
   * @memberof OpcUaTagOutputDTO
   */
  tagUsage: EdgeGatewayTagUsage;
  /**
   *
   * @type {string}
   * @memberof OpcUaTagOutputDTO
   */
  nodeId: string;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  samplingInterval?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OpcUaTagOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof OpcUaTagOutputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum OrderByType {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ParameterDataType {
  Numeric = 'Numeric',
  String = 'String',
  Toggle = 'Toggle',
  Choice = 'Choice',
  StringCollection = 'StringCollection',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ParameterEntity {
  Company = 'Company',
  Site = 'Site',
  Equipment = 'Equipment',
  Asset = 'Asset',
  Reservation = 'Reservation',
}

/**
 *
 * @export
 * @interface PermissionDTO
 */
export interface PermissionDTO {
  /**
   *
   * @type {string}
   * @memberof PermissionDTO
   */
  id?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof PermissionDTO
   */
  scopes?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  [key: string]: any | any;

  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProblemDetails
   */
  status?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  detail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  instance?: string | null;
}
/**
 *
 * @export
 * @interface ProductCustomerOutputDTO
 */
export interface ProductCustomerOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ProductCustomerOutputDTO
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof ProductCustomerOutputDTO
   */
  customerDisplayName: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductCustomerOutputDTO
   */
  isAllowed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductCustomerOutputDTO
   */
  isDefaultProduct: boolean;
}
/**
 *
 * @export
 * @interface ProductEquipmentOutputDTO
 */
export interface ProductEquipmentOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ProductEquipmentOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof ProductEquipmentOutputDTO
   */
  siteDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof ProductEquipmentOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof ProductEquipmentOutputDTO
   */
  equipmentDisplayName: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductEquipmentOutputDTO
   */
  canProvide: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductEquipmentOutputDTO
   */
  canReceive: boolean;
}
/**
 *
 * @export
 * @interface ProductLogisticsOperatorOutputDTO
 */
export interface ProductLogisticsOperatorOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ProductLogisticsOperatorOutputDTO
   */
  logisticsOperatorId: string;
  /**
   *
   * @type {string}
   * @memberof ProductLogisticsOperatorOutputDTO
   */
  logisticsOperatorDisplayName: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductLogisticsOperatorOutputDTO
   */
  isAllowed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductLogisticsOperatorOutputDTO
   */
  isDefaultProduct: boolean;
}
/**
 * Common output DTO for get by id,  create, and update endpoints.
 * @export
 * @interface ProductOutputDTO
 */
export interface ProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof ProductOutputDTO
   */
  density?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  baseProductId: string;
  /**
   *
   * @type {string}
   * @memberof ProductOutputDTO
   */
  baseProductDisplayName: string;
  /**
   *
   * @type {Array<ProductEquipmentOutputDTO>}
   * @memberof ProductOutputDTO
   */
  equipments: Array<ProductEquipmentOutputDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ProductOutputDTO
   */
  allowWithAllCustomers: boolean;
  /**
   *
   * @type {Array<ProductCustomerOutputDTO>}
   * @memberof ProductOutputDTO
   */
  customers: Array<ProductCustomerOutputDTO>;
  /**
   *
   * @type {boolean}
   * @memberof ProductOutputDTO
   */
  allowWithAllLogisticsOperators: boolean;
  /**
   *
   * @type {Array<ProductLogisticsOperatorOutputDTO>}
   * @memberof ProductOutputDTO
   */
  logisticsOperators: Array<ProductLogisticsOperatorOutputDTO>;
  /**
   * If true, this product is in use  in a reservation which has not been completed.  In that case the product cannot be disabled or deleted.
   * @type {boolean}
   * @memberof ProductOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ProductSortColumnName {
  DisplayName = 'DisplayName',
  Code = 'Code',
  BaseProductDisplayName = 'BaseProductDisplayName',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum RequiredAzureResources {
  None = 'None',
  DnaConnectDataCollection = 'DnaConnectDataCollection',
  ApiDataCollection = 'ApiDataCollection',
  EdgeGatewayDataCollection = 'EdgeGatewayDataCollection',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ScopeSortColumnName {
  DisplayName = 'DisplayName',
  Description = 'Description',
}

/**
 *
 * @export
 * @interface SearchAssetsAssetOutputDTO
 */
export interface SearchAssetsAssetOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchAssetsAssetOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsAssetOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsAssetOutputDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsAssetOutputDTO
   */
  ownerCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsAssetOutputDTO
   */
  ownerCompanyDisplayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SearchAssetsAssetOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchAssetsAssetOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchAssetsAssetOutputDTO
   */
  isUsedInEdgeGateway: boolean;
}
/**
 *
 * @export
 * @interface SearchAssetsAssetOutputDTOPagedResponse
 */
export interface SearchAssetsAssetOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchAssetsAssetOutputDTO>}
   * @memberof SearchAssetsAssetOutputDTOPagedResponse
   */
  data?: Array<SearchAssetsAssetOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchAssetsAssetOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchAssetsAssetOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchAssetsAssetOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsAssetOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchAssetsAssetOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchAssetsQueryDTO
 */
export interface SearchAssetsQueryDTO {
  /**
   * Id of the company the asset is either linked to  (provider) or its logistics operator.
   * @type {string}
   * @memberof SearchAssetsQueryDTO
   */
  companyId?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsQueryDTO
   */
  assetType?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchAssetsQueryDTO
   */
  ownerCompanyId?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchAssetsQueryDTO
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAssetsQueryDTO
   */
  pageSize?: number;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchAssetsQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {AssetSortColumnName}
   * @memberof SearchAssetsQueryDTO
   */
  sortByColumn?: AssetSortColumnName;
}
/**
 *
 * @export
 * @interface SearchDeliveryDestinationOutputDTO
 */
export interface SearchDeliveryDestinationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  customerCompanyId: string;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  customerCompanyDisplayName: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchDeliveryDestinationOutputDTO
   */
  isUsedInReservations: boolean;
}
/**
 *
 * @export
 * @interface SearchDeliveryDestinationOutputDTOPagedResponse
 */
export interface SearchDeliveryDestinationOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchDeliveryDestinationOutputDTO>}
   * @memberof SearchDeliveryDestinationOutputDTOPagedResponse
   */
  data?: Array<SearchDeliveryDestinationOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchDeliveryDestinationOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchDeliveryDestinationOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchDeliveryDestinationOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchDeliveryDestinationOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchDeliveryDestinationsQueryDTO
 */
export interface SearchDeliveryDestinationsQueryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  customerCompanyId?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {DeliveryDestinationSortColumnName}
   * @memberof SearchDeliveryDestinationsQueryDTO
   */
  sortColumnName?: DeliveryDestinationSortColumnName;
}
/**
 *
 * @export
 * @interface SearchDnaInfoServersDnaInfoServerOutputDTO
 */
export interface SearchDnaInfoServersDnaInfoServerOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTO
   */
  hasDnaInfoTags: boolean;
}
/**
 *
 * @export
 * @interface SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
 */
export interface SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchDnaInfoServersDnaInfoServerOutputDTO>}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
   */
  data?: Array<SearchDnaInfoServersDnaInfoServerOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchDnaInfoServersQueryDTO
 */
export interface SearchDnaInfoServersQueryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchDnaInfoServersQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchDnaInfoServersQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchDnaInfoServersQueryDTO
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchDnaInfoServersQueryDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchDnaInfoServersQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {DnaInfoServerSortColumnName}
   * @memberof SearchDnaInfoServersQueryDTO
   */
  sortByColumn?: DnaInfoServerSortColumnName;
}
/**
 *
 * @export
 * @interface SearchEdgeGatewaysEdgeGatewayOutputDTO
 */
export interface SearchEdgeGatewaysEdgeGatewayOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  siteId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  siteDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  assetId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  assetDisplayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  lastConnected: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTO
   */
  hasOpcUaServers: boolean;
}
/**
 *
 * @export
 * @interface SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
 */
export interface SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchEdgeGatewaysEdgeGatewayOutputDTO>}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
   */
  data?: Array<SearchEdgeGatewaysEdgeGatewayOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchEdgeGatewaysQueryDTO
 */
export interface SearchEdgeGatewaysQueryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEdgeGatewaysQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchEdgeGatewaysQueryDTO
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEdgeGatewaysQueryDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchEdgeGatewaysQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {EdgeGatewaySortColumnName}
   * @memberof SearchEdgeGatewaysQueryDTO
   */
  sortByColumn?: EdgeGatewaySortColumnName;
}
/**
 *
 * @export
 * @interface SearchProductsProductOutputDTO
 */
export interface SearchProductsProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchProductsProductOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof SearchProductsProductOutputDTO
   */
  density?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  baseProductId: string;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  baseProductDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  baseProductMeasurementUnitId: string;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  baseProductMeasurementUnitAbbreviation: string;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTO
   */
  baseProductCode: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchProductsProductOutputDTO
   */
  allowWithAllCustomers: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchProductsProductOutputDTO
   */
  allowWithAllLogisticsOperators: boolean;
}
/**
 *
 * @export
 * @interface SearchProductsProductOutputDTOPagedResponse
 */
export interface SearchProductsProductOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchProductsProductOutputDTO>}
   * @memberof SearchProductsProductOutputDTOPagedResponse
   */
  data?: Array<SearchProductsProductOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchProductsProductOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchProductsProductOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchProductsProductOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsProductOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchProductsProductOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchProductsQueryDTO
 */
export interface SearchProductsQueryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchProductsQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsQueryDTO
   */
  baseProductId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchProductsQueryDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchProductsQueryDTO
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchProductsQueryDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchProductsQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {ProductSortColumnName}
   * @memberof SearchProductsQueryDTO
   */
  sortByColumn?: ProductSortColumnName;
}
/**
 *
 * @export
 * @interface SearchScopesOutputDTO
 */
export interface SearchScopesOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchScopesOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchScopesOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof SearchScopesOutputDTO
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface SearchScopesOutputDTOPagedResponse
 */
export interface SearchScopesOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchScopesOutputDTO>}
   * @memberof SearchScopesOutputDTOPagedResponse
   */
  data?: Array<SearchScopesOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchScopesOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchScopesOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchScopesOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchScopesOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchScopesOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchScopesQueryDTO
 */
export interface SearchScopesQueryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchScopesQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchScopesQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchScopesQueryDTO
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchScopesQueryDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchScopesQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {ScopeSortColumnName}
   * @memberof SearchScopesQueryDTO
   */
  sortByColumn?: ScopeSortColumnName;
}
/**
 *
 * @export
 * @interface SearchSitesQueryDTO
 */
export interface SearchSitesQueryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchSitesQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchSitesQueryDTO
   */
  searchText?: string | null;
  /**
   *
   * @type {SiteType}
   * @memberof SearchSitesQueryDTO
   */
  type?: SiteType;
  /**
   * If specified, only sites in this country are returned.  Must be the alpha-2 code.
   * @type {string}
   * @memberof SearchSitesQueryDTO
   */
  country?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchSitesQueryDTO
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchSitesQueryDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchSitesQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {SiteSortColumnName}
   * @memberof SearchSitesQueryDTO
   */
  sortByColumn?: SiteSortColumnName;
}
/**
 *
 * @export
 * @interface SearchSitesSiteOutputDTO
 */
export interface SearchSitesSiteOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchSitesSiteOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchSitesSiteOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {SiteType}
   * @memberof SearchSitesSiteOutputDTO
   */
  type?: SiteType;
  /**
   *
   * @type {string}
   * @memberof SearchSitesSiteOutputDTO
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof SearchSitesSiteOutputDTO
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof SearchSitesSiteOutputDTO
   */
  country: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchSitesSiteOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchSitesSiteOutputDTO
   */
  isUsedInReservations: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchSitesSiteOutputDTO
   */
  isUsedInEdgeGateway: boolean;
}
/**
 *
 * @export
 * @interface SearchSitesSiteOutputDTOPagedResponse
 */
export interface SearchSitesSiteOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchSitesSiteOutputDTO>}
   * @memberof SearchSitesSiteOutputDTOPagedResponse
   */
  data?: Array<SearchSitesSiteOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchSitesSiteOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchSitesSiteOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchSitesSiteOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchSitesSiteOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchSitesSiteOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SiteEquipmentOutputDTO
 */
export interface SiteEquipmentOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteEquipmentOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {boolean}
   * @memberof SiteEquipmentOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {string}
   * @memberof SiteEquipmentOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {EquipmentType}
   * @memberof SiteEquipmentOutputDTO
   */
  type?: EquipmentType;
  /**
   *
   * @type {Array<SiteEquipmentProductOutputDTO>}
   * @memberof SiteEquipmentOutputDTO
   */
  products: Array<SiteEquipmentProductOutputDTO>;
  /**
   *
   * @type {Array<SiteEquipmentParameterOutputDTO>}
   * @memberof SiteEquipmentOutputDTO
   */
  parameters: Array<SiteEquipmentParameterOutputDTO>;
  /**
   * If true, there are incomplete reservations  linking to this equipment and it cannot be  disabled or deleted.
   * @type {boolean}
   * @memberof SiteEquipmentOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
  /**
   * If true, there are reservations linking  to this equipment and it cannot be deleted.
   * @type {boolean}
   * @memberof SiteEquipmentOutputDTO
   */
  isUsedInReservations: boolean;
  /**
   * If true, there are tags related to this  equipment and it cannot be deleted.
   * @type {boolean}
   * @memberof SiteEquipmentOutputDTO
   */
  hasTags: boolean;
  /**
   * Default interval for loading. If set, overrides value specified on site.
   * @type {number}
   * @memberof SiteEquipmentOutputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * Default interval for unloading. If set, overrides value specified on site.
   * @type {number}
   * @memberof SiteEquipmentOutputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof SiteEquipmentOutputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof SiteEquipmentOutputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
  /**
   *
   * @type {Array<SiteEquipmentReservationRestrictionOutputDTO>}
   * @memberof SiteEquipmentOutputDTO
   */
  reservationRestrictions: Array<SiteEquipmentReservationRestrictionOutputDTO>;
}
/**
 *
 * @export
 * @interface SiteEquipmentParameterOutputDTO
 */
export interface SiteEquipmentParameterOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteEquipmentParameterOutputDTO
   */
  parameterId: string;
  /**
   * Serialized value for the parameter.  Content depends on data type of parameter.
   * @type {string}
   * @memberof SiteEquipmentParameterOutputDTO
   */
  parameterValue: string;
}
/**
 *
 * @export
 * @interface SiteEquipmentProductOutputDTO
 */
export interface SiteEquipmentProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteEquipmentProductOutputDTO
   */
  productId: string;
  /**
   *
   * @type {boolean}
   * @memberof SiteEquipmentProductOutputDTO
   */
  canProvide: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SiteEquipmentProductOutputDTO
   */
  canReceive: boolean;
}
/**
 *
 * @export
 * @interface SiteEquipmentReservationRestrictionOutputDTO
 */
export interface SiteEquipmentReservationRestrictionOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteEquipmentReservationRestrictionOutputDTO
   */
  restrictionId: string;
  /**
   *
   * @type {DayOfWeek}
   * @memberof SiteEquipmentReservationRestrictionOutputDTO
   */
  startDayOfWeek: DayOfWeek;
  /**
   * The time when this restriction starts.  Expressed in local time of the related site.  Format: HH:mm
   * @type {string}
   * @memberof SiteEquipmentReservationRestrictionOutputDTO
   */
  startTime: string;
  /**
   *
   * @type {DayOfWeek}
   * @memberof SiteEquipmentReservationRestrictionOutputDTO
   */
  endDayOfWeek: DayOfWeek;
  /**
   * The time when this restriction ends.  Expressed in local time of the related site.  Format: HH:mm
   * @type {string}
   * @memberof SiteEquipmentReservationRestrictionOutputDTO
   */
  endTime: string;
}
/**
 *
 * @export
 * @interface SiteLocationOutputDTO
 */
export interface SiteLocationOutputDTO {
  /**
   *
   * @type {number}
   * @memberof SiteLocationOutputDTO
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof SiteLocationOutputDTO
   */
  longitude: number;
}
/**
 *
 * @export
 * @interface SiteOutputDTO
 */
export interface SiteOutputDTO {
  /**
   * Site Id
   * @type {string}
   * @memberof SiteOutputDTO
   */
  id?: string;
  /**
   * Company Id for site
   * @type {string}
   * @memberof SiteOutputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  companyDisplayName: string;
  /**
   * Site name
   * @type {string}
   * @memberof SiteOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof SiteOutputDTO
   */
  isDisabled: boolean;
  /**
   *
   * @type {SiteType}
   * @memberof SiteOutputDTO
   */
  type?: SiteType;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  stateOrRegion: string;
  /**
   * Country alpha-2 code
   * @type {string}
   * @memberof SiteOutputDTO
   */
  country: string;
  /**
   * Windows time zone id for the site, e.g.  \"FLE Standard Time\".  Used to localize times in reservation  change emails currently.
   * @type {string}
   * @memberof SiteOutputDTO
   */
  timeZoneId: string;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  contactPhoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof SiteOutputDTO
   */
  code: string;
  /**
   *
   * @type {Array<SiteScopeOutputDTO>}
   * @memberof SiteOutputDTO
   */
  scopes: Array<SiteScopeOutputDTO>;
  /**
   *
   * @type {Array<SiteEquipmentOutputDTO>}
   * @memberof SiteOutputDTO
   */
  equipments: Array<SiteEquipmentOutputDTO>;
  /**
   * If set, a user making a loading reservation in delivery scheduling  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof SiteOutputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user making an unloading reservation in delivery scheduling  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof SiteOutputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof SiteOutputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof SiteOutputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If `true`, logistics operators  can see each others codes in the reservation  calendar for this site.
   * @type {boolean}
   * @memberof SiteOutputDTO
   */
  logisticsOperatorCodesVisibleForReservations: boolean;
  /**
   *
   * @type {Array<SiteParameterOutputDTO>}
   * @memberof SiteOutputDTO
   */
  parameters: Array<SiteParameterOutputDTO>;
  /**
   * If true, this site is in use  in a reservation which has not been completed.  In that case the site cannot be disabled or deleted.
   * @type {boolean}
   * @memberof SiteOutputDTO
   */
  isUsedInIncompleteReservations: boolean;
  /**
   * Ids of child sites of this site.
   * @type {Array<string>}
   * @memberof SiteOutputDTO
   */
  childSiteIds: Array<string>;
  /**
   * If true, this site has one or more  parent sites. Currently this means that the  site cannot have child sites.
   * @type {boolean}
   * @memberof SiteOutputDTO
   */
  hasParentSites: boolean;
  /**
   *
   * @type {SiteLocationOutputDTO}
   * @memberof SiteOutputDTO
   */
  location?: SiteLocationOutputDTO;
}
/**
 *
 * @export
 * @interface SiteParameterOutputDTO
 */
export interface SiteParameterOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteParameterOutputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof SiteParameterOutputDTO
   */
  parameterId: string;
  /**
   * Serialized value for the parameter.  Content depends on data type of parameter.
   * @type {string}
   * @memberof SiteParameterOutputDTO
   */
  parameterValue: string;
}
/**
 *
 * @export
 * @interface SiteScopeOutputDTO
 */
export interface SiteScopeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteScopeOutputDTO
   */
  scopeId: string;
  /**
   *
   * @type {boolean}
   * @memberof SiteScopeOutputDTO
   */
  isIncluded: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SiteSortColumnName {
  DisplayName = 'DisplayName',
  Type = 'Type',
  Street = 'Street',
  City = 'City',
  Country = 'Country',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SiteType {
  DistributionTerminal = 'DistributionTerminal',
  LoadingStation = 'LoadingStation',
  FuelingStation = 'FuelingStation',
  CustomerTerminal = 'CustomerTerminal',
  Depot = 'Depot',
}

/**
 *
 * @export
 * @interface StructureItemDTO
 */
export interface StructureItemDTO {
  /**
   *
   * @type {string}
   * @memberof StructureItemDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StructureItemDTO
   */
  displayName?: string | null;
}
/**
 *
 * @export
 * @interface UpdateApiTagDataSetInputDTO
 */
export interface UpdateApiTagDataSetInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateApiTagDataSetInputDTO
   */
  displayName?: string | null;
  /**
   * Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagDataSource specific API settings.              Key is value from Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiSettingKeys.
   * @type {{ [key: string]: string; }}
   * @memberof UpdateApiTagDataSetInputDTO
   */
  apiSettings?: {[key: string]: string} | null;
  /**
   *
   * @type {Array<UpdateApiTagInputDTO>}
   * @memberof UpdateApiTagDataSetInputDTO
   */
  apiTags?: Array<UpdateApiTagInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateApiTagInputDTO
 */
export interface UpdateApiTagInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateApiTagInputDTO
   */
  tagId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateApiTagInputDTO
   */
  displayName?: string | null;
  /**
   * Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagDataSource specific tag indentifiers.              Key is value from Valmet.Iop.App.Api.V1Config.ApiTagDataSets.Shared.ApiTagIdentifierKeys.
   * @type {{ [key: string]: string; }}
   * @memberof UpdateApiTagInputDTO
   */
  apiIdentifiers?: {[key: string]: string} | null;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof UpdateApiTagInputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {ApiTagLinkedEntityType}
   * @memberof UpdateApiTagInputDTO
   */
  linkedEntityType?: ApiTagLinkedEntityType;
  /**
   * Id for the entity defined in Valmet.Iop.App.Api.V1Config.ApiTagDataSets.UpdateApiTagDataSet.UpdateApiTagInputDTO.LinkedEntityType
   * @type {string}
   * @memberof UpdateApiTagInputDTO
   */
  linkedEntityId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateApiTagInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {ApiTagTagUsage}
   * @memberof UpdateApiTagInputDTO
   */
  tagUsage?: ApiTagTagUsage;
  /**
   *
   * @type {boolean}
   * @memberof UpdateApiTagInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof UpdateApiTagInputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateApiTagInputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateApiTagInputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateApiTagInputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateApiTagInputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateApiTagInputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @interface UpdateAssetInputDTO
 */
export interface UpdateAssetInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateAssetInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateAssetInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UpdateAssetInputDTO
   */
  ownerCompanyId?: string | null;
  /**
   * If true, any product from the provider can be selected with this asset.  If false, only the products linked through  Valmet.Iop.App.Api.V1Config.Assets.UpdateAsset.UpdateAssetInputDTO.Products can be selected.
   * @type {boolean}
   * @memberof UpdateAssetInputDTO
   */
  allowAllProducts?: boolean | null;
  /**
   *
   * @type {Array<UpdateAssetProductInputDTO>}
   * @memberof UpdateAssetInputDTO
   */
  products?: Array<UpdateAssetProductInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateAssetProductInputDTO
 */
export interface UpdateAssetProductInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateAssetProductInputDTO
   */
  productId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateAssetProductInputDTO
   */
  isAllowed?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyChildCompanyInputDTO
 */
export interface UpdateCompanyChildCompanyInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyInputDTO
   */
  parentTypeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyInputDTO
   */
  childCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyInputDTO
   */
  childTypeId?: string | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyChildCompanyOutputDTO
 */
export interface UpdateCompanyChildCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  parentCompanyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  parentCompanyTypeDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyTypeDisplayName?: string | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyInputDTO
 */
export interface UpdateCompanyInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyInputDTO
   */
  vat?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCompanyInputDTO
   */
  companyTypeIds?: Array<string> | null;
  /**
   *
   * @type {Array<UpdateCompanyChildCompanyInputDTO>}
   * @memberof UpdateCompanyInputDTO
   */
  childCompanies?: Array<UpdateCompanyChildCompanyInputDTO> | null;
  /**
   *
   * @type {RequiredAzureResources}
   * @memberof UpdateCompanyInputDTO
   */
  requiredAzureResources?: RequiredAzureResources;
  /**
   * List of parameters to set on the company.
   * @type {Array<UpdateCompanyParameterInputDTO>}
   * @memberof UpdateCompanyInputDTO
   */
  parameters?: Array<UpdateCompanyParameterInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyOutputDTO
 */
export interface UpdateCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyOutputDTO
   */
  vat?: string | null;
  /**
   *
   * @type {Array<UpdateCompanyTypeOutputDTO>}
   * @memberof UpdateCompanyOutputDTO
   */
  companyTypes?: Array<UpdateCompanyTypeOutputDTO> | null;
  /**
   *
   * @type {Array<UpdateCompanyChildCompanyOutputDTO>}
   * @memberof UpdateCompanyOutputDTO
   */
  childCompanies?: Array<UpdateCompanyChildCompanyOutputDTO> | null;
  /**
   *
   * @type {RequiredAzureResources}
   * @memberof UpdateCompanyOutputDTO
   */
  requiredAzureResources?: RequiredAzureResources;
}
/**
 *
 * @export
 * @interface UpdateCompanyParameterInputDTO
 */
export interface UpdateCompanyParameterInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyParameterInputDTO
   */
  parameterId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyParameterInputDTO
   */
  parameterValue?: string | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyTypeOutputDTO
 */
export interface UpdateCompanyTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyTypeOutputDTO
   */
  typeId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyTypeOutputDTO
   */
  typeDisplayName?: string | null;
}
/**
 *
 * @export
 * @interface UpdateDeliveryDestinationInputDTO
 */
export interface UpdateDeliveryDestinationInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateDeliveryDestinationInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateDeliveryDestinationInputDTO
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateDeliveryDestinationInputDTO
   */
  address?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateDeliveryDestinationInputDTO
   */
  isDisabled?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateDnaInfoServerInputDTO
 */
export interface UpdateDnaInfoServerInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateDnaInfoServerInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {Array<UpdateDnaInfoTagInputDTO>}
   * @memberof UpdateDnaInfoServerInputDTO
   */
  dnaInfoTags?: Array<UpdateDnaInfoTagInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateDnaInfoTagInputDTO
 */
export interface UpdateDnaInfoTagInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  tagId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  dnaInfoTagName?: string | null;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  measurementUnitId?: string | null;
  /**
   *
   * @type {DnaInfoTagLinkedEntityType}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  linkedEntityType?: DnaInfoTagLinkedEntityType;
  /**
   * Id for the entity defined in Valmet.Iop.App.Api.V1Config.DnaInfoServers.UpdateDnaInfoServer.UpdateDnaInfoTagInputDTO.LinkedEntityType
   * @type {string}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  linkedEntityId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {DnaInfoTagUsage}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  tagUsage?: DnaInfoTagUsage;
  /**
   *
   * @type {boolean}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateDnaInfoTagInputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @interface UpdateEdgeGatewayInputDTO
 */
export interface UpdateEdgeGatewayInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateEdgeGatewayInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEdgeGatewayInputDTO
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateEdgeGatewayInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {EdgeGatewayLinkedEntityType}
   * @memberof UpdateEdgeGatewayInputDTO
   */
  linkedEntityType?: EdgeGatewayLinkedEntityType;
  /**
   * Id for the entity defined in Valmet.Iop.App.Api.V1Config.EdgeGateways.UpdateEdgeGateway.UpdateEdgeGatewayInputDTO.LinkedEntityType
   * @type {string}
   * @memberof UpdateEdgeGatewayInputDTO
   */
  linkedEntityId?: string | null;
  /**
   *
   * @type {Array<UpdateEdgeGatewayOpcUaServerInputDTO>}
   * @memberof UpdateEdgeGatewayInputDTO
   */
  opcUaServers?: Array<UpdateEdgeGatewayOpcUaServerInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateEdgeGatewayOpcUaServerInputDTO
 */
export interface UpdateEdgeGatewayOpcUaServerInputDTO {
  /**
   * Id of the existing server or null/empty  for new server.
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  serverId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  displayName?: string | null;
  /**
   * The URL through which to reach the server
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  endpointUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  publishingIntervalMilliseconds?: number | null;
  /**
   *
   * @type {OpcUaServerAuthenticationMode}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  authenticationMode?: OpcUaServerAuthenticationMode;
  /**
   * Required if AuthenticationMode is  UsernamePassword
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  username?: string | null;
  /**
   * Required if AuthenticationMode is  UsernamePassword.  Send this as null to keep the current value.  Empty string will clear the value we have.
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  password?: string | null;
  /**
   * Set to true to use secure OPC UA mode  to communicate with OPC UA server.
   * @type {boolean}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  useSecurity?: boolean | null;
  /**
   *
   * @type {Array<UpdateEdgeGatewayOpcUaTagInputDTO>}
   * @memberof UpdateEdgeGatewayOpcUaServerInputDTO
   */
  tags?: Array<UpdateEdgeGatewayOpcUaTagInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateEdgeGatewayOpcUaTagInputDTO
 */
export interface UpdateEdgeGatewayOpcUaTagInputDTO {
  /**
   * Id of existing tag or null/empty for new tag.
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  tagId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  displayName?: string | null;
  /**
   * Must be a strongly defined  Units.NET unit if TagUsage is defined.
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  measurementUnitId?: string | null;
  /**
   * Can only be defined if EGW is linked to  a site.
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {EdgeGatewayTagUsage}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  tagUsage?: EdgeGatewayTagUsage;
  /**
   *
   * @type {string}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  nodeId?: string | null;
  /**
   * By default the server publishing interval  is used as the sampling interval.  If this is specified, then  this is used instead.
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  samplingIntervalMilliseconds?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  limitInvalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  limitInvalidLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  limitAlarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  limitAlarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  limitWarningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateEdgeGatewayOpcUaTagInputDTO
   */
  limitWarningLow?: number | null;
}
/**
 *
 * @export
 * @interface UpdateProductCustomerInputDTO
 */
export interface UpdateProductCustomerInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateProductCustomerInputDTO
   */
  customerId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProductCustomerInputDTO
   */
  isAllowed?: boolean | null;
  /**
   * If true, the product being updated  will be set as a default for this customer  in the context of the product\'s  provider.  An error will be returned if the customer  already has another default product.
   * @type {boolean}
   * @memberof UpdateProductCustomerInputDTO
   */
  isDefaultProduct?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateProductEquipmentInputDTO
 */
export interface UpdateProductEquipmentInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateProductEquipmentInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProductEquipmentInputDTO
   */
  canProvide?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProductEquipmentInputDTO
   */
  canReceive?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateProductInputDTO
 */
export interface UpdateProductInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateProductInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProductInputDTO
   */
  isDisabled?: boolean | null;
  /**
   * Optional. Will be set to null when value is \"\".
   * @type {string}
   * @memberof UpdateProductInputDTO
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProductInputDTO
   */
  baseProductId?: string | null;
  /**
   * Optional. Will be set to null when value is \"\".
   * @type {string}
   * @memberof UpdateProductInputDTO
   */
  measurementUnitId?: string | null;
  /**
   * Optional. Will be set to null when value is 0.
   * @type {number}
   * @memberof UpdateProductInputDTO
   */
  density?: number | null;
  /**
   * List of equipments (loading bays etc.)  that are able to provide/receive the product.  A null or empty list will cause no changes  to be made.  An equipment that is not in the list will not be affected.
   * @type {Array<UpdateProductEquipmentInputDTO>}
   * @memberof UpdateProductInputDTO
   */
  equipments?: Array<UpdateProductEquipmentInputDTO> | null;
  /**
   * If true, the Valmet.Iop.App.Api.V1Config.Products.UpdateProduct.UpdateProductCustomerInputDTO.IsAllowed  field in Valmet.Iop.App.Api.V1Config.Products.UpdateProduct.UpdateProductInputDTO.Customers will have no effect,  as they will all be allowed.
   * @type {boolean}
   * @memberof UpdateProductInputDTO
   */
  allowWithAllCustomers?: boolean | null;
  /**
   * List of customers for this product.  A null or empty list will cause no changes  to be made.  A customer that is not in the list will not be affected.
   * @type {Array<UpdateProductCustomerInputDTO>}
   * @memberof UpdateProductInputDTO
   */
  customers?: Array<UpdateProductCustomerInputDTO> | null;
  /**
   * If true, the Valmet.Iop.App.Api.V1Config.Products.UpdateProduct.UpdateProductLogisticsOperatorInputDTO.IsAllowed  field in Valmet.Iop.App.Api.V1Config.Products.UpdateProduct.UpdateProductInputDTO.LogisticsOperators will have no effect,  as they will all be allowed.
   * @type {boolean}
   * @memberof UpdateProductInputDTO
   */
  allowWithAllLogisticsOperators?: boolean | null;
  /**
   * List of logistics operators for this product.  A null or empty list will cause no changes  to be made.  A logistics operator missing from the list will not be affected.
   * @type {Array<UpdateProductLogisticsOperatorInputDTO>}
   * @memberof UpdateProductInputDTO
   */
  logisticsOperators?: Array<UpdateProductLogisticsOperatorInputDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateProductLogisticsOperatorInputDTO
 */
export interface UpdateProductLogisticsOperatorInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateProductLogisticsOperatorInputDTO
   */
  logisticsOperatorId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProductLogisticsOperatorInputDTO
   */
  isAllowed?: boolean | null;
  /**
   * If true, the product being updated  will be set as a default for this logistics operator  in the context of the product\'s  provider.  An error will be returned if the logistics operator  already has another default product.
   * @type {boolean}
   * @memberof UpdateProductLogisticsOperatorInputDTO
   */
  isDefaultProduct?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateScopeCommandDTO
 */
export interface UpdateScopeCommandDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateScopeCommandDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateScopeCommandDTO
   */
  description?: string | null;
  /**
   *
   * @type {UpdateScopeSiteSelectionType}
   * @memberof UpdateScopeCommandDTO
   */
  siteSelectionType?: UpdateScopeSiteSelectionType;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateScopeCommandDTO
   */
  selectedSites?: Array<string> | null;
  /**
   *
   * @type {Array<UpdateScopeSiteType>}
   * @memberof UpdateScopeCommandDTO
   */
  selectedSiteTypes?: Array<UpdateScopeSiteType> | null;
}
/**
 *
 * @export
 * @interface UpdateScopeOutputDTO
 */
export interface UpdateScopeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateScopeOutputDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateScopeOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateScopeOutputDTO
   */
  description?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum UpdateScopeSiteSelectionType {
  None = 'None',
  AllSites = 'AllSites',
  SelectedTypes = 'SelectedTypes',
  SelectedSites = 'SelectedSites',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum UpdateScopeSiteType {
  DistributionTerminal = 'DistributionTerminal',
  LoadingStation = 'LoadingStation',
  FuelingStation = 'FuelingStation',
  CustomerTerminal = 'CustomerTerminal',
  Depot = 'Depot',
}

/**
 *
 * @export
 * @interface UpdateSiteChildSiteInputDTO
 */
export interface UpdateSiteChildSiteInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteChildSiteInputDTO
   */
  siteId?: string | null;
  /**
   * True will ensure the site is a child site,  False will ensure the site is not a child site.
   * @type {boolean}
   * @memberof UpdateSiteChildSiteInputDTO
   */
  isChild?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateSiteEquipmentInputDTO
 */
export interface UpdateSiteEquipmentInputDTO {
  /**
   * If not null, must contain the id  of the equipment being edited.  If null, equipment is assumed to be new.
   * @type {string}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {EquipmentType}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  type?: EquipmentType;
  /**
   *
   * @type {Array<UpdateSiteEquipmentProductInputDTO>}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  products?: Array<UpdateSiteEquipmentProductInputDTO> | null;
  /**
   *
   * @type {Array<UpdateSiteEquipmentParameterInputDTO>}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  parameters?: Array<UpdateSiteEquipmentParameterInputDTO> | null;
  /**
   * If set, a user making a loading reservation in delivery scheduling  on this equipment will get this long of a reservation by default  once they set the starting time. Overrides value set on site level.
   * @type {number}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user making an unloading reservation in delivery scheduling  on this equipment will get this long of a reservation by default  once they set the starting time. Overrides value set on site level.
   * @type {number}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
  /**
   *
   * @type {Array<UpdateSiteEquipmentReservationRestrictionDTO>}
   * @memberof UpdateSiteEquipmentInputDTO
   */
  reservationRestrictions?: Array<UpdateSiteEquipmentReservationRestrictionDTO> | null;
}
/**
 *
 * @export
 * @interface UpdateSiteEquipmentParameterInputDTO
 */
export interface UpdateSiteEquipmentParameterInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteEquipmentParameterInputDTO
   */
  parameterId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteEquipmentParameterInputDTO
   */
  parameterValue?: string | null;
}
/**
 *
 * @export
 * @interface UpdateSiteEquipmentProductInputDTO
 */
export interface UpdateSiteEquipmentProductInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteEquipmentProductInputDTO
   */
  productId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteEquipmentProductInputDTO
   */
  canProvide?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteEquipmentProductInputDTO
   */
  canReceive?: boolean | null;
}
/**
 *
 * @export
 * @interface UpdateSiteEquipmentReservationRestrictionDTO
 */
export interface UpdateSiteEquipmentReservationRestrictionDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteEquipmentReservationRestrictionDTO
   */
  restrictionId?: string | null;
  /**
   *
   * @type {DayOfWeek}
   * @memberof UpdateSiteEquipmentReservationRestrictionDTO
   */
  startDayOfWeek?: DayOfWeek;
  /**
   * The time when this restriction starts.  Expressed in local time of the related site.  Format: HH:mm
   * @type {string}
   * @memberof UpdateSiteEquipmentReservationRestrictionDTO
   */
  startTime?: string | null;
  /**
   *
   * @type {DayOfWeek}
   * @memberof UpdateSiteEquipmentReservationRestrictionDTO
   */
  endDayOfWeek?: DayOfWeek;
  /**
   * The time when this restriction ends.  Expressed in local time of the related site.  Format: HH:mm
   * @type {string}
   * @memberof UpdateSiteEquipmentReservationRestrictionDTO
   */
  endTime?: string | null;
}
/**
 *
 * @export
 * @interface UpdateSiteInputDTO
 */
export interface UpdateSiteInputDTO {
  /**
   * Site name
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteInputDTO
   */
  isDisabled?: boolean | null;
  /**
   *
   * @type {SiteType}
   * @memberof UpdateSiteInputDTO
   */
  type?: SiteType;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  postalCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  stateOrRegion?: string | null;
  /**
   * Country alpha-2 code
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  country?: string | null;
  /**
   * Windows time zone id for the site, e.g.  \"FLE Standard Time\".  Used to localize times in reservation  change emails currently.
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  timeZoneId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  contactPhoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteInputDTO
   */
  code?: string | null;
  /**
   * List of scopes that define in which of them  this site is included.
   * @type {Array<UpdateSiteScopeInputDTO>}
   * @memberof UpdateSiteInputDTO
   */
  scopes?: Array<UpdateSiteScopeInputDTO> | null;
  /**
   * List of equipments contained in this site.
   * @type {Array<UpdateSiteEquipmentInputDTO>}
   * @memberof UpdateSiteInputDTO
   */
  equipments?: Array<UpdateSiteEquipmentInputDTO> | null;
  /**
   * If set, a user making a loading reservation in delivery scheduling  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof UpdateSiteInputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user making an unloading reservation in delivery scheduling  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof UpdateSiteInputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof UpdateSiteInputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof UpdateSiteInputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If `true`, logistics operators  can see each others codes in the reservation  calendar for this site.
   * @type {boolean}
   * @memberof UpdateSiteInputDTO
   */
  logisticsOperatorCodesVisibleForReservations?: boolean | null;
  /**
   * List of parameters to set on the site.
   * @type {Array<UpdateSiteParameterInputDTO>}
   * @memberof UpdateSiteInputDTO
   */
  parameters?: Array<UpdateSiteParameterInputDTO> | null;
  /**
   * List of child sites and whether to assign/unassign them as  children of this site.
   * @type {Array<UpdateSiteChildSiteInputDTO>}
   * @memberof UpdateSiteInputDTO
   */
  childSites?: Array<UpdateSiteChildSiteInputDTO> | null;
  /**
   *
   * @type {UpdateSiteLocationInputDTO}
   * @memberof UpdateSiteInputDTO
   */
  location?: UpdateSiteLocationInputDTO;
}
/**
 *
 * @export
 * @interface UpdateSiteLocationInputDTO
 */
export interface UpdateSiteLocationInputDTO {
  /**
   *
   * @type {number}
   * @memberof UpdateSiteLocationInputDTO
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateSiteLocationInputDTO
   */
  longitude?: number | null;
}
/**
 *
 * @export
 * @interface UpdateSiteParameterInputDTO
 */
export interface UpdateSiteParameterInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteParameterInputDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteParameterInputDTO
   */
  parameterId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSiteParameterInputDTO
   */
  parameterValue?: string | null;
}
/**
 *
 * @export
 * @interface UpdateSiteScopeInputDTO
 */
export interface UpdateSiteScopeInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteScopeInputDTO
   */
  scopeId?: string | null;
  /**
   * True will add this site to this scope, false will remove.
   * @type {boolean}
   * @memberof UpdateSiteScopeInputDTO
   */
  isIncluded?: boolean | null;
}
/**
 *
 * @export
 * @interface UserInformationOutputDTO
 */
export interface UserInformationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  userId?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserInformationOutputDTO
   */
  userIsAdmin?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  userDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  userEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  organizationName?: string | null;
  /**
   *
   * @type {Array<PermissionDTO>}
   * @memberof UserInformationOutputDTO
   */
  permissions?: Array<PermissionDTO> | null;
}

/**
 * ApiTagDataSetsApi - axios parameter creator
 * @export
 */
export const ApiTagDataSetsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Deletes an API tag data set
     * @param {string} apiTagDataSetId API tag data set id to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsApiTagDataSetIdDelete: async (
      apiTagDataSetId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiTagDataSetId' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsApiTagDataSetIdDelete',
        'apiTagDataSetId',
        apiTagDataSetId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsApiTagDataSetIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/ApiTagDataSets/{apiTagDataSetId}`.replace(
        `{${'apiTagDataSetId'}}`,
        encodeURIComponent(String(apiTagDataSetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get API tag data set by id
     * @param {string} apiTagDataSetId API tag data set alternate id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsApiTagDataSetIdGet: async (
      apiTagDataSetId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiTagDataSetId' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsApiTagDataSetIdGet',
        'apiTagDataSetId',
        apiTagDataSetId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsApiTagDataSetIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/ApiTagDataSets/{apiTagDataSetId}`.replace(
        `{${'apiTagDataSetId'}}`,
        encodeURIComponent(String(apiTagDataSetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update an API tag data set
     * @param {string} apiTagDataSetId API tag data set id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateApiTagDataSetInputDTO} [updateApiTagDataSetInputDTO] API tag data set information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsApiTagDataSetIdPut: async (
      apiTagDataSetId: string,
      apiVersion: string,
      updateApiTagDataSetInputDTO?: UpdateApiTagDataSetInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiTagDataSetId' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsApiTagDataSetIdPut',
        'apiTagDataSetId',
        apiTagDataSetId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsApiTagDataSetIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/ApiTagDataSets/{apiTagDataSetId}`.replace(
        `{${'apiTagDataSetId'}}`,
        encodeURIComponent(String(apiTagDataSetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateApiTagDataSetInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all API tag data sets for given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Company alternate id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsGet: async (
      apiVersion: string,
      companyId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiApiTagDataSetsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/ApiTagDataSets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import a certificate for API tag data set
     * @param {string} apiVersion The requested API version
     * @param {string} [apiTagDataSetId]
     * @param {string} [password]
     * @param {any} [certificate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsImportcertificatePost: async (
      apiVersion: string,
      apiTagDataSetId?: string,
      password?: string,
      certificate?: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiApiTagDataSetsImportcertificatePost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/ApiTagDataSets/importcertificate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      if (apiTagDataSetId !== undefined) {
        localVarFormParams.append('ApiTagDataSetId', apiTagDataSetId as any);
      }

      if (password !== undefined) {
        localVarFormParams.append('Password', password as any);
      }

      if (certificate !== undefined) {
        localVarFormParams.append('certificate', certificate as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create an API tag data set
     * @param {string} apiVersion The requested API version
     * @param {CreateApiTagDataSetInputDTO} [createApiTagDataSetInputDTO] API tag data set information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsPost: async (
      apiVersion: string,
      createApiTagDataSetInputDTO?: CreateApiTagDataSetInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiApiTagDataSetsPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/ApiTagDataSets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApiTagDataSetInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiTagDataSetsApi - functional programming interface
 * @export
 */
export const ApiTagDataSetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ApiTagDataSetsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Deletes an API tag data set
     * @param {string} apiTagDataSetId API tag data set id to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiApiTagDataSetsApiTagDataSetIdDelete(
      apiTagDataSetId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiApiTagDataSetsApiTagDataSetIdDelete(
          apiTagDataSetId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get API tag data set by id
     * @param {string} apiTagDataSetId API tag data set alternate id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiApiTagDataSetsApiTagDataSetIdGet(
      apiTagDataSetId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApiTagDataSetOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiApiTagDataSetsApiTagDataSetIdGet(
          apiTagDataSetId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update an API tag data set
     * @param {string} apiTagDataSetId API tag data set id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateApiTagDataSetInputDTO} [updateApiTagDataSetInputDTO] API tag data set information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiApiTagDataSetsApiTagDataSetIdPut(
      apiTagDataSetId: string,
      apiVersion: string,
      updateApiTagDataSetInputDTO?: UpdateApiTagDataSetInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApiTagDataSetOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiApiTagDataSetsApiTagDataSetIdPut(
          apiTagDataSetId,
          apiVersion,
          updateApiTagDataSetInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all API tag data sets for given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Company alternate id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiApiTagDataSetsGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllApiTagDataSetsOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiApiTagDataSetsGet(
          apiVersion,
          companyId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Import a certificate for API tag data set
     * @param {string} apiVersion The requested API version
     * @param {string} [apiTagDataSetId]
     * @param {string} [password]
     * @param {any} [certificate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiApiTagDataSetsImportcertificatePost(
      apiVersion: string,
      apiTagDataSetId?: string,
      password?: string,
      certificate?: any,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiApiTagDataSetsImportcertificatePost(
          apiVersion,
          apiTagDataSetId,
          password,
          certificate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create an API tag data set
     * @param {string} apiVersion The requested API version
     * @param {CreateApiTagDataSetInputDTO} [createApiTagDataSetInputDTO] API tag data set information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiApiTagDataSetsPost(
      apiVersion: string,
      createApiTagDataSetInputDTO?: CreateApiTagDataSetInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApiTagDataSetOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiApiTagDataSetsPost(
          apiVersion,
          createApiTagDataSetInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ApiTagDataSetsApi - factory interface
 * @export
 */
export const ApiTagDataSetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApiTagDataSetsApiFp(configuration);
  return {
    /**
     *
     * @summary Deletes an API tag data set
     * @param {string} apiTagDataSetId API tag data set id to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsApiTagDataSetIdDelete(
      apiTagDataSetId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiApiTagDataSetsApiTagDataSetIdDelete(
          apiTagDataSetId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get API tag data set by id
     * @param {string} apiTagDataSetId API tag data set alternate id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsApiTagDataSetIdGet(
      apiTagDataSetId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<ApiTagDataSetOutputDTO> {
      return localVarFp
        .apiApiTagDataSetsApiTagDataSetIdGet(
          apiTagDataSetId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update an API tag data set
     * @param {string} apiTagDataSetId API tag data set id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateApiTagDataSetInputDTO} [updateApiTagDataSetInputDTO] API tag data set information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsApiTagDataSetIdPut(
      apiTagDataSetId: string,
      apiVersion: string,
      updateApiTagDataSetInputDTO?: UpdateApiTagDataSetInputDTO,
      options?: any,
    ): AxiosPromise<ApiTagDataSetOutputDTO> {
      return localVarFp
        .apiApiTagDataSetsApiTagDataSetIdPut(
          apiTagDataSetId,
          apiVersion,
          updateApiTagDataSetInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all API tag data sets for given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Company alternate id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): AxiosPromise<Array<GetAllApiTagDataSetsOutputDTO>> {
      return localVarFp
        .apiApiTagDataSetsGet(apiVersion, companyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import a certificate for API tag data set
     * @param {string} apiVersion The requested API version
     * @param {string} [apiTagDataSetId]
     * @param {string} [password]
     * @param {any} [certificate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsImportcertificatePost(
      apiVersion: string,
      apiTagDataSetId?: string,
      password?: string,
      certificate?: any,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiApiTagDataSetsImportcertificatePost(
          apiVersion,
          apiTagDataSetId,
          password,
          certificate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create an API tag data set
     * @param {string} apiVersion The requested API version
     * @param {CreateApiTagDataSetInputDTO} [createApiTagDataSetInputDTO] API tag data set information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiApiTagDataSetsPost(
      apiVersion: string,
      createApiTagDataSetInputDTO?: CreateApiTagDataSetInputDTO,
      options?: any,
    ): AxiosPromise<ApiTagDataSetOutputDTO> {
      return localVarFp
        .apiApiTagDataSetsPost(apiVersion, createApiTagDataSetInputDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ApiTagDataSetsApi - object-oriented interface
 * @export
 * @class ApiTagDataSetsApi
 * @extends {BaseAPI}
 */
export class ApiTagDataSetsApi extends BaseAPI {
  /**
   *
   * @summary Deletes an API tag data set
   * @param {string} apiTagDataSetId API tag data set id to delete
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiTagDataSetsApi
   */
  public apiApiTagDataSetsApiTagDataSetIdDelete(
    apiTagDataSetId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ApiTagDataSetsApiFp(this.configuration)
      .apiApiTagDataSetsApiTagDataSetIdDelete(
        apiTagDataSetId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get API tag data set by id
   * @param {string} apiTagDataSetId API tag data set alternate id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiTagDataSetsApi
   */
  public apiApiTagDataSetsApiTagDataSetIdGet(
    apiTagDataSetId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ApiTagDataSetsApiFp(this.configuration)
      .apiApiTagDataSetsApiTagDataSetIdGet(apiTagDataSetId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update an API tag data set
   * @param {string} apiTagDataSetId API tag data set id to update
   * @param {string} apiVersion The requested API version
   * @param {UpdateApiTagDataSetInputDTO} [updateApiTagDataSetInputDTO] API tag data set information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiTagDataSetsApi
   */
  public apiApiTagDataSetsApiTagDataSetIdPut(
    apiTagDataSetId: string,
    apiVersion: string,
    updateApiTagDataSetInputDTO?: UpdateApiTagDataSetInputDTO,
    options?: any,
  ) {
    return ApiTagDataSetsApiFp(this.configuration)
      .apiApiTagDataSetsApiTagDataSetIdPut(
        apiTagDataSetId,
        apiVersion,
        updateApiTagDataSetInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all API tag data sets for given company
   * @param {string} apiVersion The requested API version
   * @param {string} [companyId] Company alternate id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiTagDataSetsApi
   */
  public apiApiTagDataSetsGet(
    apiVersion: string,
    companyId?: string,
    options?: any,
  ) {
    return ApiTagDataSetsApiFp(this.configuration)
      .apiApiTagDataSetsGet(apiVersion, companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import a certificate for API tag data set
   * @param {string} apiVersion The requested API version
   * @param {string} [apiTagDataSetId]
   * @param {string} [password]
   * @param {any} [certificate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiTagDataSetsApi
   */
  public apiApiTagDataSetsImportcertificatePost(
    apiVersion: string,
    apiTagDataSetId?: string,
    password?: string,
    certificate?: any,
    options?: any,
  ) {
    return ApiTagDataSetsApiFp(this.configuration)
      .apiApiTagDataSetsImportcertificatePost(
        apiVersion,
        apiTagDataSetId,
        password,
        certificate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create an API tag data set
   * @param {string} apiVersion The requested API version
   * @param {CreateApiTagDataSetInputDTO} [createApiTagDataSetInputDTO] API tag data set information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiTagDataSetsApi
   */
  public apiApiTagDataSetsPost(
    apiVersion: string,
    createApiTagDataSetInputDTO?: CreateApiTagDataSetInputDTO,
    options?: any,
  ) {
    return ApiTagDataSetsApiFp(this.configuration)
      .apiApiTagDataSetsPost(apiVersion, createApiTagDataSetInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete an asset
     * @param {string} assetId Asset id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsAssetIdDelete: async (
      assetId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetId' is not null or undefined
      assertParamExists('apiAssetsAssetIdDelete', 'assetId', assetId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiAssetsAssetIdDelete', 'apiVersion', apiVersion);
      const localVarPath = `/api/Assets/{assetId}`.replace(
        `{${'assetId'}}`,
        encodeURIComponent(String(assetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get asset
     * @param {string} assetId Asset id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsAssetIdGet: async (
      assetId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetId' is not null or undefined
      assertParamExists('apiAssetsAssetIdGet', 'assetId', assetId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiAssetsAssetIdGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Assets/{assetId}`.replace(
        `{${'assetId'}}`,
        encodeURIComponent(String(assetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update an asset
     * @param {string} assetId Asset id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateAssetInputDTO} [updateAssetInputDTO] Asset information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsAssetIdPut: async (
      assetId: string,
      apiVersion: string,
      updateAssetInputDTO?: UpdateAssetInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetId' is not null or undefined
      assertParamExists('apiAssetsAssetIdPut', 'assetId', assetId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiAssetsAssetIdPut', 'apiVersion', apiVersion);
      const localVarPath = `/api/Assets/{assetId}`.replace(
        `{${'assetId'}}`,
        encodeURIComponent(String(assetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAssetInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create an asset
     * @param {string} apiVersion The requested API version
     * @param {CreateAssetInputDTO} [createAssetInputDTO] Asset information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsPost: async (
      apiVersion: string,
      createAssetInputDTO?: CreateAssetInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiAssetsPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Assets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssetInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lookup possible owner companies for an asset  with the given provider company.
     * @param {string} providerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet: async (
      providerCompanyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'providerCompanyId' is not null or undefined
      assertParamExists(
        'apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet',
        'providerCompanyId',
        providerCompanyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Assets/providers/{providerCompanyId}/ownerCompanies`.replace(
          `{${'providerCompanyId'}}`,
          encodeURIComponent(String(providerCompanyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lookup allowed products for a given provider company  + owner company combination.
     * @param {string} providerCompanyId
     * @param {string} ownerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet:
      async (
        providerCompanyId: string,
        ownerCompanyId: string,
        apiVersion: string,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'providerCompanyId' is not null or undefined
        assertParamExists(
          'apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet',
          'providerCompanyId',
          providerCompanyId,
        );
        // verify required parameter 'ownerCompanyId' is not null or undefined
        assertParamExists(
          'apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet',
          'ownerCompanyId',
          ownerCompanyId,
        );
        // verify required parameter 'apiVersion' is not null or undefined
        assertParamExists(
          'apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet',
          'apiVersion',
          apiVersion,
        );
        const localVarPath =
          `/api/Assets/providers/{providerCompanyId}/ownerCompanies/{ownerCompanyId}/products`
            .replace(
              `{${'providerCompanyId'}}`,
              encodeURIComponent(String(providerCompanyId)),
            )
            .replace(
              `{${'ownerCompanyId'}}`,
              encodeURIComponent(String(ownerCompanyId)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        // oauth required
        await setOAuthToObject(
          localVarHeaderParameter,
          'Bearer',
          [],
          configuration,
        );

        if (apiVersion !== undefined) {
          localVarQueryParameter['api-version'] = apiVersion;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Search assets
     * @param {string} apiVersion The requested API version
     * @param {SearchAssetsQueryDTO} [searchAssetsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsSearchPost: async (
      apiVersion: string,
      searchAssetsQueryDTO?: SearchAssetsQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiAssetsSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Assets/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchAssetsQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete an asset
     * @param {string} assetId Asset id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsAssetIdDelete(
      assetId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetsAssetIdDelete(
          assetId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get asset
     * @param {string} assetId Asset id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsAssetIdGet(
      assetId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetsAssetIdGet(
          assetId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update an asset
     * @param {string} assetId Asset id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateAssetInputDTO} [updateAssetInputDTO] Asset information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsAssetIdPut(
      assetId: string,
      apiVersion: string,
      updateAssetInputDTO?: UpdateAssetInputDTO,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetsAssetIdPut(
          assetId,
          apiVersion,
          updateAssetInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create an asset
     * @param {string} apiVersion The requested API version
     * @param {CreateAssetInputDTO} [createAssetInputDTO] Asset information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsPost(
      apiVersion: string,
      createAssetInputDTO?: CreateAssetInputDTO,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetOutputDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAssetsPost(
        apiVersion,
        createAssetInputDTO,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lookup possible owner companies for an asset  with the given provider company.
     * @param {string} providerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet(
      providerCompanyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupOwnerCompanyOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet(
          providerCompanyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lookup allowed products for a given provider company  + owner company combination.
     * @param {string} providerCompanyId
     * @param {string} ownerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet(
      providerCompanyId: string,
      ownerCompanyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupOwnerCompanyProductOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet(
          providerCompanyId,
          ownerCompanyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search assets
     * @param {string} apiVersion The requested API version
     * @param {SearchAssetsQueryDTO} [searchAssetsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetsSearchPost(
      apiVersion: string,
      searchAssetsQueryDTO?: SearchAssetsQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchAssetsAssetOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetsSearchPost(
          apiVersion,
          searchAssetsQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssetsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete an asset
     * @param {string} assetId Asset id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsAssetIdDelete(
      assetId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiAssetsAssetIdDelete(assetId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get asset
     * @param {string} assetId Asset id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsAssetIdGet(
      assetId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<AssetOutputDTO> {
      return localVarFp
        .apiAssetsAssetIdGet(assetId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update an asset
     * @param {string} assetId Asset id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateAssetInputDTO} [updateAssetInputDTO] Asset information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsAssetIdPut(
      assetId: string,
      apiVersion: string,
      updateAssetInputDTO?: UpdateAssetInputDTO,
      options?: any,
    ): AxiosPromise<AssetOutputDTO> {
      return localVarFp
        .apiAssetsAssetIdPut(assetId, apiVersion, updateAssetInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create an asset
     * @param {string} apiVersion The requested API version
     * @param {CreateAssetInputDTO} [createAssetInputDTO] Asset information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsPost(
      apiVersion: string,
      createAssetInputDTO?: CreateAssetInputDTO,
      options?: any,
    ): AxiosPromise<AssetOutputDTO> {
      return localVarFp
        .apiAssetsPost(apiVersion, createAssetInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lookup possible owner companies for an asset  with the given provider company.
     * @param {string} providerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet(
      providerCompanyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupOwnerCompanyOutputDTO>> {
      return localVarFp
        .apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet(
          providerCompanyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lookup allowed products for a given provider company  + owner company combination.
     * @param {string} providerCompanyId
     * @param {string} ownerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet(
      providerCompanyId: string,
      ownerCompanyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupOwnerCompanyProductOutputDTO>> {
      return localVarFp
        .apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet(
          providerCompanyId,
          ownerCompanyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search assets
     * @param {string} apiVersion The requested API version
     * @param {SearchAssetsQueryDTO} [searchAssetsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetsSearchPost(
      apiVersion: string,
      searchAssetsQueryDTO?: SearchAssetsQueryDTO,
      options?: any,
    ): AxiosPromise<SearchAssetsAssetOutputDTOPagedResponse> {
      return localVarFp
        .apiAssetsSearchPost(apiVersion, searchAssetsQueryDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
  /**
   *
   * @summary Delete an asset
   * @param {string} assetId Asset id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsAssetIdDelete(
    assetId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsAssetIdDelete(assetId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get asset
   * @param {string} assetId Asset id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsAssetIdGet(
    assetId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsAssetIdGet(assetId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update an asset
   * @param {string} assetId Asset id to update
   * @param {string} apiVersion The requested API version
   * @param {UpdateAssetInputDTO} [updateAssetInputDTO] Asset information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsAssetIdPut(
    assetId: string,
    apiVersion: string,
    updateAssetInputDTO?: UpdateAssetInputDTO,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsAssetIdPut(assetId, apiVersion, updateAssetInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create an asset
   * @param {string} apiVersion The requested API version
   * @param {CreateAssetInputDTO} [createAssetInputDTO] Asset information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsPost(
    apiVersion: string,
    createAssetInputDTO?: CreateAssetInputDTO,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsPost(apiVersion, createAssetInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lookup possible owner companies for an asset  with the given provider company.
   * @param {string} providerCompanyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet(
    providerCompanyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsProvidersProviderCompanyIdOwnerCompaniesGet(
        providerCompanyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lookup allowed products for a given provider company  + owner company combination.
   * @param {string} providerCompanyId
   * @param {string} ownerCompanyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet(
    providerCompanyId: string,
    ownerCompanyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsProvidersProviderCompanyIdOwnerCompaniesOwnerCompanyIdProductsGet(
        providerCompanyId,
        ownerCompanyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search assets
   * @param {string} apiVersion The requested API version
   * @param {SearchAssetsQueryDTO} [searchAssetsQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public apiAssetsSearchPost(
    apiVersion: string,
    searchAssetsQueryDTO?: SearchAssetsQueryDTO,
    options?: any,
  ) {
    return AssetsApiFp(this.configuration)
      .apiAssetsSearchPost(apiVersion, searchAssetsQueryDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all company types by company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdCompanytypesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdCompanytypesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdCompanytypesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Companies/{companyId}/companytypes`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdGet', 'companyId', companyId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update single company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdPut: async (
      companyId: string,
      apiVersion: string,
      updateCompanyInputDTO?: UpdateCompanyInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdPut', 'companyId', companyId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdPut', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCompanyInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company structure
     * @param {string} companyId AlternateId of the company
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdStructureGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdStructureGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdStructureGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Companies/{companyId}/structure`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all companies
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all company types by company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdCompanytypesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetCompanyTypesTypeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdCompanytypesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get single company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompanyByIdOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update single company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdPut(
      companyId: string,
      apiVersion: string,
      updateCompanyInputDTO?: UpdateCompanyInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateCompanyOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdPut(
          companyId,
          apiVersion,
          updateCompanyInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company structure
     * @param {string} companyId AlternateId of the company
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdStructureGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompanyStructureDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdStructureGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all companies
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CompanyOutputDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesGet(
        apiVersion,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompaniesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all company types by company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdCompanytypesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetCompanyTypesTypeOutputDTO>> {
      return localVarFp
        .apiCompaniesCompanyIdCompanytypesGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get single company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<GetCompanyByIdOutputDTO> {
      return localVarFp
        .apiCompaniesCompanyIdGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update single company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdPut(
      companyId: string,
      apiVersion: string,
      updateCompanyInputDTO?: UpdateCompanyInputDTO,
      options?: any,
    ): AxiosPromise<UpdateCompanyOutputDTO> {
      return localVarFp
        .apiCompaniesCompanyIdPut(
          companyId,
          apiVersion,
          updateCompanyInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get company structure
     * @param {string} companyId AlternateId of the company
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdStructureGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<CompanyStructureDTO> {
      return localVarFp
        .apiCompaniesCompanyIdStructureGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all companies
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<CompanyOutputDTO>> {
      return localVarFp
        .apiCompaniesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
  /**
   *
   * @summary Get all company types by company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdCompanytypesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdCompanytypesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get single company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update single company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdPut(
    companyId: string,
    apiVersion: string,
    updateCompanyInputDTO?: UpdateCompanyInputDTO,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdPut(
        companyId,
        apiVersion,
        updateCompanyInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company structure
   * @param {string} companyId AlternateId of the company
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdStructureGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdStructureGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all companies
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesGet(apiVersion: string, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompanyTypesApi - axios parameter creator
 * @export
 */
export const CompanyTypesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all allowed child company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesAllowedChildTypesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompanyTypesAllowedChildTypesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/CompanyTypes/allowedChildTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompanyTypesGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/CompanyTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyTypesApi - functional programming interface
 * @export
 */
export const CompanyTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CompanyTypesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all allowed child company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesAllowedChildTypesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AllowedChildCompanyTypeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesAllowedChildTypesGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CompanyTypeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesGet(apiVersion, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CompanyTypesApi - factory interface
 * @export
 */
export const CompanyTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompanyTypesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all allowed child company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesAllowedChildTypesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<AllowedChildCompanyTypeOutputDTO>> {
      return localVarFp
        .apiCompanyTypesAllowedChildTypesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<CompanyTypeOutputDTO>> {
      return localVarFp
        .apiCompanyTypesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CompanyTypesApi - object-oriented interface
 * @export
 * @class CompanyTypesApi
 * @extends {BaseAPI}
 */
export class CompanyTypesApi extends BaseAPI {
  /**
   *
   * @summary Get all allowed child company types
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesAllowedChildTypesGet(
    apiVersion: string,
    options?: any,
  ) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesAllowedChildTypesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all company types
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesGet(apiVersion: string, options?: any) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DeliveryDestinationsApi - axios parameter creator
 * @export
 */
export const DeliveryDestinationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete a delivery destination
     * @param {string} deliveryDestinationId Delivery destination id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsDeliveryDestinationIdDelete: async (
      deliveryDestinationId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deliveryDestinationId' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsDeliveryDestinationIdDelete',
        'deliveryDestinationId',
        deliveryDestinationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsDeliveryDestinationIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/DeliveryDestinations/{deliveryDestinationId}`.replace(
          `{${'deliveryDestinationId'}}`,
          encodeURIComponent(String(deliveryDestinationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get delivery destination
     * @param {string} deliveryDestinationId Delivery destination id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsDeliveryDestinationIdGet: async (
      deliveryDestinationId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deliveryDestinationId' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsDeliveryDestinationIdGet',
        'deliveryDestinationId',
        deliveryDestinationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsDeliveryDestinationIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/DeliveryDestinations/{deliveryDestinationId}`.replace(
          `{${'deliveryDestinationId'}}`,
          encodeURIComponent(String(deliveryDestinationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a delivery destination
     * @param {string} deliveryDestinationId Delivery destination id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateDeliveryDestinationInputDTO} [updateDeliveryDestinationInputDTO] Delivery destination information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsDeliveryDestinationIdPut: async (
      deliveryDestinationId: string,
      apiVersion: string,
      updateDeliveryDestinationInputDTO?: UpdateDeliveryDestinationInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deliveryDestinationId' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsDeliveryDestinationIdPut',
        'deliveryDestinationId',
        deliveryDestinationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsDeliveryDestinationIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/DeliveryDestinations/{deliveryDestinationId}`.replace(
          `{${'deliveryDestinationId'}}`,
          encodeURIComponent(String(deliveryDestinationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeliveryDestinationInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a delivery destination
     * @param {string} apiVersion The requested API version
     * @param {CreateDeliveryDestinationInputDTO} [createDeliveryDestinationInputDTO] Delivery destination information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsPost: async (
      apiVersion: string,
      createDeliveryDestinationInputDTO?: CreateDeliveryDestinationInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/DeliveryDestinations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDeliveryDestinationInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search delivery destinations
     * @param {string} apiVersion The requested API version
     * @param {SearchDeliveryDestinationsQueryDTO} [searchDeliveryDestinationsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsSearchPost: async (
      apiVersion: string,
      searchDeliveryDestinationsQueryDTO?: SearchDeliveryDestinationsQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDeliveryDestinationsSearchPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/DeliveryDestinations/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchDeliveryDestinationsQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeliveryDestinationsApi - functional programming interface
 * @export
 */
export const DeliveryDestinationsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DeliveryDestinationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete a delivery destination
     * @param {string} deliveryDestinationId Delivery destination id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDeliveryDestinationsDeliveryDestinationIdDelete(
      deliveryDestinationId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDeliveryDestinationsDeliveryDestinationIdDelete(
          deliveryDestinationId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get delivery destination
     * @param {string} deliveryDestinationId Delivery destination id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDeliveryDestinationsDeliveryDestinationIdGet(
      deliveryDestinationId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeliveryDestinationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDeliveryDestinationsDeliveryDestinationIdGet(
          deliveryDestinationId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update a delivery destination
     * @param {string} deliveryDestinationId Delivery destination id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateDeliveryDestinationInputDTO} [updateDeliveryDestinationInputDTO] Delivery destination information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDeliveryDestinationsDeliveryDestinationIdPut(
      deliveryDestinationId: string,
      apiVersion: string,
      updateDeliveryDestinationInputDTO?: UpdateDeliveryDestinationInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeliveryDestinationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDeliveryDestinationsDeliveryDestinationIdPut(
          deliveryDestinationId,
          apiVersion,
          updateDeliveryDestinationInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a delivery destination
     * @param {string} apiVersion The requested API version
     * @param {CreateDeliveryDestinationInputDTO} [createDeliveryDestinationInputDTO] Delivery destination information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDeliveryDestinationsPost(
      apiVersion: string,
      createDeliveryDestinationInputDTO?: CreateDeliveryDestinationInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeliveryDestinationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDeliveryDestinationsPost(
          apiVersion,
          createDeliveryDestinationInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search delivery destinations
     * @param {string} apiVersion The requested API version
     * @param {SearchDeliveryDestinationsQueryDTO} [searchDeliveryDestinationsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDeliveryDestinationsSearchPost(
      apiVersion: string,
      searchDeliveryDestinationsQueryDTO?: SearchDeliveryDestinationsQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchDeliveryDestinationOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDeliveryDestinationsSearchPost(
          apiVersion,
          searchDeliveryDestinationsQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DeliveryDestinationsApi - factory interface
 * @export
 */
export const DeliveryDestinationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeliveryDestinationsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete a delivery destination
     * @param {string} deliveryDestinationId Delivery destination id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsDeliveryDestinationIdDelete(
      deliveryDestinationId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiDeliveryDestinationsDeliveryDestinationIdDelete(
          deliveryDestinationId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get delivery destination
     * @param {string} deliveryDestinationId Delivery destination id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsDeliveryDestinationIdGet(
      deliveryDestinationId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<DeliveryDestinationOutputDTO> {
      return localVarFp
        .apiDeliveryDestinationsDeliveryDestinationIdGet(
          deliveryDestinationId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update a delivery destination
     * @param {string} deliveryDestinationId Delivery destination id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateDeliveryDestinationInputDTO} [updateDeliveryDestinationInputDTO] Delivery destination information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsDeliveryDestinationIdPut(
      deliveryDestinationId: string,
      apiVersion: string,
      updateDeliveryDestinationInputDTO?: UpdateDeliveryDestinationInputDTO,
      options?: any,
    ): AxiosPromise<DeliveryDestinationOutputDTO> {
      return localVarFp
        .apiDeliveryDestinationsDeliveryDestinationIdPut(
          deliveryDestinationId,
          apiVersion,
          updateDeliveryDestinationInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a delivery destination
     * @param {string} apiVersion The requested API version
     * @param {CreateDeliveryDestinationInputDTO} [createDeliveryDestinationInputDTO] Delivery destination information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsPost(
      apiVersion: string,
      createDeliveryDestinationInputDTO?: CreateDeliveryDestinationInputDTO,
      options?: any,
    ): AxiosPromise<DeliveryDestinationOutputDTO> {
      return localVarFp
        .apiDeliveryDestinationsPost(
          apiVersion,
          createDeliveryDestinationInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search delivery destinations
     * @param {string} apiVersion The requested API version
     * @param {SearchDeliveryDestinationsQueryDTO} [searchDeliveryDestinationsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDeliveryDestinationsSearchPost(
      apiVersion: string,
      searchDeliveryDestinationsQueryDTO?: SearchDeliveryDestinationsQueryDTO,
      options?: any,
    ): AxiosPromise<SearchDeliveryDestinationOutputDTOPagedResponse> {
      return localVarFp
        .apiDeliveryDestinationsSearchPost(
          apiVersion,
          searchDeliveryDestinationsQueryDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DeliveryDestinationsApi - object-oriented interface
 * @export
 * @class DeliveryDestinationsApi
 * @extends {BaseAPI}
 */
export class DeliveryDestinationsApi extends BaseAPI {
  /**
   *
   * @summary Delete a delivery destination
   * @param {string} deliveryDestinationId Delivery destination id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryDestinationsApi
   */
  public apiDeliveryDestinationsDeliveryDestinationIdDelete(
    deliveryDestinationId: string,
    apiVersion: string,
    options?: any,
  ) {
    return DeliveryDestinationsApiFp(this.configuration)
      .apiDeliveryDestinationsDeliveryDestinationIdDelete(
        deliveryDestinationId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get delivery destination
   * @param {string} deliveryDestinationId Delivery destination id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryDestinationsApi
   */
  public apiDeliveryDestinationsDeliveryDestinationIdGet(
    deliveryDestinationId: string,
    apiVersion: string,
    options?: any,
  ) {
    return DeliveryDestinationsApiFp(this.configuration)
      .apiDeliveryDestinationsDeliveryDestinationIdGet(
        deliveryDestinationId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a delivery destination
   * @param {string} deliveryDestinationId Delivery destination id to update
   * @param {string} apiVersion The requested API version
   * @param {UpdateDeliveryDestinationInputDTO} [updateDeliveryDestinationInputDTO] Delivery destination information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryDestinationsApi
   */
  public apiDeliveryDestinationsDeliveryDestinationIdPut(
    deliveryDestinationId: string,
    apiVersion: string,
    updateDeliveryDestinationInputDTO?: UpdateDeliveryDestinationInputDTO,
    options?: any,
  ) {
    return DeliveryDestinationsApiFp(this.configuration)
      .apiDeliveryDestinationsDeliveryDestinationIdPut(
        deliveryDestinationId,
        apiVersion,
        updateDeliveryDestinationInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a delivery destination
   * @param {string} apiVersion The requested API version
   * @param {CreateDeliveryDestinationInputDTO} [createDeliveryDestinationInputDTO] Delivery destination information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryDestinationsApi
   */
  public apiDeliveryDestinationsPost(
    apiVersion: string,
    createDeliveryDestinationInputDTO?: CreateDeliveryDestinationInputDTO,
    options?: any,
  ) {
    return DeliveryDestinationsApiFp(this.configuration)
      .apiDeliveryDestinationsPost(
        apiVersion,
        createDeliveryDestinationInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search delivery destinations
   * @param {string} apiVersion The requested API version
   * @param {SearchDeliveryDestinationsQueryDTO} [searchDeliveryDestinationsQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryDestinationsApi
   */
  public apiDeliveryDestinationsSearchPost(
    apiVersion: string,
    searchDeliveryDestinationsQueryDTO?: SearchDeliveryDestinationsQueryDTO,
    options?: any,
  ) {
    return DeliveryDestinationsApiFp(this.configuration)
      .apiDeliveryDestinationsSearchPost(
        apiVersion,
        searchDeliveryDestinationsQueryDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DnaInfoServersApi - axios parameter creator
 * @export
 */
export const DnaInfoServersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Deletes a DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersDnaInfoServerIdDelete: async (
      dnaInfoServerId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dnaInfoServerId' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersDnaInfoServerIdDelete',
        'dnaInfoServerId',
        dnaInfoServerId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersDnaInfoServerIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/DnaInfoServers/{dnaInfoServerId}`.replace(
        `{${'dnaInfoServerId'}}`,
        encodeURIComponent(String(dnaInfoServerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersDnaInfoServerIdGet: async (
      dnaInfoServerId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dnaInfoServerId' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersDnaInfoServerIdGet',
        'dnaInfoServerId',
        dnaInfoServerId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersDnaInfoServerIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/DnaInfoServers/{dnaInfoServerId}`.replace(
        `{${'dnaInfoServerId'}}`,
        encodeURIComponent(String(dnaInfoServerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateDnaInfoServerInputDTO} [updateDnaInfoServerInputDTO] DNA Info server information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersDnaInfoServerIdPut: async (
      dnaInfoServerId: string,
      apiVersion: string,
      updateDnaInfoServerInputDTO?: UpdateDnaInfoServerInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dnaInfoServerId' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersDnaInfoServerIdPut',
        'dnaInfoServerId',
        dnaInfoServerId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersDnaInfoServerIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/DnaInfoServers/{dnaInfoServerId}`.replace(
        `{${'dnaInfoServerId'}}`,
        encodeURIComponent(String(dnaInfoServerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDnaInfoServerInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a DNA Info server
     * @param {string} apiVersion The requested API version
     * @param {CreateDnaInfoServerInputDTO} [createDnaInfoServerInputDTO] DNA Info server information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersPost: async (
      apiVersion: string,
      createDnaInfoServerInputDTO?: CreateDnaInfoServerInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiDnaInfoServersPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/DnaInfoServers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDnaInfoServerInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search DNA Info servers
     * @param {string} apiVersion The requested API version
     * @param {SearchDnaInfoServersQueryDTO} [searchDnaInfoServersQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersSearchPost: async (
      apiVersion: string,
      searchDnaInfoServersQueryDTO?: SearchDnaInfoServersQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiDnaInfoServersSearchPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/DnaInfoServers/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchDnaInfoServersQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DnaInfoServersApi - functional programming interface
 * @export
 */
export const DnaInfoServersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DnaInfoServersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Deletes a DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDnaInfoServersDnaInfoServerIdDelete(
      dnaInfoServerId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDnaInfoServersDnaInfoServerIdDelete(
          dnaInfoServerId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDnaInfoServersDnaInfoServerIdGet(
      dnaInfoServerId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DnaInfoServerOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDnaInfoServersDnaInfoServerIdGet(
          dnaInfoServerId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update a DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateDnaInfoServerInputDTO} [updateDnaInfoServerInputDTO] DNA Info server information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDnaInfoServersDnaInfoServerIdPut(
      dnaInfoServerId: string,
      apiVersion: string,
      updateDnaInfoServerInputDTO?: UpdateDnaInfoServerInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DnaInfoServerOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDnaInfoServersDnaInfoServerIdPut(
          dnaInfoServerId,
          apiVersion,
          updateDnaInfoServerInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a DNA Info server
     * @param {string} apiVersion The requested API version
     * @param {CreateDnaInfoServerInputDTO} [createDnaInfoServerInputDTO] DNA Info server information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDnaInfoServersPost(
      apiVersion: string,
      createDnaInfoServerInputDTO?: CreateDnaInfoServerInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DnaInfoServerOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDnaInfoServersPost(
          apiVersion,
          createDnaInfoServerInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search DNA Info servers
     * @param {string} apiVersion The requested API version
     * @param {SearchDnaInfoServersQueryDTO} [searchDnaInfoServersQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDnaInfoServersSearchPost(
      apiVersion: string,
      searchDnaInfoServersQueryDTO?: SearchDnaInfoServersQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDnaInfoServersSearchPost(
          apiVersion,
          searchDnaInfoServersQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DnaInfoServersApi - factory interface
 * @export
 */
export const DnaInfoServersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DnaInfoServersApiFp(configuration);
  return {
    /**
     *
     * @summary Deletes a DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersDnaInfoServerIdDelete(
      dnaInfoServerId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiDnaInfoServersDnaInfoServerIdDelete(
          dnaInfoServerId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersDnaInfoServerIdGet(
      dnaInfoServerId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<DnaInfoServerOutputDTO> {
      return localVarFp
        .apiDnaInfoServersDnaInfoServerIdGet(
          dnaInfoServerId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update a DNA Info server
     * @param {string} dnaInfoServerId DNA Info server id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateDnaInfoServerInputDTO} [updateDnaInfoServerInputDTO] DNA Info server information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersDnaInfoServerIdPut(
      dnaInfoServerId: string,
      apiVersion: string,
      updateDnaInfoServerInputDTO?: UpdateDnaInfoServerInputDTO,
      options?: any,
    ): AxiosPromise<DnaInfoServerOutputDTO> {
      return localVarFp
        .apiDnaInfoServersDnaInfoServerIdPut(
          dnaInfoServerId,
          apiVersion,
          updateDnaInfoServerInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a DNA Info server
     * @param {string} apiVersion The requested API version
     * @param {CreateDnaInfoServerInputDTO} [createDnaInfoServerInputDTO] DNA Info server information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersPost(
      apiVersion: string,
      createDnaInfoServerInputDTO?: CreateDnaInfoServerInputDTO,
      options?: any,
    ): AxiosPromise<DnaInfoServerOutputDTO> {
      return localVarFp
        .apiDnaInfoServersPost(apiVersion, createDnaInfoServerInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search DNA Info servers
     * @param {string} apiVersion The requested API version
     * @param {SearchDnaInfoServersQueryDTO} [searchDnaInfoServersQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDnaInfoServersSearchPost(
      apiVersion: string,
      searchDnaInfoServersQueryDTO?: SearchDnaInfoServersQueryDTO,
      options?: any,
    ): AxiosPromise<SearchDnaInfoServersDnaInfoServerOutputDTOPagedResponse> {
      return localVarFp
        .apiDnaInfoServersSearchPost(
          apiVersion,
          searchDnaInfoServersQueryDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DnaInfoServersApi - object-oriented interface
 * @export
 * @class DnaInfoServersApi
 * @extends {BaseAPI}
 */
export class DnaInfoServersApi extends BaseAPI {
  /**
   *
   * @summary Deletes a DNA Info server
   * @param {string} dnaInfoServerId DNA Info server id to delete
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DnaInfoServersApi
   */
  public apiDnaInfoServersDnaInfoServerIdDelete(
    dnaInfoServerId: string,
    apiVersion: string,
    options?: any,
  ) {
    return DnaInfoServersApiFp(this.configuration)
      .apiDnaInfoServersDnaInfoServerIdDelete(
        dnaInfoServerId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get DNA Info server
   * @param {string} dnaInfoServerId DNA Info server id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DnaInfoServersApi
   */
  public apiDnaInfoServersDnaInfoServerIdGet(
    dnaInfoServerId: string,
    apiVersion: string,
    options?: any,
  ) {
    return DnaInfoServersApiFp(this.configuration)
      .apiDnaInfoServersDnaInfoServerIdGet(dnaInfoServerId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a DNA Info server
   * @param {string} dnaInfoServerId DNA Info server id to update
   * @param {string} apiVersion The requested API version
   * @param {UpdateDnaInfoServerInputDTO} [updateDnaInfoServerInputDTO] DNA Info server information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DnaInfoServersApi
   */
  public apiDnaInfoServersDnaInfoServerIdPut(
    dnaInfoServerId: string,
    apiVersion: string,
    updateDnaInfoServerInputDTO?: UpdateDnaInfoServerInputDTO,
    options?: any,
  ) {
    return DnaInfoServersApiFp(this.configuration)
      .apiDnaInfoServersDnaInfoServerIdPut(
        dnaInfoServerId,
        apiVersion,
        updateDnaInfoServerInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a DNA Info server
   * @param {string} apiVersion The requested API version
   * @param {CreateDnaInfoServerInputDTO} [createDnaInfoServerInputDTO] DNA Info server information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DnaInfoServersApi
   */
  public apiDnaInfoServersPost(
    apiVersion: string,
    createDnaInfoServerInputDTO?: CreateDnaInfoServerInputDTO,
    options?: any,
  ) {
    return DnaInfoServersApiFp(this.configuration)
      .apiDnaInfoServersPost(apiVersion, createDnaInfoServerInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search DNA Info servers
   * @param {string} apiVersion The requested API version
   * @param {SearchDnaInfoServersQueryDTO} [searchDnaInfoServersQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DnaInfoServersApi
   */
  public apiDnaInfoServersSearchPost(
    apiVersion: string,
    searchDnaInfoServersQueryDTO?: SearchDnaInfoServersQueryDTO,
    options?: any,
  ) {
    return DnaInfoServersApiFp(this.configuration)
      .apiDnaInfoServersSearchPost(
        apiVersion,
        searchDnaInfoServersQueryDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EdgeGatewaysApi - axios parameter creator
 * @export
 */
export const EdgeGatewaysApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete an Edge Gateway
     * @param {string} edgeGatewayId Id of Edge Gateway to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEdgeGatewayIdDelete: async (
      edgeGatewayId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEdgeGatewayIdDelete',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEdgeGatewayIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/EdgeGateways/{edgeGatewayId}`.replace(
        `{${'edgeGatewayId'}}`,
        encodeURIComponent(String(edgeGatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get edge gateway
     * @param {string} edgeGatewayId Edge gateway id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEdgeGatewayIdGet: async (
      edgeGatewayId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEdgeGatewayIdGet',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEdgeGatewayIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/EdgeGateways/{edgeGatewayId}`.replace(
        `{${'edgeGatewayId'}}`,
        encodeURIComponent(String(edgeGatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update an Edge Gateway
     * @param {string} edgeGatewayId Edge Gateway Id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateEdgeGatewayInputDTO} [updateEdgeGatewayInputDTO] Edge Gateway information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEdgeGatewayIdPut: async (
      edgeGatewayId: string,
      apiVersion: string,
      updateEdgeGatewayInputDTO?: UpdateEdgeGatewayInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEdgeGatewayIdPut',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEdgeGatewayIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/EdgeGateways/{edgeGatewayId}`.replace(
        `{${'edgeGatewayId'}}`,
        encodeURIComponent(String(edgeGatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateEdgeGatewayInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Try to enable moxa device telemetry collection on device
     * @param {string} edgeGatewayId Edge Gateway Id to enable telemetry on
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost: async (
      edgeGatewayId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/EdgeGateways/enabledevicetelemetry/{edgeGatewayId}`.replace(
          `{${'edgeGatewayId'}}`,
          encodeURIComponent(String(edgeGatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get module logs
     * @param {string} edgeGatewayId Edge Gateway Id to get module logs from
     * @param {string} apiVersion The requested API version
     * @param {EdgeAgentGetLogsInputDTO} [edgeAgentGetLogsInputDTO] GetModuleLog parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysModulelogsEdgeGatewayIdPost: async (
      edgeGatewayId: string,
      apiVersion: string,
      edgeAgentGetLogsInputDTO?: EdgeAgentGetLogsInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysModulelogsEdgeGatewayIdPost',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysModulelogsEdgeGatewayIdPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/EdgeGateways/modulelogs/{edgeGatewayId}`.replace(
          `{${'edgeGatewayId'}}`,
          encodeURIComponent(String(edgeGatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        edgeAgentGetLogsInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create an Edge Gateway
     * @param {string} apiVersion The requested API version
     * @param {CreateEdgeGatewayInputDTO} [createEdgeGatewayInputDTO] Edge Gateway information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysPost: async (
      apiVersion: string,
      createEdgeGatewayInputDTO?: CreateEdgeGatewayInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiEdgeGatewaysPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/EdgeGateways`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEdgeGatewayInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search edge gateways
     * @param {string} apiVersion The requested API version
     * @param {SearchEdgeGatewaysQueryDTO} [searchEdgeGatewaysQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysSearchPost: async (
      apiVersion: string,
      searchEdgeGatewaysQueryDTO?: SearchEdgeGatewaysQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiEdgeGatewaysSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/EdgeGateways/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchEdgeGatewaysQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Try to upload current opc ua configuration from db to device
     * @param {string} edgeGatewayId Edge Gateway Id to upload configuration to
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost: async (
      edgeGatewayId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/EdgeGateways/uploadopcuaconfiguration/{edgeGatewayId}`.replace(
          `{${'edgeGatewayId'}}`,
          encodeURIComponent(String(edgeGatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EdgeGatewaysApi - functional programming interface
 * @export
 */
export const EdgeGatewaysApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EdgeGatewaysApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete an Edge Gateway
     * @param {string} edgeGatewayId Id of Edge Gateway to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysEdgeGatewayIdDelete(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysEdgeGatewayIdDelete(
          edgeGatewayId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get edge gateway
     * @param {string} edgeGatewayId Edge gateway id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysEdgeGatewayIdGet(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EdgeGatewayOutputDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysEdgeGatewayIdGet(
          edgeGatewayId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update an Edge Gateway
     * @param {string} edgeGatewayId Edge Gateway Id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateEdgeGatewayInputDTO} [updateEdgeGatewayInputDTO] Edge Gateway information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysEdgeGatewayIdPut(
      edgeGatewayId: string,
      apiVersion: string,
      updateEdgeGatewayInputDTO?: UpdateEdgeGatewayInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EdgeGatewayOutputDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysEdgeGatewayIdPut(
          edgeGatewayId,
          apiVersion,
          updateEdgeGatewayInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Try to enable moxa device telemetry collection on device
     * @param {string} edgeGatewayId Edge Gateway Id to enable telemetry on
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost(
          edgeGatewayId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get module logs
     * @param {string} edgeGatewayId Edge Gateway Id to get module logs from
     * @param {string} apiVersion The requested API version
     * @param {EdgeAgentGetLogsInputDTO} [edgeAgentGetLogsInputDTO] GetModuleLog parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysModulelogsEdgeGatewayIdPost(
      edgeGatewayId: string,
      apiVersion: string,
      edgeAgentGetLogsInputDTO?: EdgeAgentGetLogsInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EdgeAgentGetLogsOutputStringDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysModulelogsEdgeGatewayIdPost(
          edgeGatewayId,
          apiVersion,
          edgeAgentGetLogsInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create an Edge Gateway
     * @param {string} apiVersion The requested API version
     * @param {CreateEdgeGatewayInputDTO} [createEdgeGatewayInputDTO] Edge Gateway information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysPost(
      apiVersion: string,
      createEdgeGatewayInputDTO?: CreateEdgeGatewayInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EdgeGatewayOutputDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysPost(
          apiVersion,
          createEdgeGatewayInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search edge gateways
     * @param {string} apiVersion The requested API version
     * @param {SearchEdgeGatewaysQueryDTO} [searchEdgeGatewaysQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysSearchPost(
      apiVersion: string,
      searchEdgeGatewaysQueryDTO?: SearchEdgeGatewaysQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysSearchPost(
          apiVersion,
          searchEdgeGatewaysQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Try to upload current opc ua configuration from db to device
     * @param {string} edgeGatewayId Edge Gateway Id to upload configuration to
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost(
          edgeGatewayId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EdgeGatewaysApi - factory interface
 * @export
 */
export const EdgeGatewaysApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EdgeGatewaysApiFp(configuration);
  return {
    /**
     *
     * @summary Delete an Edge Gateway
     * @param {string} edgeGatewayId Id of Edge Gateway to delete
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEdgeGatewayIdDelete(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiEdgeGatewaysEdgeGatewayIdDelete(edgeGatewayId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get edge gateway
     * @param {string} edgeGatewayId Edge gateway id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEdgeGatewayIdGet(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<EdgeGatewayOutputDto> {
      return localVarFp
        .apiEdgeGatewaysEdgeGatewayIdGet(edgeGatewayId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update an Edge Gateway
     * @param {string} edgeGatewayId Edge Gateway Id to update
     * @param {string} apiVersion The requested API version
     * @param {UpdateEdgeGatewayInputDTO} [updateEdgeGatewayInputDTO] Edge Gateway information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEdgeGatewayIdPut(
      edgeGatewayId: string,
      apiVersion: string,
      updateEdgeGatewayInputDTO?: UpdateEdgeGatewayInputDTO,
      options?: any,
    ): AxiosPromise<EdgeGatewayOutputDto> {
      return localVarFp
        .apiEdgeGatewaysEdgeGatewayIdPut(
          edgeGatewayId,
          apiVersion,
          updateEdgeGatewayInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Try to enable moxa device telemetry collection on device
     * @param {string} edgeGatewayId Edge Gateway Id to enable telemetry on
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost(
          edgeGatewayId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get module logs
     * @param {string} edgeGatewayId Edge Gateway Id to get module logs from
     * @param {string} apiVersion The requested API version
     * @param {EdgeAgentGetLogsInputDTO} [edgeAgentGetLogsInputDTO] GetModuleLog parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysModulelogsEdgeGatewayIdPost(
      edgeGatewayId: string,
      apiVersion: string,
      edgeAgentGetLogsInputDTO?: EdgeAgentGetLogsInputDTO,
      options?: any,
    ): AxiosPromise<Array<EdgeAgentGetLogsOutputStringDTO>> {
      return localVarFp
        .apiEdgeGatewaysModulelogsEdgeGatewayIdPost(
          edgeGatewayId,
          apiVersion,
          edgeAgentGetLogsInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create an Edge Gateway
     * @param {string} apiVersion The requested API version
     * @param {CreateEdgeGatewayInputDTO} [createEdgeGatewayInputDTO] Edge Gateway information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysPost(
      apiVersion: string,
      createEdgeGatewayInputDTO?: CreateEdgeGatewayInputDTO,
      options?: any,
    ): AxiosPromise<EdgeGatewayOutputDto> {
      return localVarFp
        .apiEdgeGatewaysPost(apiVersion, createEdgeGatewayInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search edge gateways
     * @param {string} apiVersion The requested API version
     * @param {SearchEdgeGatewaysQueryDTO} [searchEdgeGatewaysQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysSearchPost(
      apiVersion: string,
      searchEdgeGatewaysQueryDTO?: SearchEdgeGatewaysQueryDTO,
      options?: any,
    ): AxiosPromise<SearchEdgeGatewaysEdgeGatewayOutputDTOPagedResponse> {
      return localVarFp
        .apiEdgeGatewaysSearchPost(
          apiVersion,
          searchEdgeGatewaysQueryDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Try to upload current opc ua configuration from db to device
     * @param {string} edgeGatewayId Edge Gateway Id to upload configuration to
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost(
      edgeGatewayId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost(
          edgeGatewayId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * EdgeGatewaysApi - object-oriented interface
 * @export
 * @class EdgeGatewaysApi
 * @extends {BaseAPI}
 */
export class EdgeGatewaysApi extends BaseAPI {
  /**
   *
   * @summary Delete an Edge Gateway
   * @param {string} edgeGatewayId Id of Edge Gateway to delete
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysEdgeGatewayIdDelete(
    edgeGatewayId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysEdgeGatewayIdDelete(edgeGatewayId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get edge gateway
   * @param {string} edgeGatewayId Edge gateway id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysEdgeGatewayIdGet(
    edgeGatewayId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysEdgeGatewayIdGet(edgeGatewayId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update an Edge Gateway
   * @param {string} edgeGatewayId Edge Gateway Id to update
   * @param {string} apiVersion The requested API version
   * @param {UpdateEdgeGatewayInputDTO} [updateEdgeGatewayInputDTO] Edge Gateway information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysEdgeGatewayIdPut(
    edgeGatewayId: string,
    apiVersion: string,
    updateEdgeGatewayInputDTO?: UpdateEdgeGatewayInputDTO,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysEdgeGatewayIdPut(
        edgeGatewayId,
        apiVersion,
        updateEdgeGatewayInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Try to enable moxa device telemetry collection on device
   * @param {string} edgeGatewayId Edge Gateway Id to enable telemetry on
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost(
    edgeGatewayId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysEnabledevicetelemetryEdgeGatewayIdPost(
        edgeGatewayId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get module logs
   * @param {string} edgeGatewayId Edge Gateway Id to get module logs from
   * @param {string} apiVersion The requested API version
   * @param {EdgeAgentGetLogsInputDTO} [edgeAgentGetLogsInputDTO] GetModuleLog parameters
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysModulelogsEdgeGatewayIdPost(
    edgeGatewayId: string,
    apiVersion: string,
    edgeAgentGetLogsInputDTO?: EdgeAgentGetLogsInputDTO,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysModulelogsEdgeGatewayIdPost(
        edgeGatewayId,
        apiVersion,
        edgeAgentGetLogsInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create an Edge Gateway
   * @param {string} apiVersion The requested API version
   * @param {CreateEdgeGatewayInputDTO} [createEdgeGatewayInputDTO] Edge Gateway information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysPost(
    apiVersion: string,
    createEdgeGatewayInputDTO?: CreateEdgeGatewayInputDTO,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysPost(apiVersion, createEdgeGatewayInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search edge gateways
   * @param {string} apiVersion The requested API version
   * @param {SearchEdgeGatewaysQueryDTO} [searchEdgeGatewaysQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysSearchPost(
    apiVersion: string,
    searchEdgeGatewaysQueryDTO?: SearchEdgeGatewaysQueryDTO,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysSearchPost(
        apiVersion,
        searchEdgeGatewaysQueryDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Try to upload current opc ua configuration from db to device
   * @param {string} edgeGatewayId Edge Gateway Id to upload configuration to
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EdgeGatewaysApi
   */
  public apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost(
    edgeGatewayId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EdgeGatewaysApiFp(this.configuration)
      .apiEdgeGatewaysUploadopcuaconfigurationEdgeGatewayIdPost(
        edgeGatewayId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LookupApi - axios parameter creator
 * @export
 */
export const LookupApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all available base products
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupBaseProductsGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiLookupBaseProductsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Lookup/baseProducts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all assets for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdAssetsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdAssetsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdAssetsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Lookup/companies/{companyId}/assets`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available child sites for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdChildSitesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdChildSitesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdChildSitesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Lookup/companies/{companyId}/childSites`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get customers in the given provider  company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdCustomersGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdCustomersGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdCustomersGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Lookup/companies/{companyId}/customers`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available equipments for a specific company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdEquipmentsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdEquipmentsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdEquipmentsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Lookup/companies/{companyId}/equipments`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get logistics operators in the given provider  company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdLogisticsOperatorsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdLogisticsOperatorsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdLogisticsOperatorsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Lookup/companies/{companyId}/logisticsOperators`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get provider\'s allowed products for a logistics operator
     * @param {string} companyId
     * @param {string} logisticsOperatorId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet:
      async (
        companyId: string,
        logisticsOperatorId: string,
        apiVersion: string,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'companyId' is not null or undefined
        assertParamExists(
          'apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet',
          'companyId',
          companyId,
        );
        // verify required parameter 'logisticsOperatorId' is not null or undefined
        assertParamExists(
          'apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet',
          'logisticsOperatorId',
          logisticsOperatorId,
        );
        // verify required parameter 'apiVersion' is not null or undefined
        assertParamExists(
          'apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet',
          'apiVersion',
          apiVersion,
        );
        const localVarPath =
          `/api/Lookup/companies/{companyId}/logisticsOperators/{logisticsOperatorId}/products`
            .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)))
            .replace(
              `{${'logisticsOperatorId'}}`,
              encodeURIComponent(String(logisticsOperatorId)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        // oauth required
        await setOAuthToObject(
          localVarHeaderParameter,
          'Bearer',
          [],
          configuration,
        );

        if (apiVersion !== undefined) {
          localVarQueryParameter['api-version'] = apiVersion;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get all available products for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdProductsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdProductsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdProductsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Lookup/companies/{companyId}/products`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get companies (customers and logistics operators)  that have incomplete reservations using the given product  that are on a site operated by the given company.
     * @param {string} companyId
     * @param {string} productId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet:
      async (
        companyId: string,
        productId: string,
        apiVersion: string,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'companyId' is not null or undefined
        assertParamExists(
          'apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet',
          'companyId',
          companyId,
        );
        // verify required parameter 'productId' is not null or undefined
        assertParamExists(
          'apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet',
          'productId',
          productId,
        );
        // verify required parameter 'apiVersion' is not null or undefined
        assertParamExists(
          'apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet',
          'apiVersion',
          apiVersion,
        );
        const localVarPath =
          `/api/Lookup/companies/{companyId}/products/{productId}/companiesWithIncompleteReservations`
            .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)))
            .replace(`{${'productId'}}`, encodeURIComponent(String(productId)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        // oauth required
        await setOAuthToObject(
          localVarHeaderParameter,
          'Bearer',
          [],
          configuration,
        );

        if (apiVersion !== undefined) {
          localVarQueryParameter['api-version'] = apiVersion;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get providers in the given company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdProvidersGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdProvidersGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdProvidersGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Lookup/companies/{companyId}/providers`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available scopes for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdScopesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdScopesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdScopesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Lookup/companies/{companyId}/scopes`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all sites for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdSitesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdSitesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdSitesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Lookup/companies/{companyId}/sites`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all site types of the company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdSitestypesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdSitestypesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupCompaniesCompanyIdSitestypesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Lookup/companies/{companyId}/sitestypes`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available measurement units
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupMeasurementUnitsGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiLookupMeasurementUnitsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Lookup/measurementUnits`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available parameters
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupParametersGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiLookupParametersGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Lookup/parameters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LookupApi - functional programming interface
 * @export
 */
export const LookupApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LookupApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all available base products
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupBaseProductsGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupBaseProductOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupBaseProductsGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all assets for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdAssetsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupAssetOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdAssetsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available child sites for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdChildSitesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupChildSiteOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdChildSitesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get customers in the given provider  company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdCustomersGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupCustomerOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdCustomersGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get available equipments for a specific company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdEquipmentsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupEquipmentOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdEquipmentsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get logistics operators in the given provider  company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdLogisticsOperatorsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupLogisticsOperatorOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdLogisticsOperatorsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get provider\'s allowed products for a logistics operator
     * @param {string} companyId
     * @param {string} logisticsOperatorId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet(
      companyId: string,
      logisticsOperatorId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<
        Array<LookupProductsAllowedForLogisticsOperatorOutputDTO>
      >
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet(
          companyId,
          logisticsOperatorId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available products for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdProductsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupProductOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdProductsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get companies (customers and logistics operators)  that have incomplete reservations using the given product  that are on a site operated by the given company.
     * @param {string} companyId
     * @param {string} productId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet(
      companyId: string,
      productId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LookupProductCompaniesWithIncompleteReservationsOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet(
          companyId,
          productId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get providers in the given company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdProvidersGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupProvidersOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdProvidersGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available scopes for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdScopesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupScopeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdScopesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all sites for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdSitesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupSitesOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdSitesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all site types of the company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupCompaniesCompanyIdSitestypesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupSiteTypesOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupCompaniesCompanyIdSitestypesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available measurement units
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupMeasurementUnitsGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupMeasurementUnitOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupMeasurementUnitsGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available parameters
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLookupParametersGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LookupParameterOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiLookupParametersGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * LookupApi - factory interface
 * @export
 */
export const LookupApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LookupApiFp(configuration);
  return {
    /**
     *
     * @summary Get all available base products
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupBaseProductsGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupBaseProductOutputDTO>> {
      return localVarFp
        .apiLookupBaseProductsGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all assets for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdAssetsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupAssetOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdAssetsGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available child sites for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdChildSitesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupChildSiteOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdChildSitesGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get customers in the given provider  company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdCustomersGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupCustomerOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdCustomersGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available equipments for a specific company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdEquipmentsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupEquipmentOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdEquipmentsGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get logistics operators in the given provider  company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdLogisticsOperatorsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupLogisticsOperatorOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdLogisticsOperatorsGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get provider\'s allowed products for a logistics operator
     * @param {string} companyId
     * @param {string} logisticsOperatorId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet(
      companyId: string,
      logisticsOperatorId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupProductsAllowedForLogisticsOperatorOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet(
          companyId,
          logisticsOperatorId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available products for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdProductsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupProductOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdProductsGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get companies (customers and logistics operators)  that have incomplete reservations using the given product  that are on a site operated by the given company.
     * @param {string} companyId
     * @param {string} productId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet(
      companyId: string,
      productId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<LookupProductCompaniesWithIncompleteReservationsOutputDTO> {
      return localVarFp
        .apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet(
          companyId,
          productId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get providers in the given company\'s hierarchy
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdProvidersGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupProvidersOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdProvidersGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available scopes for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdScopesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupScopeOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdScopesGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all sites for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdSitesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupSitesOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdSitesGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all site types of the company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupCompaniesCompanyIdSitestypesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupSiteTypesOutputDTO>> {
      return localVarFp
        .apiLookupCompaniesCompanyIdSitestypesGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available measurement units
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupMeasurementUnitsGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupMeasurementUnitOutputDTO>> {
      return localVarFp
        .apiLookupMeasurementUnitsGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available parameters
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLookupParametersGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LookupParameterOutputDTO>> {
      return localVarFp
        .apiLookupParametersGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * LookupApi - object-oriented interface
 * @export
 * @class LookupApi
 * @extends {BaseAPI}
 */
export class LookupApi extends BaseAPI {
  /**
   *
   * @summary Get all available base products
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupBaseProductsGet(apiVersion: string, options?: any) {
    return LookupApiFp(this.configuration)
      .apiLookupBaseProductsGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all assets for a company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdAssetsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdAssetsGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available child sites for a company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdChildSitesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdChildSitesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get customers in the given provider  company\'s hierarchy
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdCustomersGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdCustomersGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available equipments for a specific company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdEquipmentsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdEquipmentsGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get logistics operators in the given provider  company\'s hierarchy
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdLogisticsOperatorsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdLogisticsOperatorsGet(
        companyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get provider\'s allowed products for a logistics operator
   * @param {string} companyId
   * @param {string} logisticsOperatorId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet(
    companyId: string,
    logisticsOperatorId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdLogisticsOperatorsLogisticsOperatorIdProductsGet(
        companyId,
        logisticsOperatorId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available products for a company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdProductsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdProductsGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get companies (customers and logistics operators)  that have incomplete reservations using the given product  that are on a site operated by the given company.
   * @param {string} companyId
   * @param {string} productId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet(
    companyId: string,
    productId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdProductsProductIdCompaniesWithIncompleteReservationsGet(
        companyId,
        productId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get providers in the given company\'s hierarchy
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdProvidersGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdProvidersGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available scopes for a company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdScopesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdScopesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all sites for a company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdSitesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdSitesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all site types of the company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupCompaniesCompanyIdSitestypesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return LookupApiFp(this.configuration)
      .apiLookupCompaniesCompanyIdSitestypesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available measurement units
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupMeasurementUnitsGet(apiVersion: string, options?: any) {
    return LookupApiFp(this.configuration)
      .apiLookupMeasurementUnitsGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available parameters
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LookupApi
   */
  public apiLookupParametersGet(apiVersion: string, options?: any) {
    return LookupApiFp(this.configuration)
      .apiLookupParametersGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all products provided by given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsGet: async (
      apiVersion: string,
      companyId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiProductsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new product
     * @param {string} apiVersion The requested API version
     * @param {CreateProductInputDTO} [createProductInputDTO] Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsPost: async (
      apiVersion: string,
      createProductInputDTO?: CreateProductInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiProductsPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a product
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsProductIdDelete: async (
      productId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      assertParamExists('apiProductsProductIdDelete', 'productId', productId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiProductsProductIdDelete', 'apiVersion', apiVersion);
      const localVarPath = `/api/Products/{productId}`.replace(
        `{${'productId'}}`,
        encodeURIComponent(String(productId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product by id
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsProductIdGet: async (
      productId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      assertParamExists('apiProductsProductIdGet', 'productId', productId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiProductsProductIdGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Products/{productId}`.replace(
        `{${'productId'}}`,
        encodeURIComponent(String(productId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a product
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {UpdateProductInputDTO} [updateProductInputDTO] Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsProductIdPut: async (
      productId: string,
      apiVersion: string,
      updateProductInputDTO?: UpdateProductInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      assertParamExists('apiProductsProductIdPut', 'productId', productId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiProductsProductIdPut', 'apiVersion', apiVersion);
      const localVarPath = `/api/Products/{productId}`.replace(
        `{${'productId'}}`,
        encodeURIComponent(String(productId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProductInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search products
     * @param {string} apiVersion The requested API version
     * @param {SearchProductsQueryDTO} [searchProductsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsSearchPost: async (
      apiVersion: string,
      searchProductsQueryDTO?: SearchProductsQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiProductsSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Products/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchProductsQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all products provided by given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProductsGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllProductsProductOutputDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsGet(
        apiVersion,
        companyId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a new product
     * @param {string} apiVersion The requested API version
     * @param {CreateProductInputDTO} [createProductInputDTO] Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProductsPost(
      apiVersion: string,
      createProductInputDTO?: CreateProductInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProductOutputDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsPost(
        apiVersion,
        createProductInputDTO,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete a product
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProductsProductIdDelete(
      productId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiProductsProductIdDelete(
          productId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get product by id
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProductsProductIdGet(
      productId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProductOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiProductsProductIdGet(
          productId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update a product
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {UpdateProductInputDTO} [updateProductInputDTO] Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProductsProductIdPut(
      productId: string,
      apiVersion: string,
      updateProductInputDTO?: UpdateProductInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProductOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiProductsProductIdPut(
          productId,
          apiVersion,
          updateProductInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search products
     * @param {string} apiVersion The requested API version
     * @param {SearchProductsQueryDTO} [searchProductsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProductsSearchPost(
      apiVersion: string,
      searchProductsQueryDTO?: SearchProductsQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchProductsProductOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiProductsSearchPost(
          apiVersion,
          searchProductsQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProductsApiFp(configuration);
  return {
    /**
     *
     * @summary Get all products provided by given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): AxiosPromise<Array<GetAllProductsProductOutputDTO>> {
      return localVarFp
        .apiProductsGet(apiVersion, companyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new product
     * @param {string} apiVersion The requested API version
     * @param {CreateProductInputDTO} [createProductInputDTO] Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsPost(
      apiVersion: string,
      createProductInputDTO?: CreateProductInputDTO,
      options?: any,
    ): AxiosPromise<ProductOutputDTO> {
      return localVarFp
        .apiProductsPost(apiVersion, createProductInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a product
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsProductIdDelete(
      productId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiProductsProductIdDelete(productId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get product by id
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsProductIdGet(
      productId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<ProductOutputDTO> {
      return localVarFp
        .apiProductsProductIdGet(productId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update a product
     * @param {string} productId Product id
     * @param {string} apiVersion The requested API version
     * @param {UpdateProductInputDTO} [updateProductInputDTO] Product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsProductIdPut(
      productId: string,
      apiVersion: string,
      updateProductInputDTO?: UpdateProductInputDTO,
      options?: any,
    ): AxiosPromise<ProductOutputDTO> {
      return localVarFp
        .apiProductsProductIdPut(
          productId,
          apiVersion,
          updateProductInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search products
     * @param {string} apiVersion The requested API version
     * @param {SearchProductsQueryDTO} [searchProductsQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProductsSearchPost(
      apiVersion: string,
      searchProductsQueryDTO?: SearchProductsQueryDTO,
      options?: any,
    ): AxiosPromise<SearchProductsProductOutputDTOPagedResponse> {
      return localVarFp
        .apiProductsSearchPost(apiVersion, searchProductsQueryDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
  /**
   *
   * @summary Get all products provided by given company
   * @param {string} apiVersion The requested API version
   * @param {string} [companyId] Provider company id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public apiProductsGet(apiVersion: string, companyId?: string, options?: any) {
    return ProductsApiFp(this.configuration)
      .apiProductsGet(apiVersion, companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new product
   * @param {string} apiVersion The requested API version
   * @param {CreateProductInputDTO} [createProductInputDTO] Product
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public apiProductsPost(
    apiVersion: string,
    createProductInputDTO?: CreateProductInputDTO,
    options?: any,
  ) {
    return ProductsApiFp(this.configuration)
      .apiProductsPost(apiVersion, createProductInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a product
   * @param {string} productId Product id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public apiProductsProductIdDelete(
    productId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ProductsApiFp(this.configuration)
      .apiProductsProductIdDelete(productId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get product by id
   * @param {string} productId Product id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public apiProductsProductIdGet(
    productId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ProductsApiFp(this.configuration)
      .apiProductsProductIdGet(productId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a product
   * @param {string} productId Product id
   * @param {string} apiVersion The requested API version
   * @param {UpdateProductInputDTO} [updateProductInputDTO] Product
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public apiProductsProductIdPut(
    productId: string,
    apiVersion: string,
    updateProductInputDTO?: UpdateProductInputDTO,
    options?: any,
  ) {
    return ProductsApiFp(this.configuration)
      .apiProductsProductIdPut(
        productId,
        apiVersion,
        updateProductInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search products
   * @param {string} apiVersion The requested API version
   * @param {SearchProductsQueryDTO} [searchProductsQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public apiProductsSearchPost(
    apiVersion: string,
    searchProductsQueryDTO?: SearchProductsQueryDTO,
    options?: any,
  ) {
    return ProductsApiFp(this.configuration)
      .apiProductsSearchPost(apiVersion, searchProductsQueryDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ScopesApi - axios parameter creator
 * @export
 */
export const ScopesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all scopes provided by given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesGet: async (
      apiVersion: string,
      companyId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiScopesGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Scopes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create scope
     * @param {string} apiVersion The requested API version
     * @param {CreateScopeCommandDTO} [createScopeCommandDTO] Scope containing information for the new scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesPost: async (
      apiVersion: string,
      createScopeCommandDTO?: CreateScopeCommandDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiScopesPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Scopes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createScopeCommandDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a scope
     * @param {string} scopeId Scope id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesScopeIdDelete: async (
      scopeId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scopeId' is not null or undefined
      assertParamExists('apiScopesScopeIdDelete', 'scopeId', scopeId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiScopesScopeIdDelete', 'apiVersion', apiVersion);
      const localVarPath = `/api/Scopes/{scopeId}`.replace(
        `{${'scopeId'}}`,
        encodeURIComponent(String(scopeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get scope by id
     * @param {string} scopeId Id of the scope
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesScopeIdGet: async (
      scopeId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scopeId' is not null or undefined
      assertParamExists('apiScopesScopeIdGet', 'scopeId', scopeId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiScopesScopeIdGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Scopes/{scopeId}`.replace(
        `{${'scopeId'}}`,
        encodeURIComponent(String(scopeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a scope
     * @param {string} scopeId Scope id
     * @param {string} apiVersion The requested API version
     * @param {UpdateScopeCommandDTO} [updateScopeCommandDTO] Scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesScopeIdPut: async (
      scopeId: string,
      apiVersion: string,
      updateScopeCommandDTO?: UpdateScopeCommandDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scopeId' is not null or undefined
      assertParamExists('apiScopesScopeIdPut', 'scopeId', scopeId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiScopesScopeIdPut', 'apiVersion', apiVersion);
      const localVarPath = `/api/Scopes/{scopeId}`.replace(
        `{${'scopeId'}}`,
        encodeURIComponent(String(scopeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateScopeCommandDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search scopes
     * @param {string} apiVersion The requested API version
     * @param {SearchScopesQueryDTO} [searchScopesQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesSearchPost: async (
      apiVersion: string,
      searchScopesQueryDTO?: SearchScopesQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiScopesSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Scopes/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchScopesQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ScopesApi - functional programming interface
 * @export
 */
export const ScopesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ScopesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all scopes provided by given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiScopesGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllScopesOutputDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiScopesGet(
        apiVersion,
        companyId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create scope
     * @param {string} apiVersion The requested API version
     * @param {CreateScopeCommandDTO} [createScopeCommandDTO] Scope containing information for the new scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiScopesPost(
      apiVersion: string,
      createScopeCommandDTO?: CreateScopeCommandDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateScopeOutputDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiScopesPost(
        apiVersion,
        createScopeCommandDTO,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete a scope
     * @param {string} scopeId Scope id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiScopesScopeIdDelete(
      scopeId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiScopesScopeIdDelete(
          scopeId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get scope by id
     * @param {string} scopeId Id of the scope
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiScopesScopeIdGet(
      scopeId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetScopeByIdOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiScopesScopeIdGet(
          scopeId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update a scope
     * @param {string} scopeId Scope id
     * @param {string} apiVersion The requested API version
     * @param {UpdateScopeCommandDTO} [updateScopeCommandDTO] Scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiScopesScopeIdPut(
      scopeId: string,
      apiVersion: string,
      updateScopeCommandDTO?: UpdateScopeCommandDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateScopeOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiScopesScopeIdPut(
          scopeId,
          apiVersion,
          updateScopeCommandDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search scopes
     * @param {string} apiVersion The requested API version
     * @param {SearchScopesQueryDTO} [searchScopesQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiScopesSearchPost(
      apiVersion: string,
      searchScopesQueryDTO?: SearchScopesQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchScopesOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiScopesSearchPost(
          apiVersion,
          searchScopesQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ScopesApi - factory interface
 * @export
 */
export const ScopesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ScopesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all scopes provided by given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): AxiosPromise<Array<GetAllScopesOutputDTO>> {
      return localVarFp
        .apiScopesGet(apiVersion, companyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create scope
     * @param {string} apiVersion The requested API version
     * @param {CreateScopeCommandDTO} [createScopeCommandDTO] Scope containing information for the new scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesPost(
      apiVersion: string,
      createScopeCommandDTO?: CreateScopeCommandDTO,
      options?: any,
    ): AxiosPromise<CreateScopeOutputDTO> {
      return localVarFp
        .apiScopesPost(apiVersion, createScopeCommandDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a scope
     * @param {string} scopeId Scope id
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesScopeIdDelete(
      scopeId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiScopesScopeIdDelete(scopeId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get scope by id
     * @param {string} scopeId Id of the scope
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesScopeIdGet(
      scopeId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<GetScopeByIdOutputDTO> {
      return localVarFp
        .apiScopesScopeIdGet(scopeId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update a scope
     * @param {string} scopeId Scope id
     * @param {string} apiVersion The requested API version
     * @param {UpdateScopeCommandDTO} [updateScopeCommandDTO] Scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesScopeIdPut(
      scopeId: string,
      apiVersion: string,
      updateScopeCommandDTO?: UpdateScopeCommandDTO,
      options?: any,
    ): AxiosPromise<UpdateScopeOutputDTO> {
      return localVarFp
        .apiScopesScopeIdPut(
          scopeId,
          apiVersion,
          updateScopeCommandDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search scopes
     * @param {string} apiVersion The requested API version
     * @param {SearchScopesQueryDTO} [searchScopesQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiScopesSearchPost(
      apiVersion: string,
      searchScopesQueryDTO?: SearchScopesQueryDTO,
      options?: any,
    ): AxiosPromise<SearchScopesOutputDTOPagedResponse> {
      return localVarFp
        .apiScopesSearchPost(apiVersion, searchScopesQueryDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ScopesApi - object-oriented interface
 * @export
 * @class ScopesApi
 * @extends {BaseAPI}
 */
export class ScopesApi extends BaseAPI {
  /**
   *
   * @summary Get all scopes provided by given company
   * @param {string} apiVersion The requested API version
   * @param {string} [companyId] Provider company id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScopesApi
   */
  public apiScopesGet(apiVersion: string, companyId?: string, options?: any) {
    return ScopesApiFp(this.configuration)
      .apiScopesGet(apiVersion, companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create scope
   * @param {string} apiVersion The requested API version
   * @param {CreateScopeCommandDTO} [createScopeCommandDTO] Scope containing information for the new scope
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScopesApi
   */
  public apiScopesPost(
    apiVersion: string,
    createScopeCommandDTO?: CreateScopeCommandDTO,
    options?: any,
  ) {
    return ScopesApiFp(this.configuration)
      .apiScopesPost(apiVersion, createScopeCommandDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a scope
   * @param {string} scopeId Scope id
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScopesApi
   */
  public apiScopesScopeIdDelete(
    scopeId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ScopesApiFp(this.configuration)
      .apiScopesScopeIdDelete(scopeId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get scope by id
   * @param {string} scopeId Id of the scope
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScopesApi
   */
  public apiScopesScopeIdGet(
    scopeId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ScopesApiFp(this.configuration)
      .apiScopesScopeIdGet(scopeId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a scope
   * @param {string} scopeId Scope id
   * @param {string} apiVersion The requested API version
   * @param {UpdateScopeCommandDTO} [updateScopeCommandDTO] Scope
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScopesApi
   */
  public apiScopesScopeIdPut(
    scopeId: string,
    apiVersion: string,
    updateScopeCommandDTO?: UpdateScopeCommandDTO,
    options?: any,
  ) {
    return ScopesApiFp(this.configuration)
      .apiScopesScopeIdPut(scopeId, apiVersion, updateScopeCommandDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search scopes
   * @param {string} apiVersion The requested API version
   * @param {SearchScopesQueryDTO} [searchScopesQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScopesApi
   */
  public apiScopesSearchPost(
    apiVersion: string,
    searchScopesQueryDTO?: SearchScopesQueryDTO,
    options?: any,
  ) {
    return ScopesApiFp(this.configuration)
      .apiScopesSearchPost(apiVersion, searchScopesQueryDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all sites for a given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesGet: async (
      apiVersion: string,
      companyId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiSitesGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Sites`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (companyId !== undefined) {
        localVarQueryParameter['companyId'] = companyId;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create site
     * @param {string} apiVersion The requested API version
     * @param {CreateSiteInputDTO} [createSiteInputDTO] Configuration values for the site.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesPost: async (
      apiVersion: string,
      createSiteInputDTO?: CreateSiteInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiSitesPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Sites`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSiteInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search sites
     * @param {string} apiVersion The requested API version
     * @param {SearchSitesQueryDTO} [searchSitesQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSearchPost: async (
      apiVersion: string,
      searchSitesQueryDTO?: SearchSitesQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiSitesSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Sites/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchSitesQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSiteIdDelete: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists('apiSitesSiteIdDelete', 'siteId', siteId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiSitesSiteIdDelete', 'apiVersion', apiVersion);
      const localVarPath = `/api/Sites/{siteId}`.replace(
        `{${'siteId'}}`,
        encodeURIComponent(String(siteId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSiteIdGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists('apiSitesSiteIdGet', 'siteId', siteId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiSitesSiteIdGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Sites/{siteId}`.replace(
        `{${'siteId'}}`,
        encodeURIComponent(String(siteId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {UpdateSiteInputDTO} [updateSiteInputDTO] New configuration values for the site. Null or undefined values will be ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSiteIdPut: async (
      siteId: string,
      apiVersion: string,
      updateSiteInputDTO?: UpdateSiteInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists('apiSitesSiteIdPut', 'siteId', siteId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiSitesSiteIdPut', 'apiVersion', apiVersion);
      const localVarPath = `/api/Sites/{siteId}`.replace(
        `{${'siteId'}}`,
        encodeURIComponent(String(siteId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSiteInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SitesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all sites for a given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSitesGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllSitesSiteOutputDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSitesGet(
        apiVersion,
        companyId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create site
     * @param {string} apiVersion The requested API version
     * @param {CreateSiteInputDTO} [createSiteInputDTO] Configuration values for the site.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSitesPost(
      apiVersion: string,
      createSiteInputDTO?: CreateSiteInputDTO,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteOutputDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSitesPost(
        apiVersion,
        createSiteInputDTO,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search sites
     * @param {string} apiVersion The requested API version
     * @param {SearchSitesQueryDTO} [searchSitesQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSitesSearchPost(
      apiVersion: string,
      searchSitesQueryDTO?: SearchSitesQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchSitesSiteOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiSitesSearchPost(
          apiVersion,
          searchSitesQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSitesSiteIdDelete(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiSitesSiteIdDelete(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get a site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSitesSiteIdGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiSitesSiteIdGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {UpdateSiteInputDTO} [updateSiteInputDTO] New configuration values for the site. Null or undefined values will be ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSitesSiteIdPut(
      siteId: string,
      apiVersion: string,
      updateSiteInputDTO?: UpdateSiteInputDTO,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiSitesSiteIdPut(
          siteId,
          apiVersion,
          updateSiteInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SitesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all sites for a given company
     * @param {string} apiVersion The requested API version
     * @param {string} [companyId] Provider company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesGet(
      apiVersion: string,
      companyId?: string,
      options?: any,
    ): AxiosPromise<Array<GetAllSitesSiteOutputDTO>> {
      return localVarFp
        .apiSitesGet(apiVersion, companyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create site
     * @param {string} apiVersion The requested API version
     * @param {CreateSiteInputDTO} [createSiteInputDTO] Configuration values for the site.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesPost(
      apiVersion: string,
      createSiteInputDTO?: CreateSiteInputDTO,
      options?: any,
    ): AxiosPromise<SiteOutputDTO> {
      return localVarFp
        .apiSitesPost(apiVersion, createSiteInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search sites
     * @param {string} apiVersion The requested API version
     * @param {SearchSitesQueryDTO} [searchSitesQueryDTO] Query containing search information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSearchPost(
      apiVersion: string,
      searchSitesQueryDTO?: SearchSitesQueryDTO,
      options?: any,
    ): AxiosPromise<SearchSitesSiteOutputDTOPagedResponse> {
      return localVarFp
        .apiSitesSearchPost(apiVersion, searchSitesQueryDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSiteIdDelete(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiSitesSiteIdDelete(siteId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get a site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSiteIdGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<SiteOutputDTO> {
      return localVarFp
        .apiSitesSiteIdGet(siteId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update site
     * @param {string} siteId AlternateId of the Site
     * @param {string} apiVersion The requested API version
     * @param {UpdateSiteInputDTO} [updateSiteInputDTO] New configuration values for the site. Null or undefined values will be ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSitesSiteIdPut(
      siteId: string,
      apiVersion: string,
      updateSiteInputDTO?: UpdateSiteInputDTO,
      options?: any,
    ): AxiosPromise<SiteOutputDTO> {
      return localVarFp
        .apiSitesSiteIdPut(siteId, apiVersion, updateSiteInputDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI {
  /**
   *
   * @summary Get all sites for a given company
   * @param {string} apiVersion The requested API version
   * @param {string} [companyId] Provider company id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitesApi
   */
  public apiSitesGet(apiVersion: string, companyId?: string, options?: any) {
    return SitesApiFp(this.configuration)
      .apiSitesGet(apiVersion, companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create site
   * @param {string} apiVersion The requested API version
   * @param {CreateSiteInputDTO} [createSiteInputDTO] Configuration values for the site.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitesApi
   */
  public apiSitesPost(
    apiVersion: string,
    createSiteInputDTO?: CreateSiteInputDTO,
    options?: any,
  ) {
    return SitesApiFp(this.configuration)
      .apiSitesPost(apiVersion, createSiteInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search sites
   * @param {string} apiVersion The requested API version
   * @param {SearchSitesQueryDTO} [searchSitesQueryDTO] Query containing search information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitesApi
   */
  public apiSitesSearchPost(
    apiVersion: string,
    searchSitesQueryDTO?: SearchSitesQueryDTO,
    options?: any,
  ) {
    return SitesApiFp(this.configuration)
      .apiSitesSearchPost(apiVersion, searchSitesQueryDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete site
   * @param {string} siteId AlternateId of the Site
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitesApi
   */
  public apiSitesSiteIdDelete(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return SitesApiFp(this.configuration)
      .apiSitesSiteIdDelete(siteId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a site
   * @param {string} siteId AlternateId of the Site
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitesApi
   */
  public apiSitesSiteIdGet(siteId: string, apiVersion: string, options?: any) {
    return SitesApiFp(this.configuration)
      .apiSitesSiteIdGet(siteId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update site
   * @param {string} siteId AlternateId of the Site
   * @param {string} apiVersion The requested API version
   * @param {UpdateSiteInputDTO} [updateSiteInputDTO] New configuration values for the site. Null or undefined values will be ignored.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitesApi
   */
  public apiSitesSiteIdPut(
    siteId: string,
    apiVersion: string,
    updateSiteInputDTO?: UpdateSiteInputDTO,
    options?: any,
  ) {
    return SitesApiFp(this.configuration)
      .apiSitesSiteIdPut(siteId, apiVersion, updateSiteInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} companyId
     * @param {string} siteId
     * @param {string} edgeGatewayId
     * @param {string} apiVersion The requested API version
     * @param {any} [tagsExcel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost: async (
      companyId: string,
      siteId: string,
      edgeGatewayId: string,
      apiVersion: string,
      tagsExcel?: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost',
        'companyId',
        companyId,
      );
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost',
        'siteId',
        siteId,
      );
      // verify required parameter 'edgeGatewayId' is not null or undefined
      assertParamExists(
        'apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost',
        'edgeGatewayId',
        edgeGatewayId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Tags/excelimport/{companyId}/{siteId}/{edgeGatewayId}`
          .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)))
          .replace(`{${'siteId'}}`, encodeURIComponent(String(siteId)))
          .replace(
            `{${'edgeGatewayId'}}`,
            encodeURIComponent(String(edgeGatewayId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      if (tagsExcel !== undefined) {
        localVarFormParams.append('tagsExcel', tagsExcel as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} companyId
     * @param {string} siteId
     * @param {string} edgeGatewayId
     * @param {string} apiVersion The requested API version
     * @param {any} [tagsExcel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost(
      companyId: string,
      siteId: string,
      edgeGatewayId: string,
      apiVersion: string,
      tagsExcel?: any,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportTagsOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost(
          companyId,
          siteId,
          edgeGatewayId,
          apiVersion,
          tagsExcel,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TagsApiFp(configuration);
  return {
    /**
     *
     * @param {string} companyId
     * @param {string} siteId
     * @param {string} edgeGatewayId
     * @param {string} apiVersion The requested API version
     * @param {any} [tagsExcel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost(
      companyId: string,
      siteId: string,
      edgeGatewayId: string,
      apiVersion: string,
      tagsExcel?: any,
      options?: any,
    ): AxiosPromise<ImportTagsOutputDTO> {
      return localVarFp
        .apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost(
          companyId,
          siteId,
          edgeGatewayId,
          apiVersion,
          tagsExcel,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
  /**
   *
   * @param {string} companyId
   * @param {string} siteId
   * @param {string} edgeGatewayId
   * @param {string} apiVersion The requested API version
   * @param {any} [tagsExcel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost(
    companyId: string,
    siteId: string,
    edgeGatewayId: string,
    apiVersion: string,
    tagsExcel?: any,
    options?: any,
  ) {
    return TagsApiFp(this.configuration)
      .apiTagsExcelimportCompanyIdSiteIdEdgeGatewayIdPost(
        companyId,
        siteId,
        edgeGatewayId,
        apiVersion,
        tagsExcel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersMeGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiUsersMeGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersMeGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserInformationOutputDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersMeGet(
        apiVersion,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersMeGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<UserInformationOutputDTO> {
      return localVarFp
        .apiUsersMeGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersMeGet(apiVersion: string, options?: any) {
    return UsersApiFp(this.configuration)
      .apiUsersMeGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}
