const translations = {
  list: {
    actions: {
      delete: 'Delete',
      copy: 'Copy',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      allowWithAllCustomers: 'Allow with all customers',
      allowWithAllLogisticsOperators: 'Allow with all logistics operators',
      baseProduct: 'Base product',
      code: 'Code',
      isDisabled: 'Disabled',
      measurementUnit: 'Measurement unit',
      name: 'Name',
    },
    filters: {
      baseProduct: 'Base product',
      measurementUnit: 'Measurement unit',
    },
    notifications: {
      deleteSuccess: 'Product successfully deleted.',
    },
  },
};

export default translations;
