import {iopApp} from 'api/iopApp';
import config from 'configs/appConfig';
import {useQuery} from 'react-query';
import {CompanyOutputDTO, CompanyStructureDTO} from 'api/generated/iop';
import orderBy from 'lodash.orderby';

export function useCompaniesOptions() {
  return useQuery<CompanyOutputDTO[]>('companiesOptions', async () => {
    const {data} = await iopApp.companiesApi.apiCompaniesGet(
      config.api.version,
    );
    return orderBy(data, [c => c.displayName.toLowerCase()], 'asc');
  });
}

export function useCompanyStructure(companyId: string | null | undefined) {
  return useQuery<CompanyStructureDTO>(
    ['companyStructure', companyId],
    async () => {
      const {data} =
        await iopApp.companiesApi.apiCompaniesCompanyIdStructureGet(
          companyId ?? '',
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!companyId,
    },
  );
}
