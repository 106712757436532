import {i18n} from 'i18next';
import productsEn from '../Products/i18n/products.en';
import sitesEn from '../Sites/i18n/sites.en';
import assetsEn from '../Assets/i18n/assets.en';
import companiesEN from '../Company/i18n/companies.en';
import deliveryDestinationsEn from '../DeliveryDestinations/i18n/deliveryDestinations.en';
import scopesEn from '../Scopes/i18n/scopes.en';
import edgeGatewaysEn from '../EdgeGateways/i18n/edgeGateways.en';
import apiCollectionsEn from '../ApiCollections/i18n/apiCollections.en';
import dnaInfoServersEn from '../DnaInfoServers/i18n/dnaInfoServers.en';

export const add = (i18next: i18n) => {
  i18next.addResourceBundle('en-US', 'companies', companiesEN);
  i18next.addResourceBundle('en-US', 'products', productsEn);
  i18next.addResourceBundle('en-US', 'sites', sitesEn);
  i18next.addResourceBundle('en-US', 'assets', assetsEn);
  i18next.addResourceBundle(
    'en-US',
    'deliveryDestinations',
    deliveryDestinationsEn,
  );
  i18next.addResourceBundle('en-US', 'scopes', scopesEn);
  i18next.addResourceBundle('en-US', 'edgeGateways', edgeGatewaysEn);
  i18next.addResourceBundle('en-US', 'apiCollections', apiCollectionsEn);
  i18next.addResourceBundle('en-US', 'dnaInfoServers', dnaInfoServersEn);
};
