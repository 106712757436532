const translations = {
  list: {
    actions: {
      delete: 'Delete',
      usedInEdgeGatewaysDisabledDelete:
        'Site is used in at least one edge gateway',
      usedInReservationsDisabledDelete:
        'Site is used in at least one reservation',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      name: 'Name',
      type: 'Type',
      disabled: 'Disabled',
      street: 'Street',
      city: 'City',
      country: 'Country',
    },
    filters: {
      siteType: 'Site type',
      country: 'Country',
    },
    notifications: {
      deleteSuccess: 'Site successfully deleted.',
    },
  },
};

export default translations;
