import React, {useContext} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {AppContext} from '../Layout';
import {
  useActiveCompanyIsProvider,
  useCompaniesBrowser,
} from '../context/companies-browser-context';
import {getDefaultRoute} from './Company/routes';

const Home = () => {
  // We can also render a loading screen here while we wait to find that out
  const appContext = useContext(AppContext);
  const {pathname} = useLocation();
  const history = useHistory();
  const {activeCompanyId} = useCompaniesBrowser();
  const activeCompanyIsProvider = useActiveCompanyIsProvider();
  React.useEffect(() => {
    const isFromReachRouterDefault = pathname !== '/';
    if (isFromReachRouterDefault) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (appContext.user === null || activeCompanyId === null) {
    return null;
  }

  return (
    <Redirect to={getDefaultRoute(activeCompanyId, activeCompanyIsProvider)} />
  );
};

export default Home;
