import editorEn from '../Editor/i18n.en';
import listEn from '../List/i18n.en';

const translations = {
  assetTypes: {
    Container: 'Container',
    Other: 'Other',
    Ship: 'Ship',
    Trailer: 'Trailer',
    Truck: 'Truck',
  },
  ...editorEn,
  ...listEn,
};

export default translations;
