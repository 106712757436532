import i18n from 'i18n';
import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'fieldErrors.required',
  },
  string: {
    min: ({min}) => i18n.t('fieldErrors.minLength', {length: min}),
    max: ({max}) => i18n.t('fieldErrors.maxLength', {length: max}),
  },
});
Yup.addMethod(Yup.string, 'integer', function () {
  const that = this as any;
  return that.matches(/^-?\d+$/, 'fieldErrors.digitsOnly');
});
Yup.addMethod(Yup.string, 'noNegativeInteger', function () {
  const that = this as any;
  return that.matches(/^\d+$/, 'fieldErrors.integerOnlyNoNegative');
});
Yup.addMethod(Yup.string, 'numeric', function () {
  const that = this as any;
  return that.matches(/^-?\d+[.,]?\d*$/, 'fieldErrors.numericOnly');
});
Yup.addMethod(Yup.string, 'noNegativeNumeric', function () {
  const that = this as any;
  return that.matches(/^\d+[.,]?\d*$/, 'fieldErrors.numericOnlyNoNegative');
});
Yup.addMethod(Yup.string, 'minValue', function (min: number) {
  const that = this as any;
  return that.test(
    'numericMinValue',
    i18n.t('fieldErrors.minVal', {min: min}),
    function (value: any) {
      if (!value || value.length === 0) {
        return true;
      }

      if (value.includes(',')) {
        value = value.replace(/,/, '.');
      }

      const numValue = Number(value);
      if (isNaN(numValue)) {
        return true;
      }

      return numValue >= min;
    },
  );
});
Yup.addMethod(Yup.string, 'maxValue', function (max: number) {
  const that = this as any;
  return that.test(
    'numericMaxValue',
    i18n.t('fieldErrors.maxVal', {max: max}),
    function (value: any) {
      if (!value || value.length === 0) {
        return true;
      }

      if (value.includes(',')) {
        value = value.replace(/,/, '.');
      }

      const numValue = Number(value);
      if (isNaN(numValue)) {
        return true;
      }

      return numValue <= max;
    },
  );
});
Yup.addMethod(Yup.string, 'is32BitInteger', function (multiplier?: number) {
  return (this as any).test(
    'is-32-bit-integer',
    'fieldErrors.invalid32BitInt',
    function (value: string) {
      if (!value) {
        return true;
      }

      let number = Number(value);
      if (isNaN(number)) {
        return true;
      }

      if (multiplier !== undefined) {
        number = number * multiplier;
      }

      const min = -2147483648;
      const max = 2147483647;
      return number >= min && number <= max;
    },
  );
});
