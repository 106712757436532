const translations = {
  infoTagUsage: {
    None: 'None',
    Pressure: 'Pressure',
    TankLevelMass: 'Tank level (mass)',
    Temperature: 'Temperature',
  },
  editor: {
    fieldErrors: {
      duplicateDnaInfoTagName:
        'The server already has a tag with this DNA Info tag name',
      duplicateTagName: 'The site/asset already has a tag with this Name',
      invalidLimitOrder: 'Provided limits are in wrong order',
      siteOrAssetRequired: 'Site or Asset must be selected',
    },
    buttons: {
      new: 'New tag',
    },
    actions: {
      delete: 'Delete',
    },
    columns: {
      name: 'Name',
      siteOrAsset: 'Site/Asset',
      dnaInfoTagName: 'DNA Info tag name',
    },
    titles: {
      create: 'New DNA Info server: {{displayName}}',
      edit: 'DNA Info server: {{displayName}}',
    },
    labels: {
      name: 'Name',

      generalTitle: 'General',
      dnaInfoTagName: 'DNA Info tag name',
      unit: 'Unit',
      site: 'Site',
      orAsset: 'Or asset',
      equipment: 'Equipment',
      usage: 'Usage',
      isDisabled: 'Disabled',

      limitsTitle: 'Limits',
      invalidHigh: 'Invalid high',
      alarmHigh: 'Alarm high',
      warningHigh: 'Warning high',
      warningLow: 'Warning low',
      alarmLow: 'Alarm low',
      invalidLow: 'Invalid low',
    },
    notifications: {
      createSuccess: 'DNA Info server successfully created.',
      updateSuccess: 'DNA Info server successfully updated.',
    },
  },

  tabs: {
    general: 'General',
    tags: 'Tags',
  },
};

export default translations;
