const translations = {
  massImportTags: {
    errorMessages: {
      edgeGatewayNotLinkedToSite:
        'Edge gateway is not linked to a site, cannot use mass import.',
    },
    labels: {
      edgeGateway: 'Edge gateway',
      site: 'Site',
    },
    notifications: {
      importSuccess: 'Tags successfully imported',
    },
    tabs: {
      import: 'Import',
    },
  },
};

export default translations;
