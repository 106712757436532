import editorEn from '../Editor/i18n.en';
import listEn from '../List/i18n.en';

const translations = {
  ...editorEn,
  ...listEn,
  siteTypes: {
    DistributionTerminal: 'Distribution terminal',
    LoadingStation: 'Loading station',
    FuelingStation: 'Fueling station',
    CustomerTerminal: 'Customer terminal',
    Depot: 'Depot',
  },
};

export default translations;
