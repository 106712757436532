const translations = {
  editor: {
    actions: {
      delete: 'Delete',
    },
    buttons: {
      getModuleLogs: 'Get module logs',
      newOpcUaServer: 'New server',
      newOpcUaTag: 'New tag',
      uploadNow: 'Upload now',
      enableTelemetryCollection: 'Enable telemetry collection',
    },
    columns: {
      displayName: 'Name',
      endpointUrl: 'URL',
      nodeId: 'Node Id',
    },
    errorMessages: {
      duplicateServerName: 'Server name must be unique',
      invalidLimitOrder: 'Provided limits are in wrong order',
      siteOrAssetRequired: 'Site or Asset must be selected',
    },
    helpTexts: {
      opcUaServerPassword: 'Leave empty to keep currently saved password',
    },
    labels: {
      asset: 'Asset',
      authenticationMode: 'Authentication mode',
      description: 'Description',
      displayName: 'Name',
      endpointUrl: 'URL',
      equipment: 'Equipment',
      general: 'General',
      iotHubDeviceId: 'IoT Hub device ID',
      isDisabled: 'Disabled',
      lastConnected: 'Last connected',
      limits: 'Limits',
      limitAlarmHigh: 'Alarm high',
      limitAlarmLow: 'Alarm low',
      limitInvalidHigh: 'Invalid high',
      limitInvalidLow: 'Invalid low',
      limitWarningHigh: 'Warning high',
      limitWarningLow: 'Warning low',
      measurementUnit: 'Measurement unit',
      moduleLogs: 'Module logs',
      opcUaNodeId: 'Node Id',
      or: 'or',
      password: 'Password',
      publishingInterval: 'Publishing interval (seconds)',
      samplingInterval: 'Sampling interval (seconds)',
      site: 'Site',
      state: 'State',
      tagUsage: 'Usage',
      username: 'Username',
      useSecurity: 'Use security',
    },
    notifications: {
      enableTelemetryCollectionSucceeded:
        'Telemetry collection successfully enabled.',
      configurationUploadSucceeded:
        'Edge gateway configuration successfully uploaded',
      createSuccess: 'Edge gateway successfully created.',
      updateSuccess: 'Edge gateway successfully updated.',
    },
    opcUaAuthenticationModes: {
      Anonymous: 'Anonymous',
      UsernamePassword: 'Username and password',
    },
    tabs: {
      general: 'General',
      opcUaServers: 'OPC UA servers',
      tags: 'Tags',
    },
    tagUsages: {
      Pressure: 'Pressure',
      Temperature: 'Temperature',
      TankLevelMass: 'Tank level (mass)',
    },
    titles: {
      create: 'New edge gateway: {{displayName}}',
      edit: 'Edge gateway: {{displayName}}',
    },
  },
};

export default translations;
