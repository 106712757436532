import axios, {AxiosInstance} from 'axios';
import config from 'configs/appConfig';
import {fetchServiceToken} from 'services/authService';
import {
  CompaniesApi,
  CompanyTypesApi,
  SitesApi,
  Configuration,
  UsersApi,
  ProductsApi,
  LookupApi,
  AssetsApi,
  ApiTagDataSetsApi,
  DeliveryDestinationsApi,
  EdgeGatewaysApi,
  ScopesApi,
  TagsApi,
  DnaInfoServersApi,
} from './generated/iop';

const configuration = new Configuration({
  accessToken: fetchServiceToken,
});

class IopApp {
  private axiosInstance: AxiosInstance = axios.create();

  public setAxiosInstance(axiosInstance: AxiosInstance): void {
    this.axiosInstance = axiosInstance;
  }
  public sitesApi = new SitesApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public companiesApi = new CompaniesApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public companyTypesApi = new CompanyTypesApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public productsApi = new ProductsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public lookupApi = new LookupApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public usersMeApi = new UsersApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public assetsApi = new AssetsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public deliveryDestinationsApi = new DeliveryDestinationsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public edgeGatewaysApi = new EdgeGatewaysApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public scopesApi = new ScopesApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public dataSetsApi = new ApiTagDataSetsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public tagsApi = new TagsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public dnaInfoServersApi = new DnaInfoServersApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
}
/**
 * All api endpoints are accessed through iopApi singleton.
 * It allows setting the auth token and other possible headers in one place.
 */
export const iopApp = new IopApp();
