const translations = {
  shared: {
    states: {
      connected: 'Connected',
      disconnected: 'Disconnected',
    },
  },
};

export default translations;
