import React from 'react';

export interface IContext {
  pageTitle: string;
  setPageTitle: (pageTitle: string) => void;
}

export function usePageTitleContext(): IContext {
  const context = React.useContext<IContext>(PageTitleContext);
  if (!context) {
    throw new Error(
      `usePageTitleContext must be used within a PageTitleContext`,
    );
  }
  return context;
}

const PageTitleContext = React.createContext<IContext>({
  pageTitle: '',
  setPageTitle: () => {},
});

export interface IProvider {
  children: React.ReactNode;
}

export function PageTitleContextProvider(props: IProvider) {
  const [pageTitle, setPageTitle] = React.useState('');

  return (
    <PageTitleContext.Provider
      value={{
        pageTitle,
        setPageTitle,
      }}
    >
      {props.children}
    </PageTitleContext.Provider>
  );
}

export function usePageTitle(pageTitle: string) {
  const {setPageTitle} = usePageTitleContext();
  React.useEffect(() => {
    setPageTitle(pageTitle);

    return () => {
      setPageTitle('');
    };
  }, [pageTitle, setPageTitle]);
}
