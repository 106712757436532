import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MuiTreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {RenderTree, useTreeView} from '../../context/tree-view-context';
import {useNavigate} from 'components/Hooks/useNavigate';
import {CircularProgress} from '@material-ui/core';
import {getMatchingRoute} from '../Company/routes';
import {useCompaniesBrowser} from '../../context/companies-browser-context';
import {Link} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
    color: 'white',
    height: 110,
    flexGrow: 1,
  },
  label: {
    color: 'white',
    fontSize: '16px',
  },
  labelLink: {
    color: 'white',
    // So link takes the full width
    display: 'block',
    // Remove default link style
    textDecoration: 'none',
    // For ellipsis if text is too long
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  labelWrapper: {
    // Can't use 100% as the expand/collapse icon requires space
    width: 'calc(100% - 19px)',
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content &': {
      backgroundColor: 'rgba(29, 29, 29, 0.45)',
    },
    '.MuiTreeItem-root > .MuiTreeItem-content &:hover': {
      backgroundColor: 'rgba(29, 29, 29, 0.30)',
    },
    '.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content &': {
      backgroundColor: 'rgba(29, 29, 29, 0.30)',
    },
  },
});

export function TreeView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {activeNode, nodes, expandedNodeIds, isLoading, toggleExpanded} =
    useTreeView();

  if (isLoading) {
    return (
      <div style={{textAlign: 'center'}}>
        <CircularProgress style={{color: 'white'}} />
      </div>
    );
  }

  return (
    <MuiTreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={expandedNodeIds}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={activeNode ? [activeNode.id] : []}
    >
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        collapseIcon={<></>}
        expandIcon={<></>}
        label={<></>}
      >
        {nodes.children?.map(node => (
          <Item
            key={node.id}
            labelClassName={classes.label}
            labelLinkClassName={classes.labelLink}
            labelWrapperClassName={classes.labelWrapper}
            navigate={navigate}
            node={node}
            toggleExpanded={toggleExpanded}
          />
        ))}
      </TreeItem>
    </MuiTreeView>
  );
}

interface ItemProps {
  labelClassName: string;
  labelLinkClassName: string;
  labelWrapperClassName: string;
  node: RenderTree;
  navigate: (path: string) => void;
  toggleExpanded: (nodeId: string) => void;
}

function useRouteAllowedByType(nodePath: string | undefined) {
  const {activeCompany} = useCompaniesBrowser();

  const isAllowed = React.useMemo(() => {
    if (nodePath === undefined) {
      return true;
    }

    const route = getMatchingRoute(nodePath);
    if (
      route === null ||
      route.requiresOneOfCompanyTypes === undefined ||
      route.requiresOneOfCompanyTypes.length === 0
    ) {
      return true;
    }

    if (activeCompany === null) {
      return false;
    }

    return activeCompany.types.some(
      t =>
        route.requiresOneOfCompanyTypes?.some(rt => rt === t.companyTypeId) ??
        false,
    );
  }, [activeCompany, nodePath]);

  return isAllowed;
}

const Item = ({
  labelClassName,
  labelLinkClassName,
  labelWrapperClassName,
  node,
  navigate,
  toggleExpanded,
}: ItemProps) => {
  const itemRef = React.useRef<HTMLSpanElement>(null!);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const isAllowedByType = useRouteAllowedByType(node.path);

  React.useEffect(() => {
    if (itemRef.current === null) {
      return;
    }

    const parent = itemRef.current.parentElement;
    if (parent === null) {
      return;
    }

    if (parent.scrollWidth <= parent.offsetWidth) {
      return;
    }

    setIsOverflowing(true);
  }, []);

  if (!isAllowedByType) {
    return null;
  }

  return (
    <TreeItem
      classes={{
        label: labelWrapperClassName,
      }}
      key={node.id}
      nodeId={node.id}
      label={
        node.path ? (
          <Link className={labelLinkClassName} to={node.path}>
            <span className={labelClassName} ref={itemRef}>
              {node.name}
            </span>
          </Link>
        ) : (
          <span className={labelClassName} ref={itemRef}>
            {node.name}
          </span>
        )
      }
      onIconClick={() => {
        toggleExpanded(node.id);
      }}
      title={isOverflowing ? node.name : undefined}
    >
      {Array.isArray(node.children)
        ? node.children.map(childNode => (
            <Item
              key={childNode.id}
              labelClassName={labelClassName}
              labelLinkClassName={labelLinkClassName}
              labelWrapperClassName={labelWrapperClassName}
              navigate={navigate}
              node={childNode}
              toggleExpanded={toggleExpanded}
            />
          ))
        : null}
    </TreeItem>
  );
};
