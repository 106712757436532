import {LazyExoticComponent} from 'react';
import CompanyType from 'utils/CompanyType';
import {Modes} from 'utils/router';

export interface IRouterMap {
  comp: LazyExoticComponent<any>;
  path: string;
  mode?: Modes;
  requiredFeatureFlag?: string;
  requiresOneOfCompanyTypes?: CompanyType[];
}

export type ICreatePath = () => string;
export type IUpdatePath = (id: string) => string;
export type IReadPath = (id: string) => string;
export type IMainPath = (id: string) => string;

interface IGeneratePathByPlaceholder<T> {
  path: string;
  id: string;
  placeholder: T;
}

export function generatePathByPlaceholderId<T extends string>({
  path,
  id,
  placeholder,
}: IGeneratePathByPlaceholder<T>) {
  return path.replace(placeholder as string, id);
}
