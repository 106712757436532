const translations = {
  dataSetTypes: {
    labkotec: 'Labkotec',
    netbiter: 'Netbiter',
    tolcon: 'Tolcon',
  },
  list: {
    actions: {
      delete: 'Delete',
      deleteDisabled: 'Tags must be removed before deleting API collection',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      dataSource: 'Data source',
      name: 'Name',
    },
    filters: {
      dataSetType: 'DataSet type',
    },
  },
  tabs: {
    general: 'General',
    tags: 'Tags',
    labkotec: 'Labkotec',
    netbiter: 'Netbiter',
    tolcon: 'Tolcon',
  },
};

export default translations;
