const translations = {
  dataSetTypes: {
    labkotec: 'Labkotec',
    netbiter: 'Netbiter',
    tolcon: 'Tolcon',
  },
  apiTagUsage: {
    None: 'None',
    Pressure: 'Pressure',
    TankLevelMass: 'Tank level (mass)',
    Temperature: 'Temperature',
  },
  editor: {
    fieldErrors: {
      duplicateTagUnitId:
        'Another tag has the same Tag unit id in this collection',
      duplicateTagName: 'The site/asset has already a Tag with this Name',
      duplicateTolconIdentifiers:
        'Another tag has the same unitId-measurement-container combo in this collection',
      duplicateNetbiterIdentifiers:
        'Another tag has the same systemId-tagId combo in this collection',
      invalidLimitOrder: 'Provided limits are in wrong order',
      siteOrAssetRequired: 'Site or Asset must be selected',
      identifierInvalidCharacters:
        'Identifier contains an invalid character (:)',
    },
    helpTexts: {
      keepPassword: 'Leave empty to keep currently saved password',
      keepKey: 'Leave empty to keep currently saved key',
    },
    buttons: {
      new: 'New tag',
    },
    actions: {
      delete: 'Delete',
    },
    columns: {
      name: 'Name',
      siteOrAsset: 'Site/Asset',
      tagUnitId: 'Tag unit id',
      unitId: 'Unit id',
      measurement: 'Measurement',
      systemId: 'System id',
      tagId: 'Tag id',
    },
    titles: {
      create: 'New {{dataSource}} data set: {{displayName}}',
      edit: '{{dataSource}} data set: {{displayName}}',
    },
    labels: {
      name: 'Name',
      certificate: 'Certificate',
      password: 'Password',
      collectionRate: 'Collection rate',
      webhookPassword: 'Webhook password',

      generalTitle: 'General',
      tagUnitId: 'Tag unit id',
      unitId: 'Unit id',
      measurement: 'Measurement',
      containerName: 'Container name',
      systemId: 'System id',
      tagId: 'Tag id',
      key: 'Key',

      unit: 'Unit',
      site: 'Site',
      orAsset: 'Or asset',
      equipment: 'Equipment',
      usage: 'Usage',

      limitsTitle: 'Limits',
      invalidHigh: 'Invalid high',
      alarmHigh: 'Alarm high',
      warningHigh: 'Warning high',
      warningLow: 'Warning low',
      alarmLow: 'Alarm low',
      invalidLow: 'Invalid low',

      lastValue: 'Last value',
      lastValueTimestamp: 'Timestamp',
    },
  },
  notifications: {
    createSuccess: 'API collection successfully created.',
    deleteSuccess: 'API collection successfully deleted.',
    updateSuccess: 'API collection successfully updated.',
  },
  tabs: {
    general: 'General',
    tags: 'Tags',
    labkotec: 'Labkotec',
    netbiter: 'Netbiter',
    tolcon: 'Tolcon',
  },
};

export default translations;
