import {CompanyStructureDTO} from 'api/generated/iop';
import {useCompanyStructure} from 'components/Hooks/useCompanies';
import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {
  generateCompanyIdAssetsPath,
  generateCompanyIdDeliveryDestinationsPath,
  generateCompanyIdEdgeGatewaysPath,
  generateCompanyIdProductIdCustomersPath,
  generateCompanyIdProductIdEquipmentsPath,
  generateCompanyIdProductIdGeneralPath,
  generateCompanyIdProductIdLogisticsCompaniesPath,
  generateCompanyIdProductsPath,
  generateCompanyIdSiteIdEquipmentPath,
  generateCompanyIdSiteIdGeneralPath,
  generateCompanyIdSiteIdParametersPath,
  generateCompanyIdSiteIdSchedulingPath,
  generateCompanyIdSiteIdScopesPath,
  generateCompanyIdSitesPath,
  generateCompanyGeneralPath,
  generateCompanyChildCompaniesPath,
  generateCompanyIdScopesPath,
  generateCompanyIdScopeIdGeneralPath,
  generateCompanyIdApiCollectionsPath,
  generateCompanyIdDnaInfoServersPath,
} from '../Pages/Company/routes';
import {useCompaniesBrowser} from './companies-browser-context';

export enum Type {
  SITE = 'SITE',
  PRODUCT = 'PRODUCT',
  SCOPE = 'SCOPE',
  ASSET = 'ASSET',
  DELIVERYDESTINATION = 'DELIVERYDESTINATION',
}
export interface RenderTree {
  id: string;
  name: string;
  children?: RenderTree[];
  path?: string;
  otherMatchedPaths?: string[];
  type?: Type;
}

const emptyNodes: RenderTree = {
  id: '0',
  name: '',
};

function getNodesFromStructure(
  companyId: string,
  structure: CompanyStructureDTO,
  t: TFunction,
): RenderTree {
  return {
    id: '0',
    name: '',
    children: [
      // {
      //   id: '1',
      //   name: t('treeView.system'),
      //   children: [
      //     {
      //       id: '11',
      //       name: t('treeView.baseProducts'),
      //     },
      //   ],
      // },
      {
        id: '2',
        name: t('treeView.company'),
        path: generateCompanyGeneralPath(companyId),
        otherMatchedPaths: [generateCompanyChildCompaniesPath(companyId)],
        children: [
          {
            id: '21',
            name: t('treeView.products'),
            path: generateCompanyIdProductsPath(companyId),
            children: structure.products?.map(product => ({
              id: product.id ?? '',
              name: product.displayName ?? '',
              path: generateCompanyIdProductIdGeneralPath({
                companyId,
                productId: product.id ?? '',
              }),
              otherMatchedPaths: [
                generateCompanyIdProductIdEquipmentsPath({
                  companyId,
                  productId: product.id ?? '',
                }),
                generateCompanyIdProductIdCustomersPath({
                  companyId,
                  productId: product.id ?? '',
                }),
                generateCompanyIdProductIdLogisticsCompaniesPath({
                  companyId,
                  productId: product.id ?? '',
                }),
              ],
              type: Type.PRODUCT,
            })),
          },
          {
            id: '22',
            name: t('treeView.sites'),
            path: generateCompanyIdSitesPath(companyId),
            children: structure.sites?.map(site => ({
              id: site.id ?? '',
              name: site.displayName ?? '',
              path: generateCompanyIdSiteIdGeneralPath({
                companyId,
                siteId: site.id ?? '',
              }),
              otherMatchedPaths: [
                generateCompanyIdSiteIdScopesPath({
                  companyId,
                  siteId: site.id ?? '',
                }),
                generateCompanyIdSiteIdEquipmentPath({
                  companyId,
                  siteId: site.id ?? '',
                }),
                generateCompanyIdSiteIdParametersPath({
                  companyId,
                  siteId: site.id ?? '',
                }),
                generateCompanyIdSiteIdSchedulingPath({
                  companyId,
                  siteId: site.id ?? '',
                }),
              ],
              type: Type.SITE,
            })),
          },
          {
            id: '23',
            name: t('treeView.scopes'),
            path: generateCompanyIdScopesPath(companyId),
            children: structure.scopes?.map(scope => ({
              id: scope.id ?? '',
              name: scope.displayName ?? '',
              path: generateCompanyIdScopeIdGeneralPath({
                companyId,
                scopeId: scope.id ?? '',
              }),
              type: Type.SCOPE,
            })),
          },
          {
            id: '24',
            name: t('treeView.assets'),
            path: generateCompanyIdAssetsPath(companyId),
          },
          {
            id: '25',
            name: t('treeView.deliveryDestinations'),
            path: generateCompanyIdDeliveryDestinationsPath(companyId),
          },
          {
            id: '26',
            name: t('treeView.edgeGateways'),
            path: generateCompanyIdEdgeGatewaysPath(companyId),
          },
          {
            id: '27',
            name: t('treeView.apiCollections'),
            path: generateCompanyIdApiCollectionsPath(companyId),
          },
          {
            id: '28',
            name: t('treeView.dnaInfoServers'),
            path: generateCompanyIdDnaInfoServersPath(companyId),
          },
        ],
      },
    ],
  };
}

export interface IProvider {
  children: React.ReactNode;
}

export interface IContext {
  activeNode: RenderTree | null;
  expandedNodeIds: string[];
  isLoading: boolean;
  nodes: RenderTree;
  toggleExpanded: (nodeId: string) => void;
}

function findNodeAndParentsByPath(
  node: RenderTree,
  path: string,
  parents?: readonly RenderTree[],
): {
  matchingNode: RenderTree | null;
  matchingNodeParents: readonly RenderTree[];
} {
  if (parents === undefined) {
    parents = [];
  }

  if (
    node.path === path ||
    node.otherMatchedPaths?.find(p => p === path) !== undefined
  ) {
    return {
      matchingNode: node,
      matchingNodeParents: parents,
    };
  }

  parents = [...parents, node];

  if (node.children !== undefined) {
    for (const child of node.children) {
      const recursiveResult = findNodeAndParentsByPath(child, path, parents);
      if (recursiveResult.matchingNode !== null) {
        return recursiveResult;
      }
    }
  }

  return {
    matchingNode: null,
    matchingNodeParents: [],
  };
}

export function findNodeByPath(
  node: RenderTree,
  path: string,
): RenderTree | null {
  if (
    node.path === path ||
    node.otherMatchedPaths?.find(p => p === path) !== undefined
  ) {
    return node;
  }

  if (node.children !== undefined) {
    for (const child of node.children) {
      const recursiveResult = findNodeByPath(child, path);
      if (recursiveResult !== null) {
        return recursiveResult;
      }
    }
  }

  return null;
}

const TreeView = React.createContext<IContext>({
  activeNode: null,
  expandedNodeIds: ['0', '2'],
  isLoading: true,
  nodes: emptyNodes,
  toggleExpanded: () => {},
});

export function useTreeView(): IContext {
  const context = React.useContext<IContext>(TreeView);
  if (!context) {
    throw new Error(`useTreeView must be used within a TreeViewProvider`);
  }
  return context;
}

export function TreeViewProvider(props: IProvider) {
  const {t} = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const [activeNode, setActiveNode] = React.useState<RenderTree | null>(null);
  const [expandedNodeIds, setExpandedNodeIds] = React.useState<string[]>([
    '0',
    '2',
  ]);
  const previousPath = React.useRef<string | null>(null);
  const previousName = React.useRef<string | null>(null);
  const {activeCompanyId} = useCompaniesBrowser();
  const {data: companyStructure, isFetching: isCompanyStructureLoading} =
    useCompanyStructure(activeCompanyId);

  const nodes = React.useMemo(() => {
    if (companyStructure === undefined) {
      return emptyNodes;
    }

    const newNodes = getNodesFromStructure(
      activeCompanyId ?? '',
      companyStructure,
      t,
    );
    return newNodes;
  }, [activeCompanyId, companyStructure, t]);

  React.useEffect(() => {
    // Check if current node name has changed,
    // it should cause tabs to be updated.
    var current = findNodeByPath(nodes, path);
    if (previousName.current === current?.name) {
      return;
    }

    // Reset
    setActiveNode(null);
    previousPath.current = null;

    previousName.current = current?.name ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  React.useEffect(() => {
    if (nodes === emptyNodes) {
      return;
    }

    // Company structure is loading,
    // we won't be able to find the matching node until we have it.
    if (isCompanyStructureLoading) {
      return;
    }

    if (previousPath.current === path) {
      return;
    }

    const {matchingNode, matchingNodeParents} = findNodeAndParentsByPath(
      nodes,
      path,
    );
    if (matchingNode !== null && activeNode !== matchingNode) {
      setActiveNode(matchingNode);
    }

    // Ensure all the parents of the matching node are expanded
    setExpandedNodeIds(
      expandedNodeIds.concat(matchingNodeParents.map(p => p.id)),
    );

    previousPath.current = path;
  }, [path, nodes, activeNode, expandedNodeIds, isCompanyStructureLoading]);

  const toggleExpanded = React.useCallback(
    (nodeId: string) => {
      const isExpanded = expandedNodeIds.some(id => id === nodeId);
      if (isExpanded) {
        setExpandedNodeIds(nodeIds => nodeIds.filter(id => id !== nodeId));
      } else {
        setExpandedNodeIds(nodeIds => nodeIds.concat([nodeId]));
      }
    },
    [expandedNodeIds],
  );

  const value: IContext = {
    activeNode,
    nodes,
    expandedNodeIds,
    isLoading: isCompanyStructureLoading,
    toggleExpanded,
  };

  return <TreeView.Provider value={value} {...props} />;
}
