const host = window.location.host;
let env: string | undefined;
switch (host) {
  case 'localhost:3001':
    env = 'local';
    break;
  case 'happy-cliff-01f8d2303.1.azurestaticapps.net':
    env = 'dev';
    break;
  case 'configurator.dev.integratedoperations.on.valmetsystem.com':
    env = 'dev';
    break;
  case 'lively-dune-0c4364e03.1.azurestaticapps.net':
    env = 'test';
    break;
  case 'configurator.test.integratedoperations.on.valmetsystem.com':
    env = 'test';
    break;
  case 'purple-wave-0aeb84703.1.azurestaticapps.net':
    env = 'demo';
    break;
  case 'configurator.demo.integratedoperations.on.valmetsystem.com':
    env = 'demo';
    break;
  case 'gray-glacier-0628a6c03.1.azurestaticapps.net':
    env = 'internaldemo';
    break;
  case 'conf.internaldemo.integratedoperations.on.valmetsystem.com':
    env = 'internaldemo';
    break;
  case 'purple-mud-07fcf1d03.1.azurestaticapps.net':
    env = 'prod';
    break;
  case 'configurator.integratedoperations.on.valmetsystem.com':
    env = 'prod';
    break;
  case 'nice-mushroom-0e8889103.1.azurestaticapps.net':
    env = 'staging';
    break;
  case 'configurator.staging.integratedoperations.on.valmetsystem.com':
    env = 'staging';
    break;
}

const config = {
  api: {
    url: '',
    version: '1.0-config',
    clientId: '',
  },
  msal: {
    instance: 'https://login.microsoftonline.com/',
    tenant: '',
    clientId: '',
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage',
    apiScopes: 'ConfigurationUi.Access',
    redirectUri: window.location.origin + '/aad-callback',
  },
  azureMaps: {
    clientId: '',
  },
};

if (env === 'local') {
  config.api.url = 'http://localhost:10586';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'c758b98b-f1d1-4a26-810c-018f3eb942c3';
} else if (env === 'dev') {
  config.api.url = 'https://wes-sap-euw-dv-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'c758b98b-f1d1-4a26-810c-018f3eb942c3';
} else if (env === 'test') {
  config.api.url = 'https://wes-sap-euw-qa-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'c758b98b-f1d1-4a26-810c-018f3eb942c3';
} else if (env === 'demo') {
  config.api.url = 'https://wes-sad-euw-dm-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'c758b98b-f1d1-4a26-810c-018f3eb942c3';
} else if (env === 'internaldemo') {
  config.api.url = 'https://wes-sad-euw-id-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'c758b98b-f1d1-4a26-810c-018f3eb942c3';
} else if (env === 'prod') {
  config.api.url = 'https://wes-sap-euw-pd-iop-app-backend.azurewebsites.net';
  config.api.clientId = '85061a37-4c5f-4b0a-b56e-1d8b3c055058';
  config.msal.tenant = 'valmetsolutions.onmicrosoft.com';
  config.msal.clientId = '24421f5e-fcd8-4d6e-9146-b304c91d4a05';
} else if (env === 'staging') {
  config.api.url = 'https://wes-sap-euw-st-iop-app-backend.azurewebsites.net';
  config.api.clientId = '00b38838-ab9e-4d49-926a-8b5626eba644';
  config.msal.tenant = 'valmetsolutions.onmicrosoft.com';
  config.msal.clientId = '3228b11d-9351-4873-9979-afadf40255e7';
} else {
  throw new Error('Unknown environment: ' + env);
}

export default config;
