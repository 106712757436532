import * as React from 'react';

import {routes as companyRoutes} from 'components/Pages/Company/routes';
import {useUsersMe} from 'components/Hooks/useUsersMe';
import {IRouterMap} from 'utils/router';

export const PERMISSION_ALIAS_APP_ADMIN = 'App.Administrate';

export type TPermissionAliasTypes = typeof PERMISSION_ALIAS_APP_ADMIN;

export function generateReadRoleByAlias(
  permissionAlias: TPermissionAliasTypes,
): string {
  return `${permissionAlias}.Read`;
}

export function generateCreateRoleByAlias(
  permissionAlias: TPermissionAliasTypes,
): string {
  return `${permissionAlias}.Create`;
}

export function generateUpdateRoleByAlias(
  permissionAlias: TPermissionAliasTypes,
): string {
  return `${permissionAlias}.Update`;
}

export function generateDeleteRoleByAlias(
  permissionAlias: TPermissionAliasTypes,
): string {
  return `${permissionAlias}.Delete`;
}

export function generateAdminRoleByAlias(
  permissionAlias: TPermissionAliasTypes,
): string {
  return `${permissionAlias}.Admin`;
}
export function generateAppAdminRoleByAlias(): string {
  return PERMISSION_ALIAS_APP_ADMIN;
}

export type IPermissionAlias = Nullable<TPermissionAliasTypes>;

export interface IRoute {
  path: string;
  render: () => JSX.Element;
}

interface IRoutes {
  companyRoutes: IRouterMap[];
}
export interface IPermissionContext {
  getRoutes: () => IRoutes;
  isReadable: (permissionAlias: IPermissionAlias) => boolean;
  isUpdatable: (permissionAlias: IPermissionAlias) => boolean;
  isDeletable: (permissionAlias: IPermissionAlias) => boolean;
  isCreatable: (permissionAlias: IPermissionAlias) => boolean;
  isPermit: (customPermission: string, allowNoScopes: boolean) => boolean;

  pagePermissionAlias: Nullable<
    TPermissionAliasTypes[] | TPermissionAliasTypes
  >;
  setPagePermissionAlias: (
    permissionAlias: Nullable<TPermissionAliasTypes[] | TPermissionAliasTypes>,
  ) => void;
}

const PermissionContext = React.createContext<IPermissionContext>({
  isPermit: () => true,
  isCreatable: () => true,
  isReadable: () => true,
  isUpdatable: () => true,
  isDeletable: () => true,
  getRoutes: () => {
    return {
      companyRoutes,
    };
  },
  pagePermissionAlias: null,
  setPagePermissionAlias: (
    permissionAlias: Nullable<TPermissionAliasTypes[] | TPermissionAliasTypes>,
  ) => undefined,
});

export function usePermission(): IPermissionContext {
  const context = React.useContext<IPermissionContext>(PermissionContext);
  if (!context) {
    throw new Error(`usePermission must be used within a PermissionProvider`);
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

export type Nullable<T> = T | null | undefined;
export function PermissionProvider(props: IProvider): JSX.Element {
  const {data: me, isLoading} = useUsersMe();
  const [pagePermissionAlias, setPagePermissionAlias] =
    React.useState<Nullable<TPermissionAliasTypes[] | TPermissionAliasTypes>>(
      null,
    );

  function isPermit(permissionId: string, allowNoScopes: boolean): boolean {
    return (me?.permissions ?? []).some(
      ({id, scopes}) =>
        id === permissionId && (allowNoScopes || scopes?.length),
    );
  }
  function isAdmin(permissionAlias: IPermissionAlias) {
    const allowNoScopes = isAllowNoScopes(permissionAlias);
    return permissionAlias
      ? isPermit(generateAdminRoleByAlias(permissionAlias), allowNoScopes)
      : false;
  }

  // Note we check for Alias.Admin, Alias.Read/Create/Update/Delete and just Alias
  // in the below functions
  // The "just Alias" case happens for the App.Administrate permission;
  // it does not have these "sub-permissions" like App.Administrate.Read etc.

  function isReadable(permissionAlias: IPermissionAlias): boolean {
    const allowNoScopes = isAllowNoScopes(permissionAlias);
    return permissionAlias
      ? isAdmin(permissionAlias) ||
          isPermit(generateReadRoleByAlias(permissionAlias), allowNoScopes) ||
          isPermit(permissionAlias, allowNoScopes)
      : false;
  }
  function isCreatable(permissionAlias: IPermissionAlias): boolean {
    const allowNoScopes = isAllowNoScopes(permissionAlias);
    return permissionAlias
      ? isAdmin(permissionAlias) ||
          isPermit(generateCreateRoleByAlias(permissionAlias), allowNoScopes) ||
          isPermit(permissionAlias, allowNoScopes)
      : false;
  }
  function isUpdatable(permissionAlias: IPermissionAlias): boolean {
    const allowNoScopes = isAllowNoScopes(permissionAlias);
    return permissionAlias
      ? isAdmin(permissionAlias) ||
          isPermit(generateUpdateRoleByAlias(permissionAlias), allowNoScopes) ||
          isPermit(permissionAlias, allowNoScopes)
      : false;
  }
  function isDeletable(permissionAlias: IPermissionAlias): boolean {
    const allowNoScopes = isAllowNoScopes(permissionAlias);
    return permissionAlias
      ? isAdmin(permissionAlias) ||
          isPermit(generateDeleteRoleByAlias(permissionAlias), allowNoScopes) ||
          isPermit(permissionAlias, allowNoScopes)
      : false;
  }

  function isAllowNoScopes(permissionAlias: IPermissionAlias): boolean {
    return permissionAlias === PERMISSION_ALIAS_APP_ADMIN;
  }

  const routes = {
    companyRoutes,
  };
  const value = {
    getRoutes: () => {
      return routes;
    },
    isPermit,
    isReadable,
    isCreatable,
    isUpdatable,
    isDeletable,
    pagePermissionAlias,
    setPagePermissionAlias,
  };

  return (
    <PermissionContext.Provider value={value}>
      {isLoading ? null : props.children}
    </PermissionContext.Provider>
  );
}
