const translations = {
  list: {
    actions: {
      copy: 'Copy',
      delete: 'Delete',
      usedInEdgeGatewaysDisabledDelete:
        'Asset is used in at least one edge gateway',
      usedInReservationsDisabledDelete:
        'Asset is used in at least one reservation',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      assetType: 'Type',
      disabled: 'Disabled',
      name: 'Name',
      ownerCompany: 'Owner company',
    },
    filters: {
      assetType: 'Asset type',
      ownerCompany: 'Owner company',
    },
    notifications: {
      deleteSuccess: 'Asset successfully deleted.',
    },
  },
};

export default translations;
