const translations = {
  editor: {
    actions: {
      delete: 'Delete',
    },
    buttons: {
      addStringCollectionValue: 'Add',
      newParameter: 'New parameter',
    },
    columns: {
      groupDisplayName: 'Group',
      parameterDisplayName: 'Parameter',
      value: 'Value',
    },
    fieldErrors: {
      duplicateParameterName: 'Parameters can only be specfied once',
    },
    requiredAzureResources: {
      none: 'None',
      dnaConnectDataCollection: 'DNA Connect (ADX, Event Hub)',
      apiDataCollection: 'API (ADX, Event Hub)',
      edgeGatewayDataCollection: 'Edge gateway (IoT Hub, ADX, Event Hub)',
    },
    tabs: {
      childCompanies: 'Child companies',
      general: 'General',
      parameters: 'Parameters',
    },
    titles: {
      edit: 'Company: {{displayName}}',
    },
    update: {
      success: 'Company {{companyName}} successfully saved.',
      general: {
        types: 'Types',
        vat: 'VAT',
        requiredAzureResources: 'Azure resources for data collection',
        iotHubHostName: 'IoT Hub hostname',
      },
      childCompanies: {
        addButton: 'Add child company',
        parentType: 'Parent type',
        selectParentType: 'Select parent type',
        childCompany: 'Child company',
        selectChildCompany: 'Select child company',
        childType: 'Child type',
        selectChildType: 'Select child type',
      },
      errors: {
        getCompanyFailed: 'Error in getting company.',
        lookupTypesFailed: 'Error in getting available types.',
        updateFailed: 'Error in updating company.',
        notFound: 'The company was not found in the database',
        typesRequired: 'At least one type is required',
        childCompanyParentTypeRequired: 'Parent type is required',
        childCompanyChildCompanyRequired: 'Child company is required',
        childCompanyChildTypeRequired: 'Child type is required',
        noDuplicateChildCompanies: 'Cannot add duplicate child companies',
        vatMaxLength: 'VAT cannot be longer than 20 characters',
        duplicateVat: 'Another company exists with the same VAT',
      },
    },
  },
};

export default translations;
