/* eslint-disable @typescript-eslint/camelcase */
const resources = {
  en: {
    translation: {
      logout: 'Log out',
      table: {
        actions: {
          edit: 'Edit',
          delete: 'Delete',
        },
        messages: {
          noResultsFound: 'No data available.',
        },
        multiLineText: {
          andMore: '+ {N} more',
          showMore: 'Show more',
        },
        tooltips: {
          sortAscending: 'Sort by ascending',
          sortDescending: 'Sort by descending',
          resetSort: 'Reset sort',
          columnPreferences: 'Column preferences',
          rowActions: 'Row actions',
          rowActionsNoPermissions: 'No permissions to perform actions',
          validationError: 'Given data is not valid',
        },
        values: {
          booleanTrue: 'Yes',
          booleanFalse: 'No',
        },
      },
      treeView: {
        assets: 'Assets',
        baseProducts: 'Base products',
        company: 'Company',
        deliveryDestinations: 'Delivery destinations',
        edgeGateways: 'Edge gateways',
        products: 'Products',
        sites: 'Sites',
        system: 'System',
        scopes: 'Scopes',
        apiCollections: 'API collections',
        dnaInfoServers: 'DNA Info servers',
      },
      inputs: {
        optional: 'optional',
      },
      forms: {
        cancelConfirmDialog: {
          header: 'Are you sure you want to close without saving?',
          cancel: 'Cancel',
          closeWithoutSaving: 'Close without saving',
        },
        confirmDialog: {
          no: 'No',
          yes: 'Yes',
        },
        deleteConfirmDialog: {
          header: 'Are you sure you want to delete item?',
          cancel: 'Cancel',
          apply: 'Delete',
        },
        parameterEditor: {
          actions: {
            delete: 'Delete',
          },
          buttons: {
            addStringCollectionValue: 'Add',
          },
          columns: {
            value: 'Value',
          },
          labels: {
            enabled: 'Enabled',
            newValue: 'New value',
            value: 'Value',
          },
          toggleOptions: {
            no: 'No',
            yes: 'Yes',
          },
          values: {
            emptyStringCollection: '(empty)',
          },
        },
      },
      menus: {
        multiSelect: {
          selectAll: 'Select all',
          clear: 'Clear',
          filterResults: 'Filter results',
        },
      },
      backToListView: 'Back to list view',
      errors: {
        requestFailed: 'Request failed',
        notFound: 'Not found',
      },
      fieldErrors: {
        required: 'Required',
        digitsOnly: 'Digits only',
        numericOnly: 'Numbers only',
        numericOnlyNoNegative: 'Non-negative numbers only',
        emptyString: 'Value cannot be empty',
        emptyStringCollection: 'Value cannot be empty',
        integerOnlyNoNegative: 'Non-negative digits only',
        minMax: 'Min is {{min}}, Max is {{max}}',
        minLength: 'Min length is {{length}}',
        maxLength: 'Max length is {{length}}',
        minVal: 'Min value is {{min}}',
        maxVal: 'Max value is {{max}}',
        noPast: 'Past is not valid',
        beforeStart: 'Date is before start',
        afterEnd: 'Date is after end',
        invalidDate: 'Invalid date format',
        invalidIotHubDeviceId: 'Invalid IoT Hub device ID',
        invalid32BitInt: 'Invalid numeric value',
      },
      pagination: {
        pageIndicatorTemplate: '{{start}}-{{end}} of {{results}} results',
        perPage: '/ page',
        previous: 'Previous',
        next: 'Next',
      },
      filter: {
        placeholder: 'Filter...',
        placeholderStart: 'From',
        placeholderEnd: 'To',
      },
      select: {
        open: 'Open',
        close: 'Close',
        clear: 'Clear',
        noOptions: 'No options found',
        loading: 'Loading...',
      },
      login: {
        header: 'Authentication required',
        body: 'To continue to the application, authentication is required.',
        loggingOut: 'Logging out...',
        loginInProgress: 'Login in progress...',
        login: 'Login',
        failedErrorMessage: 'Something went wrong when logging you in.',
      },
      userNotFound: {
        header: 'Unable to load user data',
        body: 'Something went wrong connecting to the server.',
      },
      accessDenied: {
        header: 'Access denied',
        body: 'Your account does not have permission to access this application.',
      },
      buttons: {
        add: 'Add',
        cancel: 'Cancel',
        update: 'Update',
        reset: 'Reset',
        save: 'Save',
      },
      tooltips: {
        delete: 'Delete',
      },
      units: {
        kg: 'kg',
      },
    },
  },
};

export default resources;
