import React from 'react';
import {useTranslation} from 'react-i18next';
import {Prompt} from 'react-router-dom';
import {Location, Action} from 'history';
import {useFormikContext} from 'formik';

interface IContext {
  isDirty: boolean;
  setDirty: (dirty: boolean) => void;
  setPathPrefix: (pathPrefix: string | null) => void;
}

const NavigationConfirmContext = React.createContext<IContext>({
  isDirty: false,
  setDirty: () => {},
  setPathPrefix: () => {},
});

export function useNavigationConfirmContext(): IContext {
  const context = React.useContext<IContext>(NavigationConfirmContext);
  if (!context) {
    throw new Error(
      `useNavigationConfirmContext must be used within a NavigationConfirmContext`,
    );
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

export function NavigationConfirmContextProvider({children}: IProvider) {
  const [dirty, setDirty] = React.useState(false);
  const pathPrefixRef = React.useRef<string | null>(null);
  const {t} = useTranslation();

  const messageFunc = React.useCallback(
    (newLocation: Location<unknown>, _: Action): string | boolean => {
      const pathPrefix = pathPrefixRef.current;
      if (pathPrefix === null) {
        // Not inside a form now
        return true;
      }

      const isWithinSameForm = newLocation.pathname.startsWith(pathPrefix);

      if (isWithinSameForm) {
        // Allow transition
        return true;
      }

      // Show confirmation dialog
      const msg = t('forms.cancelConfirmDialog.header');
      return msg;
    },
    [t],
  );

  const setPathPrefix = React.useCallback((pathPrefix: string | null) => {
    pathPrefixRef.current = pathPrefix;
  }, []);

  return (
    <>
      <Prompt when={dirty} message={messageFunc} />
      <NavigationConfirmContext.Provider
        value={{
          isDirty: dirty,
          setDirty,
          setPathPrefix,
        }}
      >
        {children}
      </NavigationConfirmContext.Provider>
    </>
  );
}

export function FormikDirtyStateUpdater() {
  const {dirty} = useFormikContext();
  const {setDirty} = useNavigationConfirmContext();

  React.useEffect(() => {
    setDirty(dirty);
    return () => {
      setDirty(false);
    };
  }, [dirty, setDirty]);

  return null;
}
