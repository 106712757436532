import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';

import UserMenu from './UserMenu';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import logoPath from '../../assets/navbar_logo.svg';
import {makeStyles} from '@material-ui/core';
import {Hidden, Button, Theme} from '@material-ui/core';
import {ValmetIcon, colors} from '@valmet-iop/ui-common';
import {getUser} from 'services/authService';
const {white, grey1} = colors;

const useStyles = makeStyles<Theme, {}>(() => ({
  root: {
    backgroundColor: grey1,
  },
  spacing: {
    flexGrow: 1,
    flexShrink: 1,
  },
}));

const TopHeader = (props: {onMenuButtonClick: () => void}) => {
  const userName = getUser()?.name ?? '';
  const classes = useStyles(props);

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar>
        {!!userName && (
          <Hidden mdUp>
            <MobileMenuButton onClick={props.onMenuButtonClick} />
          </Hidden>
        )}
        <div className={classes.spacing}></div>
        {!!userName && (
          <Hidden smDown>
            <UserMenu userName={userName} />
          </Hidden>
        )}
        <NavLogo />
      </Toolbar>
    </AppBar>
  );
};

const useLogoStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '70px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  image: {
    height: '35px',
  },
}));

const NavLogo = () => {
  const classes = useLogoStyles({});
  return (
    <div className={classes.root}>
      <img src={logoPath} alt="Valmet logo" className={classes.image} />
    </div>
  );
};

const useMobileMenuButtonStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    backgroundColor: grey1,
    color: white,
    border: `2px solid ${grey1}`,
    width: '40px',
    minWidth: '40px',
    height: '40px',
  },
}));

const MobileMenuButton = ({onClick}: {onClick: () => void}) => {
  const classes = useMobileMenuButtonStyles({});
  return (
    <Button size="large" className={classes.root} onClick={onClick}>
      <ValmetIcon icon="menu" size="medium" />
    </Button>
  );
};

export default TopHeader;
