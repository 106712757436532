import {colors} from '@valmet-iop/ui-common';
import * as React from 'react';
import {TreeView} from './TreeView';

interface ILayout {
  children: React.ReactNode;
}

export function Layout({children}: ILayout) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const {initResize, size} = useResize(ref);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: size.left + 'px 1fr',
        gridTemplateRows: '1fr',
        height: '100%',
        maxWidth: '100vw',
      }}
    >
      <div style={{background: colors.grey3, overflowY: 'auto'}}>
        <TreeView />

        <div
          ref={ref}
          onMouseDown={initResize}
          style={{
            height: '100%',
            width: '4px',
            cursor: 'ew-resize',
            position: 'absolute',
            top: '0px',
            left: size.left - 2 + 'px',
          }}
        ></div>
      </div>

      <div
        style={{
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  );
}

const useSessionStorage = (storageKey: string, defaultState: any) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey) as string) ?? defaultState,
  );

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

export const useResize = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
) => {
  const defaultSize = {
    left: 230,
  };

  const [coordinates, setCoordinates] = React.useState({
    x: Infinity,
    y: Infinity,
  });
  const [dimensions, setDimensions] = React.useState(defaultSize);
  const [size, setSize] = useSessionStorage(
    'configurator-navigation',
    defaultSize,
  );

  const initResize = (event: any) => {
    if (!ref.current) return;
    setCoordinates({x: event.clientX, y: event.clientY});
    const {left} = window.getComputedStyle(ref.current);
    setDimensions({left: parseInt(left, 10)});
  };

  React.useEffect(() => {
    const getValue = (input: number) => {
      if (input < 10) return 10;
      if (input > 600) return 600;
      return input;
    };

    const doDrag = (event: any) => {
      if (!ref.current) return;
      const left = getValue(dimensions.left + event.clientX - coordinates.x);
      if (left) {
        setSize({left});
      }
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', doDrag, false);
      document.removeEventListener('mouseup', stopDrag, false);
    };

    if (coordinates.x !== Infinity) {
      // eslint-disable-next-line scanjs-rules/call_addEventListener
      document.addEventListener('mousemove', doDrag, false);
      // eslint-disable-next-line scanjs-rules/call_addEventListener
      document.addEventListener('mouseup', stopDrag, false);
    }
  }, [dimensions, coordinates, ref, setSize]);

  return {initResize, size};
};
