const translations = {
  editor: {
    actions: {
      delete: 'Delete',
      disabledDeleteUsedInReservations:
        'Equipment is used in at least one reservation',
      disabledDeleteHasTags: 'Equipment has one or more tags',
    },
    buttons: {
      addStringCollectionValue: 'Add',
      newEquipment: 'New equipment',
      newParameter: 'New parameter',
      newRestriction: 'New restriction',
    },
    columns: {
      canProvide: 'Can provide',
      canReceive: 'Can receive',
      child: 'Child',
      code: 'Code',
      companyDisplayName: 'Company',
      description: 'Description',
      displayName: 'Name',
      groupDisplayName: 'Group',
      isDisabled: 'Disabled',
      isIncluded: 'Included',
      parameterDisplayName: 'Parameter',
      product: 'Product',
      scope: 'Scope',
      site: 'Site',
      type: 'Type',
      value: 'Value',
    },
    equipmentTypes: {
      Bay: 'Bay',
      Tank: 'Tank',
      Lot: 'Lot',
    },
    dayOfWeek: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
    },
    fieldErrors: {
      duplicateEquipmentName: 'Another equipment has the same name',
      duplicateParameterName:
        'This company - parameter combo exists multiple times',
      duplicateEquipmentParameterName:
        'This equipment parameter exists multiple times',
      locationBothCoordinatesRequired:
        'Both latitude and longitude must be specified',
      invalidTime: 'Format must be HH:mm',
    },
    helpTexts: {
      parameterCompany:
        "In some exceptional cases, a company different from the site's owner is specified.",
      reservationRestriction:
        'Reservations are not allowed during these time ranges.',
    },
    labels: {
      city: 'City',
      code: 'Site code',
      company: 'Company',
      country: 'Country',
      defaultLoadingTimeIntervalMinutes:
        'Default loading time interval (minutes)',
      defaultUnloadingTimeIntervalMinutes:
        'Default unloading time interval (minutes)',
      minimumLoadingTimeIntervalMinutes:
        'Minimum loading time interval (minutes)',
      minimumUnloadingTimeIntervalMinutes:
        'Minimum unloading time interval (minutes)',
      displayName: 'Name',
      enabled: 'Enabled',
      isDisabled: 'Disabled',
      latitude: 'Latitude',
      longitude: 'Longitude',
      logisticsOperatorCodesVisibleForReservations:
        'Logistics operator codes visible for reservations',
      parameter: 'Parameter',
      parameterDefaultValue: 'Default value',
      phoneNumber: 'Phone',
      postalCode: 'Postal code',
      stateOrRegion: 'State/region',
      street: 'Street',
      timeZone: 'Timezone',
      type: 'Type',
      value: 'Value',
      startDayOfWeek: 'Start day',
      startTime: 'Start time',
      endDayOfWeek: 'End day',
      endTime: 'End time',
    },
    notifications: {
      createSuccess: 'Site successfully created.',
      updateSuccess: 'Site successfully updated.',
    },
    tabs: {
      childSites: 'Child sites',
      equipment: 'Equipment',
      general: 'General',
      new: 'New site',
      parameters: 'Parameters',
      products: 'Products',
      scheduling: 'Scheduling',
      scopes: 'Scopes',
    },
    titles: {
      create: 'New site: {{displayName}}',
      edit: 'Site: {{displayName}}',
    },
  },
};

export default translations;
