const translations = {
  list: {
    actions: {
      delete: 'Delete',
      disabledDelete: 'Tags must be removed before deleting DNA Info server',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      name: 'Name',
    },
    notifications: {
      deleteSuccess: 'DNA Info server successfully deleted.',
    },
  },
  tabs: {
    general: 'General',
    tags: 'Tags',
  },
};

export default translations;
