import 'react-toastify/dist/ReactToastify.css';
import {Progress} from 'components/Common/Progress';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import {ReactQueryDevtools} from 'react-query/devtools';
import {getIsAppInitialized} from '../../redux/reducers';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {IState, ToastContainer} from '@valmet-iop/ui-common';
import TopHeader from './TopHeader';
import {useMediaQuery} from '@material-ui/core';

import {PermissionProvider} from 'context/permission-context';
import {RoutesPermission} from 'components/Pages/Permission';
import {UserInformationOutputDTO} from 'api/generated/iop';
import {useUsersMe} from 'components/Hooks/useUsersMe';
import {BrowserRouter as Router} from 'react-router-dom';
import {TreeViewProvider} from 'components/context/tree-view-context';
import {CompaniesBrowserProvider} from 'components/context/companies-browser-context';
import {NavigationConfirmContextProvider} from 'components/Common/FormikNavigationConfirmation/FormikNavigationConfirmation';
import {PageTitleContextProvider} from 'components/context/page-title-context';
import {TableStateProvider} from 'components/context/tableState-context';

const useStyles = makeStyles<Theme, {}>(theme => ({
  '@global': {
    body: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },
  sideNavContentContainer: {
    display: 'flex',
    height: 'calc(100vh - 70px)',
  },
  contentContainer: {
    position: 'relative',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 70px)',
    '& > .MuiCard-root > .MuiCardContent-root': {
      padding: theme.spacing(8, 4, 4, 4),
    },
  },
}));

interface AppContextModel {
  adminOrganizationId: string;
  user: UserInformationOutputDTO | null;
  userInfoLoadStatus: 'NOT_STARTED' | 'LOADING' | 'LOADED' | 'LOAD_FAILED';
  userInfoLoadError: unknown;
  refreshUserInfo: () => void;
}

export const AppContext = React.createContext<AppContextModel>({
  adminOrganizationId: '7fd0d737-057e-4f98-b2df-f3a0bfe071e1',
  user: null,
  userInfoLoadStatus: 'NOT_STARTED',
  userInfoLoadError: undefined,
  refreshUserInfo: () => undefined,
});
const Layout = (props: {}) => {
  const classes = useStyles(props);
  const applicationReady = useSelector<IState, boolean>(
    getIsAppInitialized,
    shallowEqual,
  );

  const {
    data: userInfo = {
      organizationId: '',
      organizationName: '',
      userIsAdmin: false,
      userDisplayName: '',
      userEmail: '',
      userId: '',
    },
    refetch: getUserInfo,
    isSuccess: isUserInfoAvailable,
    isLoading: isUserInfoLoading,
    isError: isUserInfoError,
    error: userInfoLoadError,
  } = useUsersMe();

  // On mobile the side nav is hidden by default, on desktop visible by default
  // We track these separately and use a media query hook to decide which one to use and update
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
  const [desktopNavExpanded, setDesktopNavExpanded] = useState(true);
  const onSideNavExpandToggle = () => {
    if (isMobile) {
      setMobileNavExpanded(!mobileNavExpanded);
    } else {
      setDesktopNavExpanded(!desktopNavExpanded);
    }
  };
  // const sideNavExpanded = isMobile ? mobileNavExpanded : desktopNavExpanded;

  useEffect(() => {
    document.title = 'Valmet IOP Configurator';
  }, []);

  return (
    <AppContext.Provider
      value={{
        adminOrganizationId: '7fd0d737-057e-4f98-b2df-f3a0bfe071e1',
        user: isUserInfoAvailable ? userInfo : null,
        userInfoLoadStatus: !!userInfo.organizationId
          ? 'LOADED'
          : isUserInfoLoading
          ? 'LOADING'
          : isUserInfoError
          ? 'LOAD_FAILED'
          : 'NOT_STARTED',
        userInfoLoadError,
        refreshUserInfo: getUserInfo,
      }}
    >
      <ToastContainer />

      <TopHeader onMenuButtonClick={onSideNavExpandToggle} />
      <PermissionProvider>
        <div className={classes.sideNavContentContainer}>
          <Router>
            {applicationReady && (
              <React.Suspense fallback={<Progress />}>
                <PageTitleContextProvider>
                  <CompaniesBrowserProvider>
                    <TableStateProvider>
                      <TreeViewProvider>
                        <NavigationConfirmContextProvider>
                          <RoutesPermission
                            className={classes.contentContainer}
                          />
                        </NavigationConfirmContextProvider>
                      </TreeViewProvider>
                    </TableStateProvider>
                  </CompaniesBrowserProvider>
                </PageTitleContextProvider>
              </React.Suspense>
            )}
          </Router>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </PermissionProvider>
    </AppContext.Provider>
  );
};

export default Layout;
