import React from 'react';
import {useLocation} from 'react-router-dom';
import {useCompaniesOptions} from 'components/Hooks/useCompanies';
import {CompanyOutputDTO} from 'api/generated/iop';
import {useNavigate} from 'components/Hooks/useNavigate';
import {companyIsProvider} from 'utils/CompanyType';
import {
  generateCompanyIdAssetsPath,
  generateCompanyIdProductsPath,
} from 'components/Pages/Company/routes';

export interface IContext {
  activeCompanyId: string | null;
  activeCompany: CompanyOutputDTO | null;
  setActiveCompanyId: (companyId: string) => void;
}

export function useCompaniesBrowser(): IContext {
  const context = React.useContext<IContext>(CompaniesBrowser);
  if (!context) {
    throw new Error(
      `useCompaniesBrowser must be used within a CompaniesBrowser`,
    );
  }
  return context;
}

const CompaniesBrowser = React.createContext<IContext>({
  activeCompanyId: null,
  activeCompany: null,
  setActiveCompanyId: () => {},
});

function parseCompanyIdFromPath(path: string): string | null {
  if (!path.startsWith('/company/')) {
    return null;
  }

  // Assume second segment is the company id
  const segments = path.split('/');
  //  /company/company-id/something/more
  //0     1        2          3      4
  if (segments.length < 3) {
    return null;
  }

  return segments[2];
}

function useProps(): IContext {
  const location = useLocation();
  const path = location.pathname;
  const activeCompanyId = React.useMemo(() => {
    return parseCompanyIdFromPath(path);
  }, [path]);
  const {data: companyOptions} = useCompaniesOptions();
  const activeCompany = React.useMemo(() => {
    if (activeCompanyId === null) {
      return null;
    }

    const company = companyOptions?.find(c => c.companyId === activeCompanyId);
    if (company === undefined) {
      return null;
    }

    return company;
  }, [activeCompanyId, companyOptions]);

  const navigate = useNavigate();

  const setActiveCompanyIdProp = React.useCallback(
    (companyId: string) => {
      const company = companyOptions?.find(c => c.companyId === companyId);
      if (!company) {
        return;
      }
      navigate(
        companyIsProvider(company)
          ? generateCompanyIdProductsPath(company.companyId)
          : generateCompanyIdAssetsPath(company.companyId),
      );
      setCompanyIdToStorage(companyId);
    },
    [companyOptions, navigate],
  );

  return {
    activeCompanyId,
    activeCompany,
    setActiveCompanyId: setActiveCompanyIdProp,
  };
}
export interface IProvider {
  children: React.ReactNode;
}
export function CompaniesBrowserProvider(props: IProvider) {
  const location = useLocation();
  const path = location.pathname;

  const {data: companyOptions} = useCompaniesOptions();
  const {activeCompanyId, activeCompany, setActiveCompanyId} = useProps();
  React.useEffect(() => {
    if (!companyOptions || companyOptions.length === 0) {
      return;
    }

    const companyIdFromPath = parseCompanyIdFromPath(path);
    const companyOptionFromPath =
      companyIdFromPath !== null
        ? companyOptions.find(o => o.companyId === companyIdFromPath)
        : undefined;

    if (companyOptionFromPath !== undefined) {
      // We have active company from URL, done
      return;
    }

    const companyIdFromStorage = getCompanyIdFromStorage();
    const companyOptionFromStorage =
      companyIdFromStorage !== null
        ? companyOptions.find(o => o.companyId === companyIdFromStorage)
        : undefined;

    // Priority order: URL -> Storage -> First option
    const defaultOption =
      companyOptionFromPath ?? companyOptionFromStorage ?? companyOptions[0];

    setActiveCompanyId(defaultOption.companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyOptions]);

  const value: IContext = {
    activeCompanyId,
    activeCompany,
    setActiveCompanyId,
  };

  return <CompaniesBrowser.Provider value={value} {...props} />;
}

const CompanyIdStorageKey = 'LastSelectedCompanyId';

function getCompanyIdFromStorage() {
  return window.localStorage.getItem(CompanyIdStorageKey);
}

function setCompanyIdToStorage(companyId: string) {
  window.localStorage.setItem(CompanyIdStorageKey, companyId);
}

export function useActiveCompanyIsProvider() {
  const {activeCompany} = useCompaniesBrowser();
  return companyIsProvider(activeCompany);
}
