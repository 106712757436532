import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

export function useNavigate() {
  const history = useHistory();
  const navigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );
  return navigate;
}
