const translations = {
  list: {
    actions: {
      copy: 'Copy',
      delete: 'Delete',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      address: 'Address',
      code: 'Code',
      customerCompany: 'Customer company',
      disabled: 'Disabled',
      name: 'Name',
    },
    filters: {
      customerCompany: 'Customer company',
    },
    notifications: {
      deleteSuccess: 'Delivery destination successfully deleted.',
    },
  },
};

export default translations;
