import React from 'react';
import {TServerSideState} from '@valmet-iop/ui-common';
import {SiteType} from 'api/generated/iop';
import {useCompaniesBrowser} from 'components/context/companies-browser-context';

export enum ListType {
  SITE = 'SITE',
  PRODUCT = 'PRODUCT',
  ASSET = 'ASSET',
  DELIVERYDESTINATION = 'DELIVERYDESTINATION',
  SCOPE = 'SCOPE',
  EDGEGATEWAY = 'EDGEGATEWAY',
  DATASET = 'DATASET',
  DNAINFOSERVER = 'DNAINFOSERVER',
}
interface IContext {
  tableState: TServerSideState<any>;
  pageActiveCompany: string | null;
  pageType: ListType;
  tableFilters: IFilters;
  onTableStateChange: (state: TServerSideState<any>) => void;
  onPageTypeChange: (pageType: ListType) => void;
  onPageActiveCompanyChange: (activeCompanyId: string | null) => void;
  onTableFiltersChange: (filters: IFilters) => void;
}
const TableStateContext = React.createContext<IContext | null>(null);

export function useTableState(): IContext {
  const context = React.useContext<IContext>(
    TableStateContext as React.Context<IContext>,
  );
  if (!context) {
    throw new Error(`useTableState must be used within a TableStateProvider`);
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

export interface ITableState {
  state: TServerSideState<any>;
  filters: IFilters;
  onStateChange: (state: TServerSideState<any>) => void;
  onFiltersChange: (filters: IFilters) => void;
}

export interface IFilters {
  searchText: string;
  // Asset
  assetType?: string | null;
  ownerCompanyId?: string | null;
  // Delivery company
  customerCompanyId?: string | null;
  // Product
  baseProductId?: string | null;
  measurementUnitId?: string | null;
  // Site
  type?: SiteType;
  country?: string | null;
}

export const resetTableState: TServerSideState<any> = {
  sortBy: [],
  columnOrder: [],
  filters: [],
  globalFilter: [],
  pageIndex: 0,
  pageSize: 10,
  selectedRowIds: {},
  expanded: {},
};

export const resetTableFilters: IFilters = {
  searchText: '',
};

const PAGE_TYPE_CHANGE = 'PAGE_TYPE_CHANGE';
const PAGE_ACTIVE_COMPANY_CHANGE = 'PAGE_ACTIVE_COMPANY_CHANGE';
const TABLE_STATE_CHANGE = 'TABLE_STATE_CHANGE';
const TABLE_FILTERS_CHANGE = 'TABLE_FILTERS_CHANGE';

interface IPageTypeChangeAction {
  type: typeof PAGE_TYPE_CHANGE;
  payload: ListType;
}
interface IPageActiveCompanyAction {
  type: typeof PAGE_ACTIVE_COMPANY_CHANGE;
  payload: string | null;
}
interface ITableStateAction {
  type: typeof TABLE_STATE_CHANGE;
  payload: TServerSideState<any>;
}
interface ITableFiltersAction {
  type: typeof TABLE_FILTERS_CHANGE;
  payload: IFilters;
}
type TAction =
  | IPageTypeChangeAction
  | IPageActiveCompanyAction
  | ITableStateAction
  | ITableFiltersAction;
interface IState {
  pageType: ListType;
  pageActiveCompany: string | null;
  tableState: TServerSideState<any>;
  tableFilters: IFilters;
}

function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case PAGE_TYPE_CHANGE:
      if (state.pageType !== action.payload) {
        return {
          ...state,
          pageType: action.payload,
          tableState: resetTableState,
          tableFilters: resetTableFilters,
        };
      }
      return state;
    case PAGE_ACTIVE_COMPANY_CHANGE:
      if (state.pageActiveCompany !== action.payload) {
        return {
          ...state,
          pageActiveCompany: action.payload,
          tableState: resetTableState,
          tableFilters: resetTableFilters,
        };
      }
      return state;
    case TABLE_STATE_CHANGE:
      return {
        ...state,
        tableState: action.payload,
      };
    case TABLE_FILTERS_CHANGE:
      return {
        ...state,
        tableState: {...state.tableState, pageIndex: 0},
        tableFilters: action.payload,
      };
    default:
      return state;
  }
}

export function TableStateProvider(props: IProvider) {
  const {activeCompany} = useCompaniesBrowser();

  const [{pageType, pageActiveCompany, tableState, tableFilters}, dispatch] =
    React.useReducer(reducer, {
      pageType: ListType.PRODUCT,
      pageActiveCompany: null,
      tableState: resetTableState,
      tableFilters: resetTableFilters,
    });

  React.useEffect(() => {
    dispatch({
      type: PAGE_ACTIVE_COMPANY_CHANGE,
      payload: activeCompany?.companyId || null,
    });
  }, [activeCompany]);

  const value = React.useMemo(() => {
    return {
      pageType,
      pageActiveCompany,
      tableState,
      tableFilters,
      onPageTypeChange: (pageType: ListType) => {
        dispatch({type: PAGE_TYPE_CHANGE, payload: pageType});
      },
      onPageActiveCompanyChange: (pageActiveCompany: string | null) => {
        dispatch({
          type: PAGE_ACTIVE_COMPANY_CHANGE,
          payload: pageActiveCompany,
        });
      },
      onTableStateChange: (state: TServerSideState<any>) => {
        dispatch({type: TABLE_STATE_CHANGE, payload: state});
      },
      onTableFiltersChange: (filters: IFilters) => {
        dispatch({type: TABLE_FILTERS_CHANGE, payload: filters});
      },
    };
  }, [pageType, pageActiveCompany, tableState, tableFilters]);

  return (
    <TableStateContext.Provider value={value}>
      {props.children}
    </TableStateContext.Provider>
  );
}

export const useListTableState = (
  type: ListType,
): {listTableState: ITableState; contextIsSync: boolean} => {
  const {activeCompanyId} = useCompaniesBrowser();

  const {
    tableState,
    tableFilters,
    pageActiveCompany,
    pageType,
    onTableStateChange,
    onTableFiltersChange,
    onPageTypeChange,
  } = useTableState();

  React.useEffect(() => {
    onPageTypeChange(type);
  }, [onPageTypeChange, type]);

  const listTableState: ITableState = {
    state: tableState,
    filters: tableFilters,
    onStateChange: onTableStateChange,
    onFiltersChange: onTableFiltersChange,
  };

  const contextIsSync = React.useMemo(() => {
    return pageActiveCompany === activeCompanyId && pageType === type;
  }, [pageActiveCompany, activeCompanyId, pageType, type]);

  return {listTableState, contextIsSync};
};
