const translations = {
  list: {
    actions: {
      copy: 'Copy',
      delete: 'Delete',
      disabledDelete: 'Edge gateway has one or more OPC UA servers',
      disabledMassImport: 'Edge gateway is not linked to a site',
      massImport: 'Mass import OPC UA tags',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      asset: 'Asset',
      name: 'Name',
      site: 'Site',
      state: 'State',
      disabled: 'Disabled',
    },
    notifications: {
      deleteSuccess: 'Edge gateway successfully deleted.',
    },
    tooltips: {
      gatewayDisconnectedWithUnknownTimestamp:
        'Telemetry has not been received from the gateway',
      gatewayDisconnectedWithKnownTimestamp:
        'Telemetry last received on {{ts, datetime}}',
    },
  },
};

export default translations;
