const translations = {
  editor: {
    columns: {
      description: 'Description',
      displayName: 'Name',
      isIncluded: 'Included',
    },
    labels: {
      description: 'Description',
      displayName: 'Name',
      include: 'Include',
    },
    notifications: {
      createSuccess: 'Scope successfully created.',
      updateSuccess: 'Scope successfully updated.',
    },
    siteSelectionTypes: {
      AllSites: 'All sites',
      None: 'None',
      SelectedSites: 'Selected sites',
      SelectedTypes: 'Selected types',
    },
    siteTypes: {
      CustomerTerminal: 'Customer terminal',
      Depot: 'Depot',
      DistributionTerminal: 'Distribution terminal',
      FuelingStation: 'Fueling station',
      LoadingStation: 'Loading station',
    },
    tabs: {
      general: 'General',
      sites: 'Sites',
    },
    titles: {
      create: 'New scope: {{displayName}}',
      edit: 'Scope: {{displayName}}',
    },
  },
};

export default translations;
