const translations = {
  editor: {
    columns: {
      allowed: 'Allow',
      code: 'Code',
      product: 'Product',
    },
    labels: {
      allowAllProducts: 'Allow all products',
      displayName: 'Name',
      isDisabled: 'Disabled',
      ownerCompany: 'Owner company',
      provider: 'Provider company',
      type: 'Type',
    },
    notifications: {
      createSuccess: 'Asset successfully created.',
      updateSuccess: 'Asset successfully updated.',
    },
    titles: {
      create: 'New asset: {{displayName}}',
      edit: 'Asset: {{displayName}}',
    },
    tabs: {
      general: 'General',
      parameters: 'Parameters',
      products: 'Products',
      tags: 'Tags',
      tracking: 'Tracking',
    },
  },
};

export default translations;
