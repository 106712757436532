const translations = {
  list: {
    actions: {
      delete: 'Delete',
    },
    buttons: {
      new: 'New',
    },
    columns: {
      name: 'Name',
      description: 'Description',
    },
    notifications: {
      deleteSuccess: 'Scope successfully deleted.',
    },
  },
};

export default translations;
