import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import resources from './translations';
import {add as addFeatures} from '../components/Pages/Company/i18n';

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en-US',
  lng: 'en-US',
  ns: ['translation'],
  defaultNS: 'translation',
  preload: ['en-US'],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

// Add feature-specific translations
addFeatures(i18n);

export default i18n;
