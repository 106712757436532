import {Switch, Route, Redirect} from 'react-router-dom';
import PrivateRoute from 'components/Common/PrivateRoute';
import {usePermission} from 'context/permission-context';
import * as React from 'react';
import {IRouterMap} from 'utils/router';
import Home from '.';
import AadCallback from './AadCallback';
import Login from './Login';

import {Layout} from './Layout';

export function RoutesPermission({className}: {className: string}) {
  const {getRoutes} = usePermission();
  return (
    <div className={className}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/aad-callback">
          <AadCallback />
        </Route>
        <Route exact path="/">
          <PrivateRoute comp={Home} />
        </Route>
        {[
          ...[...Object.values(getRoutes())].map((route: IRouterMap[]) => {
            return route.map(({comp, path, mode}: IRouterMap) => {
              return (
                <Route exact path={path} key={path}>
                  <Layout>
                    <PrivateRoute comp={comp} mode={mode} />
                  </Layout>
                </Route>
              );
            });
          }),
        ]}
        <Redirect to="/" />
      </Switch>
    </div>
  );
}
