import editorEn from '../Editor/i18n.en';
import listEn from '../List/i18n.en';
import sharedEn from '../shared/i18n.en';
import massImportTagsEn from '../MassImportTags/i18n.en';

const translations = {
  ...editorEn,
  ...listEn,
  ...sharedEn,
  ...massImportTagsEn,
};

export default translations;
