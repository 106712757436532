import {CompanyOutputDTO} from 'api/generated/iop';

enum CompanyType {
  AlternativeFuelsProvider = '654157c3-3846-4f80-b3b7-f094fbc9b079',
  AlternativeFuelsCustomer = '80a40e5a-06cf-40de-81c6-c758293869b7',
  LogisticsOperator = '68ceee88-8f30-48d6-8301-ca071006efe7',
}

export const companyIsCustomer = (
  company: CompanyOutputDTO | null | undefined,
) =>
  !!company?.types.find(
    type => type.companyTypeId === CompanyType.AlternativeFuelsCustomer,
  );

export const companyIsLogisticsOperator = (
  company: CompanyOutputDTO | null | undefined,
) =>
  !!company?.types.find(
    type => type.companyTypeId === CompanyType.LogisticsOperator,
  );

export const companyIsProvider = (
  company: CompanyOutputDTO | null | undefined,
) =>
  !!company?.types.find(
    type => type.companyTypeId === CompanyType.AlternativeFuelsProvider,
  );

export default CompanyType;
