const translations = {
  editor: {
    columns: {
      allow: 'Allow',
      canProvide: 'Can provide',
      canReceive: 'Can receive',
      customer: 'Customer',
      defaultProduct: 'Default product',
      equipment: 'Equipment',
      logisticsOperator: 'Logistics operator',
      site: 'Site',
    },
    labels: {
      allowAllCustomers: 'Allow all customers',
      allowAllLogisticsOperators: 'Allow all logistics operators',
      baseProduct: 'Base product',
      baseProductCode: 'Base product code',
      baseProductMeasurementUnit: 'Base product measurement unit',
      code: 'Code',
      density: 'Density',
      displayName: 'Name',
      isDisabled: 'Disabled',
      measurementUnit: 'Measurement unit',
    },
    titles: {
      create: 'New product: {{displayName}}',
      edit: 'Product: {{displayName}}',
    },
    notifications: {
      createSuccess: 'Product successfully created.',
      updateSuccess: 'Product successfully updated.',
    },
    tabs: {
      assets: 'Assets',
      customers: 'Customers',
      equipment: 'Equipment',
      general: 'General',
      logisticsOperators: 'Logistics operators',
      new: 'New product',
    },
  },
};

export default translations;
