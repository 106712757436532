import withInitialization from './components/HOC/withInitialization';
import withProvider from './components/HOC/withProvider';
import withReactQueryClient from './components/HOC/withReactQueryClient';
import {withTheming} from '@valmet-iop/ui-common';
import Root from './components/Layout/';
import './i18n';
import './validation';

const App = withProvider(
  withInitialization(withTheming(withReactQueryClient(Root))),
);

export default App;
