import React from 'react';
import {matchPath} from 'react-router-dom';
import CompanyType from 'utils/CompanyType';
import {generatePathByPlaceholderId, IRouterMap} from '../utils/router';
import {enumKeys} from 'utils/enums';

const CompanyEditorPage = React.lazy(
  () => import('./Company/Editor/CompanyEditor'),
);

const ProductListPage = React.lazy(
  () => import('./Products/List/ListProducts'),
);
const ProductEditorPage = React.lazy(
  () => import('./Products/Editor/ProductEditor'),
);

const SiteListPage = React.lazy(() => import('./Sites/List/ListSites'));
const SiteEditorPage = React.lazy(() => import('./Sites/Editor/SiteEditor'));

const AssetListPage = React.lazy(() => import('./Assets/List/ListAssets'));
const AssetEditorPage = React.lazy(() => import('./Assets/Editor/AssetEditor'));

const DeliveryDestinationListPage = React.lazy(
  () => import('./DeliveryDestinations/List/ListDeliveryDestinations'),
);
const DeliveryDestinationEditorPage = React.lazy(
  () => import('./DeliveryDestinations/Editor/DeliveryDestinationEditor'),
);

const ScopeListPage = React.lazy(() => import('./Scopes/List/ListScopes'));
const ScopeEditorPage = React.lazy(() => import('./Scopes/Editor/ScopeEditor'));

const EdgeGatewayListPage = React.lazy(
  () => import('./EdgeGateways/List/ListEdgeGateways'),
);
const EdgeGatewayEditorPage = React.lazy(
  () => import('./EdgeGateways/Editor/EdgeGatewayEditor'),
);
const EdgeGatewayMassImportTagsPage = React.lazy(
  () => import('./EdgeGateways/MassImportTags/MassImportTagsForm'),
);

const ApiCollectionListPage = React.lazy(
  () => import('./ApiCollections/List/ListApiCollections'),
);
const ApiCollectionEditorPage = React.lazy(
  () => import('./ApiCollections/Editor/ApiCollectionEditor'),
);

const DnaInfoServerListPage = React.lazy(
  () => import('./DnaInfoServers/List/ListDnaInfoServers'),
);
const DnaInfoServerEditorPage = React.lazy(
  () => import('./DnaInfoServers/Editor/DnaInfoServerEditor'),
);

const ROUTE_COMPANYID = `/company/:companyId`;
export const ROUTE_COMPANYID_EDIT_WILDCARD = `${ROUTE_COMPANYID}/edit/*`;
export const ROUTE_COMPANYID_EDIT_GENERAL = `${ROUTE_COMPANYID}/edit/general`;
export const ROUTE_COMPANYID_EDIT_CHILD_COMPANIES = `${ROUTE_COMPANYID}/edit/child-companies`;
export const ROUTE_COMPANYID_EDIT_PARAMETERS = `${ROUTE_COMPANYID}/edit/parameters`;

const ROUTE_COMPANYID_ASSETS = `${ROUTE_COMPANYID}/assets`;
export const ROUTE_COMPANYID_ASSETID_WILDCARD = `${ROUTE_COMPANYID_ASSETS}/:assetId/*`;
export const ROUTE_COMPANYID_ASSETID_GENERAL = `${ROUTE_COMPANYID_ASSETS}/:assetId/general`;
export const ROUTE_COMPANYID_ASSETID_PRODUCTS = `${ROUTE_COMPANYID_ASSETS}/:assetId/products`;

const ROUTE_COMPANYID_SITES = `${ROUTE_COMPANYID}/sites`;
export const ROUTE_COMPANYID_SITEID_WILDCARD = `${ROUTE_COMPANYID_SITES}/:siteId/*`;
export const ROUTE_COMPANYID_SITEID_GENERAL = `${ROUTE_COMPANYID_SITES}/:siteId/general`;
export const ROUTE_COMPANYID_SITEID_SCOPES = `${ROUTE_COMPANYID_SITES}/:siteId/scopes`;
export const ROUTE_COMPANYID_SITEID_EQUIPMENT = `${ROUTE_COMPANYID_SITES}/:siteId/equipment`;
export const ROUTE_COMPANYID_SITEID_CHILDSITES = `${ROUTE_COMPANYID_SITES}/:siteId/childSites`;
export const ROUTE_COMPANYID_SITEID_TAGS = `${ROUTE_COMPANYID_SITES}/:siteId/tags`;
export const ROUTE_COMPANYID_SITEID_PARAMETERS = `${ROUTE_COMPANYID_SITES}/:siteId/parameters`;
export const ROUTE_COMPANYID_SITEID_SCHEDULING = `${ROUTE_COMPANYID_SITES}/:siteId/scheduling`;

const ROUTE_COMPANYID_PRODUCTS = `${ROUTE_COMPANYID}/products`;
export const ROUTE_COMPANYID_PRODUCTID_WILDCARD = `${ROUTE_COMPANYID_PRODUCTS}/:productId/*`;
export const ROUTE_COMPANYID_PRODUCTID_GENERAL = `${ROUTE_COMPANYID_PRODUCTS}/:productId/general`;
export const ROUTE_COMPANYID_PRODUCTID_EQUIPMENT = `${ROUTE_COMPANYID_PRODUCTS}/:productId/equipment`;
export const ROUTE_COMPANYID_PRODUCTID_CUSTOMERS = `${ROUTE_COMPANYID_PRODUCTS}/:productId/customers`;
export const ROUTE_COMPANYID_PRODUCTID_LOGISTIC_COMPANIES = `${ROUTE_COMPANYID_PRODUCTS}/:productId/logistic-companies`;
export const ROUTE_COMPANYID_PRODUCTID_LOGISTIC_ASSETS = `${ROUTE_COMPANYID_PRODUCTS}/:productId/assets`;

const ROUTE_COMPANYID_DELIVERY_DESTINATIONS = `${ROUTE_COMPANYID}/deliveryDestinations`;
export const ROUTE_COMPANYID_DELIVERY_DESTINATIONID_WILDCARD = `${ROUTE_COMPANYID_DELIVERY_DESTINATIONS}/:deliveryDestinationId/*`;
export const ROUTE_COMPANYID_DELIVERY_DESTINATIONID_GENERAL = `${ROUTE_COMPANYID_DELIVERY_DESTINATIONS}/:deliveryDestinationId/general`;

const ROUTE_COMPANYID_EDGE_GATEWAYS = `${ROUTE_COMPANYID}/edgeGateways`;
export const ROUTE_COMPANYID_EDGE_GATEWAYID_WILDCARD = `${ROUTE_COMPANYID_EDGE_GATEWAYS}/:edgeGatewayId/*`;
export const ROUTE_COMPANYID_EDGE_GATEWAYID_GENERAL = `${ROUTE_COMPANYID_EDGE_GATEWAYS}/:edgeGatewayId/general`;
export const ROUTE_COMPANYID_EDGE_GATEWAYID_OPCUA_SERVERS = `${ROUTE_COMPANYID_EDGE_GATEWAYS}/:edgeGatewayId/opcUaServers`;
export const ROUTE_COMPANYID_EDGE_GATEWAY_MASS_IMPORT_TAGS = `${ROUTE_COMPANYID_EDGE_GATEWAYS}/massImportTags/:edgeGatewayId`;

const ROUTE_COMPANYID_SCOPES = `${ROUTE_COMPANYID}/scopes`;
export const ROUTE_COMPANYID_SCOPEID_GENERAL = `${ROUTE_COMPANYID_SCOPES}/:scopeId/general`;
export const ROUTE_COMPANYID_SCOPEID_SITE = `${ROUTE_COMPANYID_SCOPES}/:scopeId/sites`;
export const ROUTE_COMPANYID_SCOPEID_WILDCARD = `${ROUTE_COMPANYID_SCOPES}/:scopeId/*`;

const ROUTE_COMPANYID_DATASETS = `${ROUTE_COMPANYID}/datasets`;
export const ROUTE_COMPANYID_DATASETS_WILDCARD = `${ROUTE_COMPANYID_DATASETS}/:apiCollectionId/*`;
export const ROUTE_COMPANYID_DATASETID_GENERAL = `${ROUTE_COMPANYID_DATASETS}/:apiCollectionId/general`;
export const ROUTE_COMPANYID_DATASETID_TAGS = `${ROUTE_COMPANYID_DATASETS}/:apiCollectionId/tags`;

const ROUTE_COMPANYID_DNA_INFO_SERVERS = `${ROUTE_COMPANYID}/dnainfoservers`;
export const ROUTE_COMPANYID_DNA_INFO_SERVERID_WILDCARD = `${ROUTE_COMPANYID_DNA_INFO_SERVERS}/:dnaInfoServerId/*`;
export const ROUTE_COMPANYID_DNA_INFO_SERVERID_GENERAL = `${ROUTE_COMPANYID_DNA_INFO_SERVERS}/:dnaInfoServerId/general`;
export const ROUTE_COMPANYID_DNA_INFO_SERVERID_TAGS = `${ROUTE_COMPANYID_DNA_INFO_SERVERS}/:dnaInfoServerId/tags`;

export interface IParams {
  companyId: string;
  siteId: string;
  productId: string;
  assetId: string;
  deliveryDestinationId: string;
  edgeGatewayId: string;
  scopeId: string;
  apiCollectionId: string;
  dnaInfoServerId: string;
}
enum Placeholder {
  companyId = ':companyId',
  siteId = ':siteId',
  productId = ':productId',
  assetId = ':assetId',
  deliveryDestinationId = ':deliveryDestinationId',
  edgeGatewayId = ':edgeGatewayId',
  scopeId = ':scopeId',
  apiCollectionId = ':apiCollectionId',
  dnaInfoServerId = ':dnaInfoServerId',
}

/**
 * Generates a path from a template + available parameters.
 * @param path Path template with placeholders
 * @param params Values to use to replace placeholders
 * @returns Path with placeholders replaced by values
 */
export const generatePath = (path: string, params: IParams) => {
  enumKeys(Placeholder).forEach(placeholder => {
    if (!!params[placeholder])
      path = generatePathByPlaceholderId<Placeholder>({
        path,
        id: params[placeholder],
        placeholder: Placeholder[placeholder],
      });
  });

  return path;
};

export const generateCompanyGeneralPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_EDIT_GENERAL,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyChildCompaniesPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_EDIT_CHILD_COMPANIES,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyParametersPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_EDIT_PARAMETERS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyPathPrefix = (id: string): string => {
  const wildcardPath = generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_EDIT_WILDCARD,
    id,
    placeholder: Placeholder.companyId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdSitesPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_SITES,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyIdProductsPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_PRODUCTS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyIdAssetsPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_ASSETS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyIdDeliveryDestinationsPath = (
  id: string,
): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_DELIVERY_DESTINATIONS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export const generateCompanyIdApiCollectionsPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_DATASETS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export interface ICompanyIdPath {
  companyId: string;
}
export const generateCompanyIdEdgeGatewaysPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_EDGE_GATEWAYS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export interface ICompanyIdProductIdPath {
  companyId: string;
  productId: string;
}

export interface ICompanyIdScopeIdPath {
  companyId: string;
  scopeId: string;
}

const generateCompanyIdProductIdHelper = ({
  companyId,
  productId,
  path,
}: ICompanyIdProductIdPath & {path: string}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: productId,
    placeholder: Placeholder.productId,
  });
};

export const generateCompanyIdProductIdPathPrefix = ({
  companyId,
  productId,
}: ICompanyIdProductIdPath) => {
  const wildcardPath = generateCompanyIdProductIdHelper({
    path: ROUTE_COMPANYID_PRODUCTID_WILDCARD,
    companyId,
    productId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdProductIdGeneralPath = ({
  companyId,
  productId,
}: ICompanyIdProductIdPath) => {
  return generateCompanyIdProductIdHelper({
    path: ROUTE_COMPANYID_PRODUCTID_GENERAL,
    companyId,
    productId,
  });
};

export const generateCompanyIdProductIdEquipmentsPath = ({
  companyId,
  productId,
}: ICompanyIdProductIdPath) => {
  return generateCompanyIdProductIdHelper({
    path: ROUTE_COMPANYID_PRODUCTID_EQUIPMENT,
    companyId,
    productId,
  });
};

export const generateCompanyIdProductIdCustomersPath = ({
  companyId,
  productId,
}: ICompanyIdProductIdPath) => {
  return generateCompanyIdProductIdHelper({
    path: ROUTE_COMPANYID_PRODUCTID_CUSTOMERS,
    companyId,
    productId,
  });
};

export const generateCompanyIdProductIdLogisticsCompaniesPath = ({
  companyId,
  productId,
}: ICompanyIdProductIdPath) => {
  return generateCompanyIdProductIdHelper({
    path: ROUTE_COMPANYID_PRODUCTID_LOGISTIC_COMPANIES,
    companyId,
    productId,
  });
};

export const generateCompanyIdProductIdAssetsPath = ({
  companyId,
  productId,
}: ICompanyIdProductIdPath) => {
  return generateCompanyIdProductIdHelper({
    path: ROUTE_COMPANYID_PRODUCTID_LOGISTIC_ASSETS,
    companyId,
    productId,
  });
};

export interface IPathWithPlaceHolders {
  placeholdersWithValues: IPlaceHolderWithValue[];
  path: string;
}

interface IPlaceHolderWithValue {
  placeHolder: Placeholder;
  value: string;
}

export interface ICompanyIdSiteIdPath {
  companyId: string;
  siteId: string;
}

const generateCompanyIdSiteIdHelper = ({
  companyId,
  siteId,
  path,
}: ICompanyIdSiteIdPath & {
  path: string;
}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: siteId,
    placeholder: Placeholder.siteId,
  });
};

export const generateCompanyIdSiteIdPathPrefix = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  const wildcardPath = generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_WILDCARD,
    companyId,
    siteId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdSiteIdGeneralPath = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  return generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_GENERAL,
    companyId,
    siteId,
  });
};

export const generateCompanyIdSiteIdScopesPath = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  return generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_SCOPES,
    companyId,
    siteId,
  });
};

export const generateCompanyIdSiteIdTagsPath = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  return generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_TAGS,
    companyId,
    siteId,
  });
};
export const generateCompanyIdSiteIdParametersPath = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  return generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_PARAMETERS,
    companyId,
    siteId,
  });
};

export const generateCompanyIdSiteIdEquipmentPath = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  return generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_EQUIPMENT,
    companyId,
    siteId,
  });
};
export const generateCompanyIdSiteIdSchedulingPath = ({
  companyId,
  siteId,
}: ICompanyIdSiteIdPath) => {
  return generateCompanyIdSiteIdHelper({
    path: ROUTE_COMPANYID_SITEID_SCHEDULING,
    companyId,
    siteId,
  });
};

export interface ICompanyIdAssetIdPath {
  companyId: string;
  assetId: string;
}

const generateCompanyIdAssetIdHelper = ({
  companyId,
  assetId,
  path,
}: ICompanyIdAssetIdPath & {path: string}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: assetId,
    placeholder: Placeholder.assetId,
  });
};

export const generateCompanyIdAssetIdPathPrefix = ({
  companyId,
  assetId,
}: ICompanyIdAssetIdPath) => {
  const wildcardPath = generateCompanyIdAssetIdHelper({
    path: ROUTE_COMPANYID_ASSETID_WILDCARD,
    companyId,
    assetId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdAssetIdGeneralPath = ({
  companyId,
  assetId,
}: ICompanyIdAssetIdPath) => {
  return generateCompanyIdAssetIdHelper({
    path: ROUTE_COMPANYID_ASSETID_GENERAL,
    companyId,
    assetId,
  });
};

export const generateCompanyIdAssetIdProductsPath = ({
  companyId,
  assetId,
}: ICompanyIdAssetIdPath) => {
  return generateCompanyIdAssetIdHelper({
    path: ROUTE_COMPANYID_ASSETID_PRODUCTS,
    companyId,
    assetId,
  });
};

export interface ICompanyIdDeliveryDestinationIdPath {
  companyId: string;
  deliveryDestinationId: string;
}

const generateCompanyIdDeliveryDestinationIdHelper = ({
  companyId,
  deliveryDestinationId,
  path,
}: ICompanyIdDeliveryDestinationIdPath & {path: string}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: deliveryDestinationId,
    placeholder: Placeholder.deliveryDestinationId,
  });
};

export const generateCompanyIdDeliveryDestinationIdPathPrefix = ({
  companyId,
  deliveryDestinationId,
}: ICompanyIdDeliveryDestinationIdPath) => {
  const wildcardPath = generateCompanyIdDeliveryDestinationIdHelper({
    path: ROUTE_COMPANYID_DELIVERY_DESTINATIONID_WILDCARD,
    companyId,
    deliveryDestinationId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdDeliveryDestinationIdGeneralPath = ({
  companyId,
  deliveryDestinationId,
}: ICompanyIdDeliveryDestinationIdPath) => {
  return generateCompanyIdDeliveryDestinationIdHelper({
    path: ROUTE_COMPANYID_DELIVERY_DESTINATIONID_GENERAL,
    companyId,
    deliveryDestinationId,
  });
};

export interface ICompanyIdEdgeGatewayIdPath {
  companyId: string;
  edgeGatewayId: string;
}

const generateCompanyIdEdgeGatewayIdHelper = ({
  companyId,
  edgeGatewayId,
  path,
}: ICompanyIdEdgeGatewayIdPath & {path: string}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: edgeGatewayId,
    placeholder: Placeholder.edgeGatewayId,
  });
};

export const generateCompanyIdEdgeGatewayIdPathPrefix = ({
  companyId,
  edgeGatewayId,
}: ICompanyIdEdgeGatewayIdPath) => {
  const wildcardPath = generateCompanyIdEdgeGatewayIdHelper({
    path: ROUTE_COMPANYID_EDGE_GATEWAYID_WILDCARD,
    companyId,
    edgeGatewayId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdEdgeGatewayIdGeneralPath = ({
  companyId,
  edgeGatewayId,
}: ICompanyIdEdgeGatewayIdPath) => {
  return generateCompanyIdEdgeGatewayIdHelper({
    path: ROUTE_COMPANYID_EDGE_GATEWAYID_GENERAL,
    companyId,
    edgeGatewayId,
  });
};

export const generateCompanyIdEdgeGatewayMassImportTagsPath = ({
  companyId,
  edgeGatewayId,
}: ICompanyIdEdgeGatewayIdPath) => {
  return generateCompanyIdEdgeGatewayIdHelper({
    path: ROUTE_COMPANYID_EDGE_GATEWAY_MASS_IMPORT_TAGS,
    companyId,
    edgeGatewayId,
  });
};

export interface ICompanyIdDnaInfoServerIdPath {
  companyId: string;
  dnaInfoServerId: string;
}

const generateCompanyIdDnaInfoServerIdHelper = ({
  companyId,
  dnaInfoServerId,
  path,
}: ICompanyIdDnaInfoServerIdPath & {path: string}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: dnaInfoServerId,
    placeholder: Placeholder.dnaInfoServerId,
  });
};

export const generateCompanyIdDnaInfoServerIdPathPrefix = ({
  companyId,
  dnaInfoServerId,
}: ICompanyIdDnaInfoServerIdPath) => {
  const wildcardPath = generateCompanyIdDnaInfoServerIdHelper({
    path: ROUTE_COMPANYID_DNA_INFO_SERVERID_WILDCARD,
    companyId,
    dnaInfoServerId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};

export const generateCompanyIdDnaInfoServerIdGeneralPath = ({
  companyId,
  dnaInfoServerId,
}: ICompanyIdDnaInfoServerIdPath) => {
  return generateCompanyIdDnaInfoServerIdHelper({
    path: ROUTE_COMPANYID_DNA_INFO_SERVERID_GENERAL,
    companyId,
    dnaInfoServerId,
  });
};

export const generateCompanyIdDnaInfoServersPath = (id: string): string => {
  return generatePathByPlaceholderId<Placeholder>({
    path: ROUTE_COMPANYID_DNA_INFO_SERVERS,
    id,
    placeholder: Placeholder.companyId,
  });
};

export interface ICompanyIdScopeIdPath {
  companyId: string;
  scopeId: string;
}

export const generateCompanyIdScopeIdGeneralPath = ({
  companyId,
  scopeId,
}: ICompanyIdScopeIdPath): string =>
  ROUTE_COMPANYID_SCOPEID_GENERAL.replace(
    Placeholder.companyId,
    companyId,
  ).replace(Placeholder.scopeId, scopeId);

export const generateCompanyIdScopesPath = (id: string): string =>
  ROUTE_COMPANYID_SCOPES.replace(Placeholder.companyId, id);

export function getDefaultRoute(companyId: string, isProvider: boolean) {
  if (isProvider) {
    return generateCompanyIdProductsPath(companyId);
  }

  return generateCompanyIdAssetsPath(companyId);
}
export const generateCompanyIdScopeIdPathPrefix = ({
  companyId,
  scopeId,
}: ICompanyIdScopeIdPath) =>
  ROUTE_COMPANYID_SCOPEID_WILDCARD.replace(Placeholder.companyId, companyId)
    .replace(Placeholder.scopeId, scopeId)
    .replace('*', '');
export interface ICompanyIdApiCollectionIdPath {
  companyId: string;
  apiCollectionId: string;
}
const generateCompanyIdApiCollectionIdHelper = ({
  companyId,
  apiCollectionId,
  path,
}: ICompanyIdApiCollectionIdPath & {
  path: string;
}) => {
  const pathWithCompanyId = generatePathByPlaceholderId<Placeholder>({
    path,
    id: companyId,
    placeholder: Placeholder.companyId,
  });
  return generatePathByPlaceholderId<Placeholder>({
    path: pathWithCompanyId,
    id: apiCollectionId,
    placeholder: Placeholder.apiCollectionId,
  });
};
export const generateCompanyIdApiCollectionIdPathPrefix = ({
  companyId,
  apiCollectionId,
}: ICompanyIdApiCollectionIdPath) => {
  const wildcardPath = generateCompanyIdApiCollectionIdHelper({
    path: ROUTE_COMPANYID_DATASETS_WILDCARD,
    companyId,
    apiCollectionId,
  });
  // Remove the * from end
  return wildcardPath.substring(0, wildcardPath.length - 1);
};
export const generateCompanyIdApiCollectionIdGeneralPath = ({
  companyId,
  apiCollectionId,
}: ICompanyIdApiCollectionIdPath) => {
  return generateCompanyIdApiCollectionIdHelper({
    path: ROUTE_COMPANYID_DATASETID_GENERAL,
    companyId,
    apiCollectionId,
  });
};

export const routes: IRouterMap[] = [
  {
    comp: CompanyEditorPage,
    path: ROUTE_COMPANYID_EDIT_WILDCARD,
    requiresOneOfCompanyTypes: undefined,
  },
  {
    comp: SiteListPage,
    path: ROUTE_COMPANYID_SITES,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: ProductListPage,
    path: ROUTE_COMPANYID_PRODUCTS,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: ProductEditorPage,
    path: ROUTE_COMPANYID_PRODUCTID_WILDCARD,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: SiteEditorPage,
    path: ROUTE_COMPANYID_SITEID_WILDCARD,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: AssetListPage,
    path: ROUTE_COMPANYID_ASSETS,
    requiresOneOfCompanyTypes: undefined,
  },
  {
    comp: AssetEditorPage,
    path: ROUTE_COMPANYID_ASSETID_WILDCARD,
    requiresOneOfCompanyTypes: undefined,
  },
  {
    comp: DeliveryDestinationListPage,
    path: ROUTE_COMPANYID_DELIVERY_DESTINATIONS,
    requiresOneOfCompanyTypes: [
      CompanyType.AlternativeFuelsProvider,
      CompanyType.AlternativeFuelsCustomer,
    ],
  },
  {
    comp: DeliveryDestinationEditorPage,
    path: ROUTE_COMPANYID_DELIVERY_DESTINATIONID_WILDCARD,
    requiresOneOfCompanyTypes: [
      CompanyType.AlternativeFuelsProvider,
      CompanyType.AlternativeFuelsCustomer,
    ],
  },
  {
    comp: ScopeListPage,
    path: ROUTE_COMPANYID_SCOPES,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: ScopeEditorPage,
    path: ROUTE_COMPANYID_SCOPEID_WILDCARD,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: EdgeGatewayListPage,
    path: ROUTE_COMPANYID_EDGE_GATEWAYS,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: EdgeGatewayMassImportTagsPage,
    path: ROUTE_COMPANYID_EDGE_GATEWAY_MASS_IMPORT_TAGS,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: EdgeGatewayEditorPage,
    path: ROUTE_COMPANYID_EDGE_GATEWAYID_WILDCARD,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: ApiCollectionListPage,
    path: ROUTE_COMPANYID_DATASETS,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: ApiCollectionEditorPage,
    path: ROUTE_COMPANYID_DATASETS_WILDCARD,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: DnaInfoServerListPage,
    path: ROUTE_COMPANYID_DNA_INFO_SERVERS,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
  {
    comp: DnaInfoServerEditorPage,
    path: ROUTE_COMPANYID_DNA_INFO_SERVERID_WILDCARD,
    requiresOneOfCompanyTypes: [CompanyType.AlternativeFuelsProvider],
  },
];

/**
 * Returns the matching route-component mapping
 * for a given URL path.
 * @param path The path, e.g. "/company/some-id/products"
 * @returns The mapping if a match is found or null if no match found
 */
export const getMatchingRoute = (path: string): IRouterMap | null => {
  const route = routes.find(r =>
    matchPath(path, {
      path: r.path,
      exact: true,
    }),
  );
  if (route === undefined) {
    return null;
  }

  return route;
};
