const translations = {
  editor: {
    labels: {
      address: 'Address',
      code: 'Code',
      customerCompany: 'Customer company',
      displayName: 'Name',
      isDisabled: 'Disabled',
    },
    notifications: {
      createSuccess: 'Delivery destination successfully created.',
      updateSuccess: 'Delivery destination successfully updated.',
    },
    tabs: {
      general: 'General',
    },
    titles: {
      create: 'New delivery destination: {{displayName}}',
      edit: 'Delivery destination: {{displayName}}',
    },
  },
};

export default translations;
